<template>
  <b-container class="p-0">
    <b-row class="mb-3">
      <b-col>
        <h1>{{ $t('dishCategories.title') }}</h1>
      </b-col>

      <b-col sm="auto" cols="12">
        <b-button
          squared
          to="/dishCategories/add"
          variant="outline-success"
          v-if="!isOperator"
        >
          {{ $t('dishCategories.add_button') }}
        </b-button>
      </b-col>
    </b-row>

    <hr />

    <b-row class="mt-3">
      <b-col
        v-for="category in dishCategories"
        :key="category.id"
        xl="12"
        lg="12"
        md="6"
        sm="12"
        class="mb-3"
      >
        <b-card>
          <b-row align-h="start" align-v="end">
            <b-col lg="auto" sm="12" class="pr-0">
              <h4 class="m-0">
                {{ category[`name_${interfaceLang}`] || category.name }}
              </h4>
            </b-col>

            <b-col lg="auto" class="pl-lg-2" sm="12">
              <span class="d-none d-lg-inline">/</span>

              <span class="text-secondary">
                {{ category.name }}
              </span>
            </b-col>

            <b-col sm="12" lg="auto" align-self="end">
              <small :title="String(category.updatedAt)"
                ><i
                  >{{ $t('dishCategories.lastUpdated') }}
                  {{ inWords(new Date(category.updatedAt)) }}</i
                ></small
              >
            </b-col>
          </b-row>

          <hr />

          <div class="mt-3 d-flex flex-nowrap justify-content-between">
            <div>
              {{ $t('dishCategories.displayBetween') }}
            </div>

            <em v-if="hasDisplayingInterval(category)">
              {{ category.startDisplayingAt }}-{{ category.stopDisplayingAt }}
            </em>

            <code v-else>
              {{ $t('dishCategories.alwaysDisplayed') }}
            </code>
          </div>

          <b-row class="mt-3">
            <b-col>
              <b-form-checkbox
                class="d-inline ml-2 text-center"
                v-model="category.enabled"
                @input="changeState(category)"
              >
                {{ $t('dishCategories.enabled') }}
              </b-form-checkbox>
            </b-col>

            <!-- Available only on lg devices (desktops) -->

            <b-col cols="12" class="d-none d-lg-block col-lg-auto">
              <b-link variant="link" :to="'/dishCategories/' + category.id">
                <b-icon-pencil></b-icon-pencil>
                {{ $t('dishCategories.edit') }}
              </b-link>

              <span class="d-none d-lg-inline">/</span>

              <b-link
                variant="link"
                @click="onDishesLinkPressed(category)"
                class="ml-lg-1"
              >
                <b-icon-list></b-icon-list>
                {{ $t('dishCategories.dishes') }}
              </b-link>
            </b-col>
          </b-row>

          <hr class="d-lg-none" />

          <b-row align-h="around" class="text-center d-lg-none">
            <b-col>
              <b-link variant="link" :to="'/dishCategories/' + category.id">
                {{ $t('dishCategories.edit') }}
              </b-link>
            </b-col>

            <b-col>
              <b-link variant="link" @click="onDishesLinkPressed(category)">
                {{ $t('dishCategories.dishes') }}
              </b-link>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { RoleEnum, IDishCat } from 'types';
import { formatDistanceToNow } from 'date-fns';

const storagePrefix = process.env.VUE_APP_STORAGE_PREFIX;

@Component({
  name: 'DishCategories',
})
export default class DishCategories extends Vue {
  public dishCategories: IDishCat[] = [];

  interfaceLang = this.$store.getters.interfaceLang;

  public async changeState(dishCategory: IDishCat) {
    const response = await this.axios.put<IDishCat>(
      `/dishCategories/${dishCategory.id}`,
      dishCategory,
    );

    dishCategory = response.data;
  }

  async mounted() {
    const response = await this.axios.get<IDishCat[]>(
      '/dishCategories?restaurantId=' +
        localStorage.getItem(`${storagePrefix}-restaurantId`),
    );

    this.dishCategories = response.data.sort((a, b) => {
      if (a.position > b.position) return 1;

      if (a.position < b.position) return -1;

      return a.name.localeCompare(b.name);
    });
  }

  public hasDisplayingInterval(category: IDishCat): boolean {
    if (category.startDisplayingAt && category.stopDisplayingAt) {
      return true;
    }

    return false;
  }

  public onDishesLinkPressed(category: IDishCat) {
    localStorage.setItem(`${storagePrefix}-dishCategoryId`, category.id);
    this.$router.push('/dishes');
  }

  get isOperator() {
    return this.$store.getters.roleId === RoleEnum.OPERATOR;
  }

  inWords(date: Date) {
    return formatDistanceToNow(date, { addSuffix: true });
  }
}
</script>
