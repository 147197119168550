<template>
  <b-container fluid class="m-0 mt-3 p-0">
    <h5>{{ $t('dish.tags') }}</h5>

    <div class="selected-tags-container">
      <b-badge
        variant="danger"
        v-for="tag in dishTags"
        :key="tag.id"
        class="tag close-item mr-1"
        pill
        @click="removeTag(tag)"
      >
        <b-icon-x-lg class="mr-1" font-scale="0.75" />
        {{ tag[`name_${interfaceLang}`] || tag.name }}
      </b-badge>
    </div>

    <div class="mb-2">
      <small>{{ $t('dish.tags_disclaimer') }}</small>
    </div>

    <b-badge
      :variant="tag.status === 'SELECTED' ? 'secondary' : 'primary'"
      v-for="tag in tags"
      :key="tag.id"
      :class="getClassesBasedOnTag(tag)"
      pill
      @click="addTag(tag)"
    >
      <b-icon-plus />
      {{ tag[`name_${interfaceLang}`] || tag.name }}
    </b-badge>
  </b-container>
</template>

<script lang="ts">
import { ITag } from 'types';
import { Component, Prop, Vue } from 'vue-property-decorator';

type TagStatus = 'SELECTED' | 'UNSELECTED';

type TagModified = ITag & { status: TagStatus };

@Component({ name: 'TagPad' })
export default class TagPad extends Vue {
  interfaceLang = this.$store.getters.interfaceLang;

  @Prop({ default: () => [] })
  availableTags: ITag[];

  @Prop({ default: () => [] })
  dishTags: ITag[];

  addTag(tag: ITag) {
    const dishAlreadySelected = this.dishTags.find((et) => et.id === tag.id);
    if (dishAlreadySelected) return;

    this.dishTags.push(tag);

    this.$emit('listUpdated', this.dishTags);
  }

  removeTag(tag: ITag) {
    const tagIndex = this.dishTags.findIndex((et) => et.id === tag.id);
    if (tagIndex === -1) return;

    this.dishTags.splice(tagIndex, 1);
    this.$emit('listUpdated', this.dishTags);
  }

  get tags() {
    return this.availableTags.map((tag) => ({
      ...tag,
      status: this.dishTags.some((et) => et.id === tag.id)
        ? 'SELECTED'
        : 'UNSELECTED',
    }));
  }

  getClassesBasedOnTag(tag: TagModified): string {
    let tagList = 'tag mr-1 ';
    if (tag?.status === 'UNSELECTED') tagList += 'add';
    else tagList += 'not-selectable';

    return tagList;
  }
}
</script>

<style scoped>
.selected-tags-container {
  width: 100%;
  min-height: 2rem;
  border-radius: 5px;
  border: 1px solid #ced4da;
  background-color: var(--white);
  padding: 4px 0.5rem;
}

.tag {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.not-selectable {
  color: #d8d8d8;
}

.tag.add:hover {
  transform: scale(1.05);
  background-color: var(--success);
}

.tag.add:active {
  transform: scale(1);
  outline: 1px solid var(--green);
}

.tag.close-item:hover {
  transform: scale(0.95);
}

.tag.close-item:active {
  transform: scale(1);
  outline: 1px solid var(--indigo);
}
</style>
