<template>
  <b-container fluid v-if="dish">
    <h1>{{ dish[`name_${interfaceLang}`] }} / {{ dish.name }}</h1>

    <hr />

    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col>
          <b-form-group
            :label="$t('dish.name')"
            :state="nameValidation"
            :invalid-feedback="$t('validation.mustBeLessThan', { amount: 256 })"
          >
            <b-form-input
              id="input-1"
              :placeholder="$t('dish.name_placeholder')"
              type="text"
              v-model="dish.name"
            />
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
            :label="$t('dish.name_ru')"
            :state="nameRuValidation"
            :invalid-feedback="$t('validation.mustBeLessThan', { amount: 256 })"
          >
            <b-form-input
              id="input-2"
              :placeholder="$t('dish.name_ru_placeholder')"
              type="text"
              v-model="dish.name_ru"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group :label="$t('dish.position')">
        <b-form-input
          id="input-3"
          placeholder="1"
          type="number"
          v-model="dish.position"
        />
      </b-form-group>

      <b-form-group
        :label="$t('dish.description')"
        :state="descriptionValidation"
        :invalid-feedback="$t('validation.mustBeLessThan', { amount: 256 })"
      >
        <b-form-textarea
          id="input-4"
          v-model="dish.description"
          rows="5"
          :placeholder="$t('dish.description_placeholder')"
        />
      </b-form-group>

      <b-form-group
        :label="$t('dish.description_ru')"
        :state="descriptionRuValidation"
        :invalid-feedback="$t('validation.mustBeLessThan', { amount: 256 })"
      >
        <b-form-textarea
          id="input-5"
          v-model="dish.description_ru"
          rows="5"
          :placeholder="$t('dish.description_ru_placeholder')"
        />
      </b-form-group>

      <b-form-group
        :label="$t('dish.price')"
        :state="priceValidation"
        :invalid-feedback="$t('validation.cannotBeNull')"
      >
        <b-form-input
          id="input-6"
          placeholder="100"
          type="number"
          step="0.01"
          v-model="dish.price"
        />
      </b-form-group>

      <b-form-checkbox id="checkbox-1" v-model="dish.enabled" name="checkbox-1">
        {{ $t('dish.enabled') }}
      </b-form-checkbox>

      <b-form-checkbox id="checkbox-2" v-model="useAmount" name="checkbox-2">
        {{ $t('dish.amount') }}
      </b-form-checkbox>

      <b-form-input
        id="input-7"
        placeholder="100"
        type="number"
        step="1"
        v-model="dish.amount"
      />

      <tag-pad
        :availableTags="availableTags"
        :dishTags="dish.tags"
        @listUpdated="onTagsUpdated"
      />

      <photo-uploader
        class="mt-3 mb-3"
        :title="$t('dish.image')"
        :cityId="cityId"
        :telegramFileId="dish.telegramFileId"
        @uploaded="onUploaded"
        @uploading="onUploading"
      />

      <div class="d-flex justify-content-between">
        <b-button
          type="submit"
          variant="primary"
          :disabled="!dish || photoUploading || !allValidationHasBeenPassed"
        >
          {{ $t('dish.button_update') }}
        </b-button>

        <b-button
          v-if="!isOperator"
          @click.prevent="onDelete"
          variant="link"
          v-b-hover="onDeleteHover"
          :class="deleteLinkHovered ? 'text-danger' : 'text-secondary'"
        >
          {{ $t('dish.button_remove') }}
        </b-button>
      </div>
    </b-form>
  </b-container>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RoleEnum, ICity, IDish, IRestaurant } from 'types';

import PhotoUploader from './PhotoUploader.vue';
import TagPad from './TagPad.vue';

const storagePrefix = process.env.VUE_APP_STORAGE_PREFIX;

@Component({
  name: 'Dish',
  components: { 'photo-uploader': PhotoUploader, 'tag-pad': TagPad },
})
export default class Dish extends Vue {
  @Prop()
  id: string;

  useAmount = false;
  dish: IDish = null;
  cityId: ICity['id'] = null;

  photoUploading = false;
  deleteLinkHovered = false;

  availableTags: any[] = [];

  interfaceLang = this.$store.getters.interfaceLang;

  async mounted() {
    const restaurantId = localStorage.getItem(`${storagePrefix}-restaurantId`);

    const dishResponse = await this.axios.get<IDish>('/dishes/' + this.id);
    this.dish = dishResponse?.data;
    this.useAmount = this.dish.amount !== null;

    const restaurantResponse = await this.axios.get<IRestaurant>(
      `/restaurants/${restaurantId}`,
    );
    this.cityId = restaurantResponse?.data?.cityId;

    const availableTagsResponse = await this.axios.get(
      '/tags/cities/' + this.cityId,
    );
    this.availableTags = availableTagsResponse.data;
  }

  onTagsUpdated(tags) {
    this.dish.tags = tags;
  }

  onDelete() {
    if (confirm(this.$t('dish.confirm_delete').toString())) {
      this.axios
        .delete('/dishes/' + this.id)
        .then(() => this.$router.push({ path: '/dishes' }));
    }
  }

  onSubmit() {
    if (this.useAmount) {
      this.dish.amount = this.dish.amount || 0;
    } else {
      this.dish.amount = null;
    }
    this.axios
      .put('/dishes/' + this.id, this.dish)
      .then(() => this.$router.push({ path: '/dishes' }));
  }

  onUploading() {
    this.photoUploading = true;
  }

  onUploaded(telegramFileId: string) {
    this.photoUploading = false;
    this.dish.telegramFileId = telegramFileId;
  }

  onDeleteHover(value) {
    this.deleteLinkHovered = value;
  }

  /** Here goes validation */

  get nameValidation() {
    if (!this.dish?.name) return null;

    return this.dish.name.length < 256;
  }

  get nameRuValidation() {
    if (!this.dish?.name_ru) return null;

    return this.dish.name_ru.length < 256;
  }

  get descriptionValidation() {
    if (!this.dish?.description) return null;

    return this.dish.description.length < 256;
  }

  get descriptionRuValidation() {
    if (!this.dish?.description_ru) return null;

    return this.dish.description_ru.length < 256;
  }

  get priceValidation() {
    if (!this.dish?.price) return null;

    return this.dish.price !== 0;
  }

  get allValidationHasBeenPassed() {
    return (
      this.nameValidation !== false &&
      this.nameRuValidation !== false &&
      this.descriptionValidation !== false &&
      this.descriptionRuValidation !== false &&
      this.priceValidation !== false
    );
  }

  get isOperator() {
    return this.$store.getters.roleId === RoleEnum.OPERATOR;
  }
}
</script>
