<template>
  <div v-if="users">
    <div class="row mb-3">
      <div class="col">
        <h1>Пользователи</h1>
        <b-button squared to="/admin/add" variant="outline-success">
          + Добавить пользователя
        </b-button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-table :fields="fields" :items="users" hover striped>
          <template v-slot:cell(username)="data">
            <b-link :to="'/admin/' + data.item.id">
              {{ data.item.username }}
            </b-link>
          </template>

          <template v-slot:cell(roleId)="data">
            {{ getAdminRoleName(data.item.roleId) }}
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { RoleEnum } from 'types';

@Component({ name: 'Admins' })
export default class Admins extends Vue {
  users = [];

  fields = [
    {
      key: 'username',
      label: 'Логин',
      sortable: true,
    },
    {
      key: 'restaurant.name',
      label: 'Ресторан',
      sortable: true,
    },
    /**
     * if the city administrator tries to view the list of restaurateurs,
     * they won't see the "City" field, because there is only one city for they.
     *
     * It this is not a city admin who tries to view the list of admins,
     * this field must be shown.
     */
    !this.isCityAdmin && {
      key: 'city.name',
      label: 'Город',
      sortable: true,
    },
    {
      key: 'roleId',
      label: 'Роль',
      sortable: true,
    },
  ];

  get isCityAdmin() {
    return [RoleEnum.CITY_ADMIN].includes(this.$store.getters.roleId);
  }

  getAdminRoleName(roleId: RoleEnum) {
    switch (roleId) {
      case RoleEnum.ADMIN:
        return 'Главный администратор';
      case RoleEnum.CITY_ADMIN:
        return 'Администратор города';
      case RoleEnum.RESTAURANT_OWNER:
        return 'Владелец ресторана';
      case RoleEnum.OPERATOR:
        return 'Оператор';
      case RoleEnum.COURIER:
        return 'Курьер';
      default:
        return 'Хз';
    }
  }

  mounted() {
    this.axios.get('/users').then((response) => {
      this.users = response.data;
    });
  }
}
</script>

<style scoped></style>
