<template>
  <b-container>
    <header>
      <h4>Tags</h4>

      <b-button variant="primary" @click="onCreate">
        {{ $t('tags.create') }}
      </b-button>
    </header>

    <hr />

    <b-form-group :label="$t('city.choose')">
      <b-form-select :options="cities" @input="onCityChanged">
        Please select a city
      </b-form-select>
    </b-form-group>

    <b-list-group>
      <b-list-group-item
        v-for="tag in availableTags"
        :key="tag.id"
        class="d-flex justify-content-between mb-1"
        pi
      >
        {{ tag.name }} / {{ tag.name_ru }}

        <div>
          <b-icon-pencil
            class="icon-clickable edit"
            @click="onTagSelected(tag)"
          />
          /
          <b-icon-trash
            class="icon-clickable delete"
            @click="onTagDelete(tag)"
          />
        </div>
      </b-list-group-item>
    </b-list-group>
  </b-container>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ICity, ITag, RoleEnum } from 'types';

export type PreparedCity = { text: string; value: ICity };

@Component({ name: 'Tags' })
export default class Tags extends Vue {
  availableTags: ITag[] = [];
  cities: PreparedCity[] = [];

  async mounted() {
    if (this.isCityAdmin) this.updateTags(this.cityId);

    if (this.isAdmin) {
      const citiesResponse = await this.axios.get<ICity[]>('/cities');
      this.cities = citiesResponse.data?.map((city) => ({
        text: city.name,
        value: city,
      }));
    }
  }

  async onCreate() {
    await this.$router.push('/tag');
  }

  async onCityChanged(city: ICity) {
    await this.updateTags(city.id);
  }

  async updateTags(cityId: ICity['id']) {
    const url = '/tags/cities/' + cityId;

    const availableTagsResponse = await this.axios.get<ITag[]>(url);
    this.availableTags = availableTagsResponse.data;
  }

  async onTagSelected(tag: ITag) {
    await this.$router.push('/tags/' + tag.id);
  }

  async onTagDelete(tag: ITag) {
    await this.axios.delete<ITag[]>('/tags/' + tag.id);

    await this.updateTags(tag.cityId);
  }

  // Getters from the store

  get cityId() {
    return this.$store.getters.cityId;
  }

  get isAdmin() {
    return this.$store.getters.roleId === RoleEnum.ADMIN;
  }

  get isCityAdmin() {
    return this.$store.getters.roleId === RoleEnum.CITY_ADMIN;
  }
}
</script>

<style scoped>
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header > h4 {
  width: fit-content;
  margin: 0;
}

.icon-clickable {
  cursor: pointer;
  transition: color 0.4s ease-in-out;
}

.icon-clickable.edit:hover {
  color: var(--primary);
}
.icon-clickable.delete:hover {
  color: var(--danger);
}
</style>
