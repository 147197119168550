import Vue from 'vue';
// eslint-disable-next-line import/order
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import VueBootstrapDatetimepicker from 'vue-bootstrap-datetimepicker';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueBootstrapDatetimepicker);
