<template>
  <b-row>
    <b-col md="12">
      <h4 class="p-0">{{ title }}</h4>
      <p style="color: red">{{ error }}</p>
    </b-col>

    <b-row
      v-if="images.length > 0"
      align-v="center"
      align-h="center"
      class="w-100 justify-content-center"
    >
      <b-col
        md="12"
        v-for="image in images"
        :key="image.link"
        class="d-flex justify-content-center mb-3"
      >
        <b-skeleton-img no-aspect v-if="loading" />
        <b-row v-else>
          <b-img fluid :src="image.photoUrl" style="max-height: 350px" />
          <br />
          <video :src="image.photoUrl" controls style="max-height: 350px" />
        </b-row>
      </b-col>
    </b-row>

    <b-col md="12" class="p-0">
      <b-col md="12">
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            v-model="useURL"
            :options="radioOptions"
            :aria-describedby="ariaDescribedby"
          />
        </b-form-group>
      </b-col>

      <b-col md="12" class="mb-3">
        <b-form-input
          v-if="useURL"
          placeholder="http://unsplash.com/s..."
          type="text"
          v-model="link"
          debounce="1000"
          @update="onURLInserted"
          :state="uploadingState"
        />
        <b-form-file
          v-else
          accept=".jpg, .png, .gif, .jpeg, .webp, .mp4"
          v-model="files"
          :multiple="multiple"
          @input="onImageChosen"
          :state="uploadingState"
        />
      </b-col>

      <b-col md="12" v-if="uploading">
        <div>
          <b-spinner variant="primary" small />
          <small class="ml-1">
            {{ $t('ad.pleaseWaitUpload') }}
          </small>
        </div>

        <b-progress max="100" class="mt-3">
          <b-progress-bar
            variant="primary"
            animated
            :value="uploadingProgress"
            show-progress
          />
        </b-progress>
      </b-col>
    </b-col>
  </b-row>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

const URLRegex =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

@Component({ name: 'photo-uploader' })
export default class PhotoUploader extends Vue {
  @Prop()
  title: string;

  @Prop()
  cityId: string;

  @Prop()
  telegramFileId?: string | string[];

  @Prop({ default: false })
  multiple: boolean;

  @Prop({ default: '' })
  error: string;

  /**
   * Variables that are responsible for showing the image
   */
  useURL = false;
  files: File[] | File = null;
  images: { link: string; photoUrl: string | SourceBuffer }[] = [];
  link: string;
  radioOptions = [
    { text: 'URL', value: true },
    { text: 'Upload', value: false },
  ];

  /**
   * For showing indication
   */
  loading = true;
  uploading = false;
  uploadingState: boolean | null = null;
  uploadingProgress = 0;

  isUpdating = false;

  async onURLInserted(value: string) {
    if (value) {
      this.uploadingState = true;
      this.$emit('uploaded', value);
    } else {
      this.uploadingState = false;
      this.$emit('uploading');
    }
  }

  async onImageChosen() {
    this.uploadingState = null;
    this.uploading = true;

    this.$emit('uploading');

    const photoForm = new FormData(),
      params: { cityId?: string } = {};

    const files: File[] =
      this.files instanceof Array ? this.files : [this.files];

    files.forEach((file: File, index: number) => {
      photoForm.append('photo_' + index, file, file.name);
    });

    if (this.cityId) {
      params.cityId = this.cityId;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let data: any = null;

    try {
      const response = await this.axios.post(`/photo`, photoForm, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          this.uploadingProgress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
        },
        params,
      });
      data = response.data;
    } catch (e) {
      //
    }

    if (!data) {
      this.error = 'Error while uploading image';
    }

    this.uploading = false;
    this.uploadingState = true;

    this.$emit('uploaded', this.multiple ? data : data[0]);
  }

  @Watch('telegramFileId')
  async onTelegramFileIdChanged() {
    if (!this.isUpdating) {
      this.isUpdating = true;
      await this.loadImage();
      this.isUpdating = false;
    }
  }

  @Watch('cityId')
  async onCityIdChanged() {
    if (!this.isUpdating) {
      this.isUpdating = true;
      await this.loadImage();
      this.isUpdating = false;
    }
  }

  async loadImage() {
    if (!this.telegramFileId || !this.cityId) return;

    const telegramFileIds =
      this.telegramFileId instanceof Array
        ? this.telegramFileId
        : [this.telegramFileId];

    this.images = [];

    for (const fileId of telegramFileIds) {
      if (URLRegex.test(fileId)) {
        this.useURL = true;

        this.images.push({
          link: fileId,
          photoUrl: fileId,
        });
      } else {
        this.useURL = false;

        try {
          const response = await this.axios.get(`/photo/`, {
            responseType: 'blob',
            params: {
              cityId: this.cityId,
              telegramFileId: fileId,
            },
          });

          const imageSource = URL.createObjectURL(response.data);

          this.images.push({
            link: fileId,
            photoUrl: imageSource,
          });
        } catch (e) {
          this.$bvToast.toast('Error while getting image', {
            title: this.$t('error').toString(),
            variant: 'danger',
          });
          this.images.push({
            link: null,
            photoUrl: null,
          });
        }
      }

      if (!this.multiple) break;
    }

    this.loading = false;
  }
}
</script>
