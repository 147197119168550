<template>
  <b-container fluid>
    <h1>{{ $t('login.title') }}</h1>

    <b-form @submit.prevent="onSubmit">
      <b-form-group
        id="input-group-1"
        :label="$t('login.login')"
        label-for="input-1"
      >
        <b-form-input
          :state="errorValidation"
          v-model="form.username"
          :placeholder="$t('login.login_placeholder')"
          id="input-1"
          required
          type="text"
        />

        <b-form-invalid-feedback id="input-live-feedback">
          {{ $t('login.error') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        id="input-group-2"
        v-bind:label="$t('login.password')"
        label-for="input-2"
      >
        <b-input-group>
          <b-form-input
            id="input-2"
            v-bind:placeholder="$t('login.password_placeholder')"
            required
            :type="showPassword ? 'text' : 'password'"
            v-model="form.password"
          />

          <b-input-group-append>
            <b-button
              variant="outline-primary"
              @click="showPassword = !showPassword"
            >
              <b-icon-eye-fill />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>

      <b-button type="submit" variant="primary" class="mt-1">
        <b-spinner v-if="isLoading" small />
        {{ $t('login.button') }}
      </b-button>
    </b-form>
  </b-container>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

import { ILoginForm } from '../store/interfaces';

@Component({ name: 'Login' })
export default class Login extends Vue {
  form: ILoginForm = {
    username: '',
    password: '',
  };

  showPassword = false;

  async onSubmit() {
    await this.$store.dispatch('login', this.form);
    this.$router.push('/');
  }

  get errorValidation() {
    return !this.$store.state.status || this.$store.state.status === 'loading'
      ? null
      : this.$store.state.status !== 'error';
  }

  get isLoading() {
    return this.$store.state.status === 'loading';
  }
}
</script>

<style scoped></style>
