export default {
  validation: {
    mustBeLessThan:
      'La longitud del campo debe ser menor que {amount} símbolos',
    mustBeMoreAndLessThan:
      'La longitud del campo debe ser mayor que {moreAmount} y menor que {lessAmount} símbolos',
    userNotFound: 'Usuario no encontrado',
    scheduleError:
      'Solo se acepta este formato: 09:30-00:00 o 09:30-14:00,15:00-20:30. Por favor, corrija el campo.',
    cannotBeNull: 'No puede ser nulo',
    cantPublishAds: 'No se pueden publicar anuncios:',
  },
  ad: {
    chatId: 'ID del chat para la publicación',
    h1: 'Anuncios',
    edit: 'Editar anuncio',
    title: 'Título: {title}',
    title_placeholder: 'Anuncio #1',
    text: 'Texto del anuncio. (No olvides que se publicará en un chat de Telegram en ruso):',
    text_placeholder: 'Tenemos el mejor restaurante',
    publishDate: 'Hora de inicio: {publishDate}',
    unpublishDate: 'Hora de eliminación: {unpublishDate}',
    restaurant: 'Restaurante: {restaurant}',
    image: 'Imagen:',
    drop_placeholder: 'Suelta aquí',
    file_placeholder: 'Elige un archivo',
    add: '+ Anuncio',
    save: 'Guardar',
    delete: 'Eliminar anuncio',
    new: '+ Anuncio',
    status: 'Estado',
    created: 'Esperando',
    published: 'Publicado',
    removed: 'Eliminado',
    unpublished: 'Eliminado',
    timeout: 'Puedes crear el siguiente: {timeout}',
    reload: 'Recargar página',
    publicationError: 'Ocurrió un error durante la publicación',
    removePublicationError:
      'Ocurrió un error durante la eliminación del anuncio',
    lengthValidation:
      'La longitud del mensaje debe ser menor de 1024 caracteres',
    waitingForImage: 'Adjuntando imagen al anuncio',
    pleaseWaitUpload: 'Por favor, espera hasta que la foto se haya subido...',
  },
  city: {
    add: '+ Añadir ciudad',
    active: 'La ciudad está activa',
    all: 'Ciudades',
    chatname: 'Chat de la ciudad',
    currency: 'Moneda en la ciudad',
    one: 'Ciudad',
    save: 'Guardar cambios',
    regions: 'Regiones ->',
    name: 'Nombre (inglés)',
    name_ru: 'Nombre (ruso)',
    cityDisabledReason: 'Razón por la que la ciudad está deshabilitada',
    choose: 'Elige una ciudad',
    choose_placeholder: 'Por favor, selecciona una ciudad',
    deliveryCostJump: 'Configuración del salto de costo de entrega',
    deliveryCostJumpStart: '¿Cuándo debe comenzar el salto de precio?',
    locales: 'Idiomas en el canal de los mensajeros',
    hideMoney: 'Hide prices from couriers',
  },
  config: {
    ads: 'Anuncios',
    adsChannelId: 'ID del canal para anuncios',
    adsDelay: 'Tiempo de espera entre anuncios (en horas)',
    adsTime: 'Hora de publicación (en horas)',
    deliveryMultiplier: 'Multiplicador de entrega',
    delete: 'Eliminar',
    ids: 'Identificadores',
    infoButtonText_en: 'Texto de información en inglés',
    infoButtonText_ru: 'Texto de información en ruso',
    mainImage: 'Imagen predeterminada',
    notifyChannelId: 'ID del canal para notificaciones',
    reviewChannelId: 'ID del canal para reseñas',
    save: 'Guardar',
    settings: 'Configuraciones',
    showInfoButton: 'Mostrar botón de información',
    showTopTen: 'Mostrar botón de top 10',
    showOpenNow: 'Mostrar botón de "Abierto ahora"',
    showTagsButton: 'Mostrar botón de etiquetas',
  },
  cuisines: {
    enabled: 'Mostrar en la lista',
  },
  dishCategories: {
    title: 'Categorías de platos',
    add_button: '+ Añadir categoría de platos',
    name: 'Nombre de la categoría (inglés)',
    name_ru: 'Nombre de la categoría (ruso)',
    position: 'Posición en la lista',
    enabled: 'Mostrar en el bot',
    edit: 'Editar',
    dishes: 'Obtener platos',
    displayBetween: 'Mostrado:',
    alwaysDisplayed: 'siempre',
    lastUpdated: 'Última actualización',
  },
  dishCategory: {
    add: 'Nueva categoría',
    button_remove: 'Eliminar la categoría',
    button_update: 'Actualizar categoría',
    button_add: 'Añadir',
    name: 'Nombre de la categoría (inglés)',
    name_placeholder: 'Nombre de la categoría (inglés)',
    name_ru: 'Nombre de la categoría (ruso)',
    name_ru_placeholder: 'Nombre de la categoría (ruso)',
    position: 'Posición en la lista',
    dishes: 'Platos',
    enabled: 'Mostrar en tu menú',
    startTime: 'Comenzar a mostrar a las:',
    endTime: 'Dejar de mostrar a las:',
    confirm_delete: '¿Estás seguro de que quieres eliminar esta categoría?',
  },
  dishes: {
    title: 'Platos',
    button_add: 'Añadir plato',
    name: ' Nombre del plato (inglés)',
    name_ru: 'Nombre del plato (ruso)',
    position: 'Posición en la lista',
    description: 'Descripción',
    price: 'Precio',
    enabled: 'Mostrar en el bot',
    amount: 'Cantidad restante',
    edit: 'Editar',
    lastUpdated: 'Última actualización',
  },
  dish: {
    title: 'Nuevo plato',
    name: ' Nombre del plato (inglés)',
    name_placeholder: ' Nombre del plato (inglés)',
    name_ru: 'Nombre del plato (ruso)',
    name_ru_placeholder: 'Nombre del plato (ruso)',
    position: 'Posición en la lista',
    description: 'Descripción (inglés)',
    description_placeholder: 'Descripción (inglés)',
    description_ru: 'Descripción (ruso)',
    description_ru_placeholder: 'Descripción (ruso)',
    price: 'Precio:',
    image: 'Foto del plato:',
    image_placeholder: 'Selecciona un archivo',
    button_add: 'Añadir',
    button_remove: 'Eliminar plato',
    button_update: 'Actualizar plato',
    enabled: 'Mostrar en tu menú',
    amount: 'Cantidad restante',
    tags: 'Etiquetas',
    tags_disclaimer:
      'Las etiquetas se utilizan para acelerar la búsqueda de platos para los clientes. Puedes elegir no más de 2 etiquetas para un plato.',
    confirm_delete: '¿Estás seguro de que quieres eliminar este plato?',
  },
  home: {
    title: 'Página principal',
    body: 'Seleccionar idioma',
    menuMovedTitle: '¡El menú se ha movido aquí!',
    menuMovedText: 'Puedes acceder a él presionando este botón.',
    dontShowAgain: 'No mostrar de nuevo',
    pleaseReloadPage: '¡Se ha lanzado una nueva versión!',
    pleaseReloadPageDetails: `Estamos trabajando constantemente en la mejora de nuestro servicio. ¡Por favor, recarga esta página para continuar trabajando con nuestro producto!`,
  },
  language: {
    search_placeholder: 'Buscar...',
  },
  logout: {
    title: 'Cerrar sesión',
    button: 'Cerrar sesión',
  },
  login: {
    title: 'Iniciar sesión',
    login: 'Usuario',
    login_placeholder: 'Introduce tu usuario',
    password: 'Contraseña',
    password_placeholder: 'Introduce tu contraseña',
    button: 'Entrar',
    error: 'Has proporcionado una contraseña o usuario incorrecto',
  },
  menu: {
    reviews: 'Reseñas',
    bots: 'Bots',
    ads: 'Anuncios',
    login: 'Iniciar sesión',
    logout: 'Cerrar sesión',
    cities: 'Ciudades',
    cuisines: 'Cocinas',
    restaurants: 'Restaurantes',
    channel: 'Notificaciones',
    config: 'Configuración del sitio',
    russian: 'Textos en ruso',
    georgian: 'Textos en georgiano',
    english: 'Textos en inglés',
    spam: 'Correo masivo',
    stats: 'Estadísticas',
    orders: 'Pedidos',
    top: 'Top de clientes',
    users: 'Usuarios',
    myrest: 'Mi restaurante',
    regions: 'Regiones',
    city: 'Editar ciudad',
    tags: 'Etiquetas',
  },
  bots: {
    title: 'Lista de bots en funcionamiento',
    add: 'Añadir nuevo bot',
    main_page_title: 'Bot de la ciudad',
  },
  region: {
    all: 'Regiones',
    add: '+ Añadir región',
    delete: 'Eliminar región',
    one: 'Región',
    name: 'Nombre de la región (en inglés)',
    name_ru: 'Nombre de la región (en ruso)',
    regions: 'Introduce regiones (1 por línea)',
    regions_placeholder: 'Izquierda\nDerecha',
    save: 'Guardar datos',
  },
  restaurant: {
    descriptionLengthError:
      'La longitud de la descripción debe ser menor de 512 símbolos',
    address: 'Enlace de Google al mapa',
    general: 'Opciones generales',
    contact: 'Contacto',
    cities: 'Elige la ciudad:',
    maximumTimeForPreorder: 'Tiempo máximo de preorden (en horas)',
    chose_delivery_city: 'Elige la ciudad donde está disponible la entrega',
    delivery: 'Configuración de entrega',
    title_add: 'Nuevo restaurante',
    dishCategories: 'Categorías de platos',
    remove: 'Eliminar',
    name: 'Nombre del restaurante: {name}',
    name_placeholder: 'Por favor, introduce el nombre del restaurante de nuevo',
    name_ru: 'Nombre del restaurante: {name}',
    name_ru_placeholder: 'Nombre del restaurante',
    receipt_text: 'Texto en el recibo (inglés):',
    receipt_text_ru: 'Texto en el recibo (ruso):',
    description_ru: 'Descripción (ruso):',
    description_ru_placeholder: 'Descripción (ruso)',
    description: 'Descripción (inglés):',
    description_placeholder: 'Descripción (inglés)',
    position: 'Posición en la lista: {position}',
    minOrderSum: 'Pedido mínimo: {sum}',
    deliveryTime: 'Tiempo de entrega: {time}',
    deliveryTime_placeholder: '25 min',
    schedule: 'Horario de trabajo: {schedule}',
    schedule_placeholder: '09:00-00:00',
    schedule_error:
      'Solo se acepta este formato: 09:30-00:00 o 09:30-14:00,15:00-20:30. Por favor, corrija el campo.',
    deliveryPrice: 'Cargos de entrega:',
    notificationAccount:
      'Cuenta de Telegram para pedidos: {notificationAccount}',
    districtId: 'Distrito del restaurante',
    cuisines: 'Cocinas:',
    image: 'Logo del restaurante:',
    file_placeholder: 'Selecciona un archivo',
    update: 'Actualizar',
    button_add: 'Añadir restaurante',
    url: 'URL directa para el restaurante en el bot:',
    account_tooltip:
      'Envía el comando /id a cualquiera de nuestros bots para obtener tu ID de Telegram.',
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sábado',
    sunday: 'Domingo',
    link: 'Enlace al restaurante',
    linkUnavailable:
      '🚫 Ningún bot funciona en la ciudad donde se encuentra el restaurante',
    confirm_delete: '¿Estás seguro de que quieres eliminar este restaurante?',
  },
  restaurants: {
    title: 'Restaurantes',
    add_button: '+ Añadir restaurante',
    name: 'Nombre (inglés)',
    name_ru: 'Nombre (ruso)',
    canPickup: '¿Recoger?',
    canDelivery: 'Entrega habilitada',
    canSelfCuisine: 'Mostrar la elección de cocina',
    enabled: 'Trabajando ahora',
    active: 'Activo (admin)',
    position: 'Posición en la lista',
    minOrderSum: 'Pedido mínimo',
    deliveryTime: 'Tiempo de entrega',
    deliveryPrice: 'Precio de entrega',
    notificationAccount: 'Cuenta de notificación',
  },
  spam: {
    user_total_count: 'Total de usuarios',
  },
  chat: {
    title: 'Chat con el cliente {customerName}',
    orderHasBeenChanged: 'El usuario hizo un pedido №{threadId}',
    inputPlaceholder: 'Escribe aquí un mensaje para enviar',
    send: 'Enviar',
    sendToRestaurant: 'Enviar al restaurante',
    chatIsEmpty: 'Aún no hay mensajes en este chat',
    customer: 'Cliente',
  },
  orders: {
    title: 'Pedidos',
    export: 'Exportar a Excel',
    startingDate: 'Fecha de inicio:',
    endingDate: 'Fecha de finalización:',
    city: 'Ciudad:',
    restaurants: 'Restaurantes:',
    restaurantDisclaimer: 'Elige uno o varios restaurantes',
    showNumbers: '¿Mostrar números?',
    totalSum: 'Suma total para este período:',
    totalSumWithoutDelivery:
      'Suma total sin costo de entrega para este período:',
    ordersAmount: 'Cantidad de pedidos:',
    averageCheque: 'Cheque promedio:',
    sum: 'Suma:',
    sumWithoutDelivery: 'Suma sin entrega:',
    orderedAt: 'Fecha del pedido:',
    customerName: 'Nombre del cliente:',
    contactType: 'Tipo de contacto:',
    phone: 'Teléfono:',
    status: 'Estado:',
    openChat: 'Abrir chat',
    enableBriefSummary: '¿Habilitar resumen breve?',
    paid: 'Pagado:',
    paymentReceipt: 'ID de cargo:',
    serviceFee: 'Tarifa de servicio:',
    courier: 'Mensajero:',
    invoiceId: 'Invoice ID:',
    changeStatus: 'Cambiar estado',
    statusesHelp: {
      noStatus: 'Estado desconocido',
      CREATED: 'Recién creado, se pagará en efectivo',
      NOT_PAID: 'Recién creado, se pagará en Telegram',
      WAITING_FOR_COURIER: 'Esperando al mensajero / recogida',
      READY:
        'El restaurante ha cocinado el pedido, esperando la confirmación de un operador',
      CONFIRMED: 'El restaurante confirmó el pedido, comenzando a cocinarlo',
      SERVING: 'El pedido se está cocinando en el restaurante',
      RESTAURANT_CLOSED: 'Restaurant closed',
      CANCELED_BY_RESTAURANT: 'Canceled by restaurant',
      CANCELED_BY_USER: 'Canceled by user',
      DRAFT: 'Order draft',
      PAID: 'Paid by card',
      DELAYED: 'Order delayed',
      COOKED: 'Order cooked',
      IN_RESTAURANT: 'Order ready for pickup',
      OUT_FOR_DELIVERY: 'Order on the way',
      CALL_CUSTOMER: 'Need to call the customer',
      DELIVERED: 'Order delivered',
    },
    statuses: {
      noStatus: 'desconocido',
      CREATED: 'no confirmado',
      CONFIRMED: 'confirmado',
      SERVING: 'procesando',
      READY: 'cocinado',
      WAITING_FOR_COURIER: 'entregado',
      NOT_PAID: 'no pagado',
      RESTAURANT_CLOSED: 'restaurant closed',
      CANCELED_BY_RESTAURANT: 'canceled by restaurant',
      CANCELED_BY_USER: 'canceled by user',
      DRAFT: 'order draft',
      PAID: 'paid',
      DELAYED: 'delayed',
      COOKED: 'cooked',
      IN_RESTAURANT: 'in restaurant',
      OUT_FOR_DELIVERY: 'on the way',
      CALL_CUSTOMER: 'need to call the customer',
      DELIVERED: 'delivered',
    },
  },
  tags: {
    newTag: 'Nueva etiqueta',
    name: 'Nombre en inglés',
    name_ru: 'Nombre en ruso',
    update: 'Actualizar etiqueta',
    create: 'Crear etiqueta',
  },
  reviews: {
    title: 'Reseñas',
    noText: 'Sin texto',
    chooseCity: 'Elige ciudad:',
    answer: {
      label: 'Responder a la reseña:',
      submit: 'Enviar',
    },
    controlling: {
      delete: 'Eliminar',
      approve: 'Aprobar',
      publish: 'Publicar',
      answer: 'Responder',
    },
  },
};
