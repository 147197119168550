"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkProperties = void 0;
/**
 * Checks if all properties is in the object and if some of them are not,
 * then it returns it.
 *
 * @param properties List of keys that must be in the object being checked
 * @returns Null if all properties exist, key if some of them does not exist
 *
 * **Example**:
 * ```ts
 * const goodOrder = { id: 1, sum: 500.00, user_id: 213484 }
 * const badOrder = { id: 2, user_id: 234214 }
 *
 * const isOrder = checkProperties<keyof Order>(['id', 'sum', 'user_id'])
 *
 * isOrder(goodOrder) // returns null
 * isOrder(badOrder) // returns "sum"
 * ```
 */
function checkProperties(properties) {
    return (obj) => properties.reduce((acc, key) => {
        if (acc) {
            return acc;
        } // To not overwrite the missing key everytime
        return obj[key] ? acc : key; // If key isn't in the object, return its name
    }, null);
}
exports.checkProperties = checkProperties;
