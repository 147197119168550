<template>
  <div>
    <div class="row">
      <div class="col">
        <h1>{{ $t('home.title') }}</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <p>{{ $t('home.body') }}</p>
        <b-button v-on:click.prevent="setEnglish" variant="outline-danger"
          >🇬🇧 English</b-button
        >
        &nbsp;
        <b-button v-on:click.prevent="setRussian" variant="outline-primary"
          >🇷🇺 Русский</b-button
        >
        &nbsp;
        <b-button v-on:click.prevent="setSpanish" variant="outline-warning"
          >🇪🇸 Español</b-button
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
const storagePrefix = process.env.VUE_APP_STORAGE_PREFIX;
export default {
  name: 'Home',
  data() {
    return { langs: ['ru', 'en', 'es'] };
  },
  methods: {
    setRussian() {
      localStorage.setItem(`${storagePrefix}-locale`, 'ru');
      this.$i18n.locale = 'ru';
      return this.axios.get(`/language/checkout-locale/ru`);
    },
    setEnglish() {
      localStorage.setItem(`${storagePrefix}-locale`, 'en');
      this.$i18n.locale = 'en';
      return this.axios.get(`/language/checkout-locale/en`);
    },
    setSpanish() {
      localStorage.setItem(`${storagePrefix}-locale`, 'es');
      this.$i18n.locale = 'en';
      return this.axios.get(`/language/checkout-locale/es`);
    },
  },
};
</script>

<style scoped></style>
