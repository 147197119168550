<template>
  <b-container fluid>
    <h1>{{ $t('dish.title') }}</h1>

    <hr />

    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col>
          <b-form-group
            :label="$t('dish.name')"
            :state="nameValidation"
            :invalid-feedback="$t('validation.mustBeLessThan', { amount: 256 })"
          >
            <b-form-input
              v-model="form.name"
              v-bind:placeholder="$t('dish.name_placeholder')"
              id="input-1"
              required
              type="text"
            />
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
            :label="$t('dish.name_ru')"
            :state="nameRuValidation"
            :invalid-feedback="$t('validation.mustBeLessThan', { amount: 256 })"
          >
            <b-form-input
              v-model="form.name_ru"
              v-bind:placeholder="$t('dish.name_ru_placeholder')"
              id="input-2"
              required
              type="text"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group :label="$t('dish.position')">
        <b-form-input
          id="input-6"
          placeholder="1"
          required
          type="number"
          v-model="form.position"
        />
      </b-form-group>

      <b-form-group
        :label="$t('dish.description')"
        :state="descriptionValidation"
        :invalid-feedback="$t('validation.mustBeLessThan', { amount: 256 })"
      >
        <b-form-textarea
          rows="5"
          v-model="form.description"
          v-bind:placeholder="$t('dish.description_placeholder')"
          id="input-3"
          required
        />
      </b-form-group>

      <b-form-group
        :label="$t('dish.description_ru')"
        :state="descriptionRuValidation"
        :invalid-feedback="$t('validation.mustBeLessThan', { amount: 256 })"
      >
        <b-form-textarea
          rows="5"
          v-model="form.description_ru"
          v-bind:placeholder="$t('dish.description_ru_placeholder')"
          id="input-4"
          required
        />
      </b-form-group>

      <b-form-group
        :label="$t('dish.price')"
        :state="priceValidation"
        :invalid-feedback="$t('validation.cannotBeNull')"
      >
        <b-form-input
          id="input-5"
          placeholder="100"
          required
          type="number"
          step="0.01"
          v-model="form.price"
        />
      </b-form-group>

      <b-form-checkbox id="checkbox-1" v-model="form.enabled" name="checkbox-1">
        {{ $t('dish.enabled') }}
      </b-form-checkbox>

      <photo-uploader
        class="mt-3 mb-3"
        :title="$t('dish.image')"
        :cityId="cityId"
        :telegramFileId="form.telegramFileId"
        @uploaded="onUploaded"
        @uploading="onUploading"
      />

      <b-button
        type="submit"
        variant="primary"
        class="mt-3 mb-3"
        :disabled="photoUploading || !allValidationHasBeenPassed"
      >
        {{ $t('dish.button_add') }}
      </b-button>
    </b-form>
  </b-container>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ICity, IDish, IRestaurant } from 'types';

import PhotoUploader from './PhotoUploader.vue';

const storagePrefix = process.env.VUE_APP_STORAGE_PREFIX;

type InputDish = Omit<IDish, 'id' | 'position' | 'price'> & {
  position: string;
  price: string;
};

@Component({ name: 'DishAdd', components: { 'photo-uploader': PhotoUploader } })
export default class DishAdd extends Vue {
  form: InputDish = {
    dishCategoryId: localStorage.getItem(`${storagePrefix}-dishCategoryId`),
    name: '',
    name_ru: '',
    position: '',
    description: '',
    description_ru: '',
    price: '',
    telegramFileId: '',
    enabled: true,
    popularityCoefficient: 0,
  };

  cityId: ICity['id'] = null;
  photoUploading = false;

  async mounted() {
    const restaurantId = localStorage.getItem(`${storagePrefix}-restaurantId`);

    const restaurantRequest = await this.axios.get<IRestaurant>(
      `/restaurants/${restaurantId}`,
    );

    this.cityId = restaurantRequest?.data?.cityId;
  }

  async onSubmit() {
    await this.axios.post('/dishes', {
      ...this.form,
      position: parseInt(this.form.position),
      price: parseInt(this.form.price),
    });

    this.$router.push('/dishes');
  }

  onUploading() {
    this.photoUploading = true;
  }

  onUploaded(telegramFileId: string) {
    this.photoUploading = false;
    this.form.telegramFileId = telegramFileId;
  }

  /** Here goes validation */

  get nameValidation() {
    if (!this.form?.name) return null;

    return this.form.name.length < 256;
  }

  get nameRuValidation() {
    if (!this.form?.name_ru) return null;

    return this.form.name_ru.length < 256;
  }

  get descriptionValidation() {
    if (!this.form?.description) return null;

    return this.form.description.length < 256;
  }

  get descriptionRuValidation() {
    if (!this.form?.description_ru) return null;

    return this.form.description_ru.length < 256;
  }

  get priceValidation() {
    if (!this.form?.price) return null;

    return this.form.price !== '0';
  }

  get allValidationHasBeenPassed() {
    return (
      this.nameValidation !== false &&
      this.nameRuValidation !== false &&
      this.descriptionValidation !== false &&
      this.descriptionRuValidation !== false &&
      this.priceValidation !== false
    );
  }
}
</script>
