<template>
  <div>
    <div class="row">
      <div class="col">
        <h1>{{ $t('logout.title') }}</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-button squared v-on:click="logout" variant="danger">{{
          $t('logout.button')
        }}</b-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'Logout',
  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => this.$router.push('/login'));
    },
  },
};
</script>

<style scoped></style>
