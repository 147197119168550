<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <h1 class="p-0">
          {{ restaurant[`name_${interfaceLang}`] || restaurant.name }}
        </h1>
      </b-col>

      <b-col cols="12" md="auto" align-self="center">
        <b-button
          v-on:click.prevent="onDelete"
          variant="outline-danger"
          v-if="isAdmin"
        >
          {{ $t('restaurant.remove') }}
        </b-button>
        <b-button
          v-on:click.prevent="onLink"
          variant="primary"
          class="ml-2 mr-2"
        >
          {{ $t('restaurant.dishCategories') }}
        </b-button>
        <b-button v-on:click.prevent="onReviews" variant="success">
          {{ $t('menu.reviews') }}
        </b-button>
      </b-col>
    </b-row>

    <hr />

    <b-row class="mt-3">
      <b-col>
        <b-badge
          variant="primary"
          style="cursor: pointer"
          @click="linkVisible = !linkVisible"
        >
          <b-icon-caret-down-fill v-if="linkVisible" />
          <b-icon-caret-right-fill v-else />

          {{ $t('restaurant.link') }}
        </b-badge>

        <b-collapse v-model="linkVisible" class="mt-2">
          <small v-if="!restaurant.link">
            <strong>
              {{ $t('restaurant.linkUnavailable') }}
            </strong>
          </small>

          <small v-else>
            <b-link :href="restaurant.link">
              {{ restaurant.link }}
            </b-link>
          </small>
        </b-collapse>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <h5>{{ $t('restaurant.general') }}</h5>
      </b-col>
    </b-row>

    <b-form @submit.prevent="onSubmit" class="mb-3">
      <b-row v-if="isCityAdmin" class="mb-3">
        <b-col md="12">
          <label for="input-2">
            {{ $t('restaurant.position', { position: '' }) }}
          </label>
        </b-col>

        <b-col md="12">
          <b-form-input
            id="input-2"
            placeholder="1"
            type="number"
            v-model="restaurant.positionInput"
          />
        </b-col>
      </b-row>

      <b-row id="input-group-1" class="mb-3">
        <b-col>
          <b-form-group
            :label="$t('restaurant.name', { name: '' })"
            :state="restaurantNameValidation"
            :invalid-feedback="
              $t('validation.mustBeMoreAndLessThan', {
                moreAmount: 3,
                lessAmount: 256,
              })
            "
          >
            <b-form-input
              id="input-1"
              placeholder="Введите название расторана на английском"
              type="text"
              v-model="restaurant.name"
            />
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
            :label="$t('restaurant.name_ru', { name: '' })"
            :state="restaurantNameRuValidation"
            :invalid-feedback="
              $t('validation.mustBeMoreAndLessThan', {
                moreAmount: 3,
                lessAmount: 256,
              })
            "
          >
            <b-form-input
              id="input-15"
              placeholder="Введите название расторана"
              type="text"
              v-model="restaurant.name_ru"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="6">
          <b-form-group
            :label="$t('restaurant.description')"
            :state="descriptionValidation"
            :invalid-feedback="
              $t('validation.mustBeLessThan', {
                amount: 512,
              })
            "
          >
            <b-form-textarea
              id="input-3"
              v-model="restaurant.description"
              v-bind:placeholder="$t('restaurant.description')"
              type="text"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            :label="$t('restaurant.description_ru')"
            :state="descriptionEnValidation"
            :invalid-feedback="
              $t('validation.mustBeLessThan', {
                amount: 512,
              })
            "
          >
            <b-form-textarea
              id="input-35"
              v-model="restaurant.description_ru"
              v-bind:placeholder="$t('restaurant.description_ru')"
              type="text"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="isOperator" class="mt-3">
        <b-col md="6">
          <b-form-group
            :label="$t('restaurant.receipt_text')"
            :state="receiptTextValidation"
            :invalid-feedback="
              $t('validation.mustBeLessThan', {
                amount: 128,
              })
            "
          >
            <b-form-textarea
              id="input-3"
              v-model="restaurant.receipt_text"
              v-bind:placeholder="$t('restaurant.receipt_text')"
              type="text"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            :label="$t('restaurant.receipt_text_ru')"
            :state="receiptTextRuValidation"
            :invalid-feedback="
              $t('validation.mustBeLessThan', {
                amount: 128,
              })
            "
          >
            <b-form-textarea
              id="input-35"
              v-model="restaurant.receipt_text_ru"
              v-bind:placeholder="$t('restaurant.receipt_text_ru')"
              type="text"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="isCityAdmin" class="mt-3">
        <b-col>
          <b-form-group>
            <b-form-checkbox
              id="checkbox-active"
              v-model="restaurant.active"
              name="checkbox-active"
            >
              {{ $t('restaurants.active') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col>
          <b-form-group>
            <b-form-checkbox
              id="checkbox-1"
              v-model="restaurant.enabled"
              name="checkbox-1"
            >
              {{ $t('restaurants.enabled') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="12" class="mb-3">
          <label for="input-3">
            {{ $t('restaurant.minOrderSum', { sum: '' }) }}
          </label>
          <b-form-input
            id="input-3"
            placeholder="1000"
            type="number"
            step="0.01"
            v-model="restaurant.minOrderSumInput"
          />
        </b-col>

        <b-col md="12">
          <b-form-group
            id="input-group-4"
            :state="scheduleValidation"
            :label="$t('restaurant.schedule', { schedule: '' })"
            :invalid-feedback="this.$t('validation.scheduleError')"
          >
            <b-form-input
              id="input-4"
              v-bind:placeholder="$t('restaurant.schedule_placeholder')"
              type="text"
              v-model="restaurant.schedule"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row align-h="start" class="mb-3">
        <b-col sm="12" class="mb-2"> Working days: </b-col>

        <b-col
          xs="12"
          md="4"
          lg="2"
          xl="1"
          class="mr-3"
          v-for="day in restaurant.workingDays"
          :key="day.value"
        >
          <b-form-checkbox v-model="day.enabled">
            {{ getLocalizedDayOfWeek(day) }}
          </b-form-checkbox>
        </b-col>
      </b-row>

      <b-row class="mb-3">
        <b-col>
          <div v-if="isOperator">
            <b-form-group v-bind:label="$t('restaurant.cuisines')">
              <b-form-checkbox
                :key="cuisine.id"
                :value="cuisine.id"
                inline
                name="flavour-3a"
                v-for="cuisine in allCuisines"
                v-model="selectedCuisines"
              >
                {{ cuisine.name }} / {{ cuisine.name_ru }}
              </b-form-checkbox>
            </b-form-group>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col md="12">
          <h5>{{ $t('restaurant.contact') }}</h5>
        </b-col>
      </b-row>

      <b-row class="mb-3">
        <b-col md="6">
          <b-form-group
            :label="
              $t('restaurant.notificationAccount', {
                notificationAccountId: '',
              })
            "
            label-for="notificationAccountInput"
            :invalid-feedback="$t('validation.userNotFound')"
            :state="notificationAccountValidation"
            id="notificationAccount"
          >
            <b-form-input
              placeholder="16515313"
              v-model="notificationAccountTelegramId"
              id="notificationAccountInput"
              :state="notificationAccountValidation"
            />
          </b-form-group>

          <b-tooltip target="notificationAccount" triggers="focus">
            {{ $t('restaurant.account_tooltip') }}
          </b-tooltip>
        </b-col>

        <b-col md="6">
          <b-form-group
            :label="$t('restaurant.address')"
            :state="addressValidation"
            :invalid-feedback="
              $t('validation.mustBeLessThan', {
                amount: 256,
              })
            "
          >
            <b-form-input
              :placeholder="$t('restaurant.address')"
              type="text"
              v-model="restaurant.address"
              required
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <h5>{{ $t('restaurant.delivery') }}</h5>
        </b-col>
      </b-row>

      <div class="mb-3">
        <b-col md="6">
          <label for="input-18">
            {{ $t('restaurant.maximumTimeForPreorder') }}
          </label>
          <b-input
            id="input-18"
            type="number"
            placeholder="12"
            v-model="restaurant.maximumPreorderTime"
          />
        </b-col>
        <b-col md="6">
          <label for="input-19">
            {{ $t('restaurant.districtId') }}
          </label>
          <b-form-select
            class="col-4"
            v-model="restaurant.districtId"
            :options="districtsSelector"
          ></b-form-select>
        </b-col>
      </div>

      <b-row class="mb-3">
        <b-col>
          <b-form-checkbox v-model="restaurant.canPickup" class="col-4">
            {{ $t('restaurants.canPickup') }}
          </b-form-checkbox>

          <b-form-checkbox v-model="restaurant.canDelivery" class="col-4">
            {{ $t('restaurants.canDelivery') }}
          </b-form-checkbox>
        </b-col>
      </b-row>

      <b-row class="mb-3" v-if="restaurant.canDelivery">
        <b-col :md="districts.length > 0 ? 12 : 8">
          <b-form-group
            :label="$t('restaurant.deliveryTime', { time: '' })"
            :state="deliveryTimeValidation"
            :invalid-feedback="
              $t('validation.mustBeLessThan', {
                amount: 256,
              })
            "
          >
            <b-form-input
              id="input-4"
              type="text"
              v-model="restaurant.deliveryTime"
              v-bind:placeholder="$t('restaurant.deliveryTime_placeholder')"
            />
          </b-form-group>
        </b-col>

        <b-col
          v-if="districts.length > 0"
          v-for="districtRestaurant in districtRestaurants"
          :key="districtRestaurant.districtId"
          md="6"
        >
          <b-row>
            <b-col md="12">
              <h6>
                {{ districtRestaurant.district.name }}
                /
                {{ districtRestaurant.district.name_ru }}
                -
                {{ $t('restaurant.deliveryPrice') }}
              </h6>
            </b-col>

            <b-col md="12" class="mb-3">
              <b-input-group>
                <b-input-group-prepend is-text>
                  <b-form-checkbox
                    class="mr-n2"
                    v-model="districtRestaurant.enabled"
                    switch
                  />
                </b-input-group-prepend>

                <b-form-input
                  placeholder="200.00"
                  type="number"
                  step="0.01"
                  v-model="districtRestaurant.price"
                  :disabled="!districtRestaurant.enabled"
                  :required="Boolean(districtRestaurant.enabled)"
                />
              </b-input-group>
            </b-col>
          </b-row>
        </b-col>

        <b-col v-if="districts.length <= 0">
          <b-form-group>
            <label for="input-4">
              {{
                $t('restaurant.deliveryPrice', {
                  deliveryPrice: restaurant.deliveryPrice,
                })
              }}
            </label>

            <b-form-input
              id="input-4"
              placeholder="200.00"
              type="number"
              step="0.01"
              v-model="restaurant.deliveryPriceInput"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <photo-uploader
        :title="$t('restaurant.image')"
        :cityId="restaurant.cityId"
        :telegramFileId="restaurant.telegramFileId"
        @uploaded="onUploaded"
        @uploading="onUploading"
      />

      <b-button
        class="mt-3"
        squared
        type="submit"
        variant="outline-primary"
        :disabled="loading || photoUploading || !allValidationHasBeenPassed"
      >
        {{ $t('restaurant.update') }}
      </b-button>
    </b-form>
  </b-container>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  ICity,
  ICuisine,
  IDistrict,
  IDistrictRestaurant,
  IRestaurant,
  RoleEnum,
} from 'types';

import PhotoUploader from './PhotoUploader.vue';

type RestaurantResponsed = IRestaurant & {
  notificationUser: { telegramId: string | number };
};

interface RawRestaurant extends Omit<IRestaurant, 'position' | 'minOrderSum'> {
  notificationUser: { telegramId: string | number };
  deliveryPriceInput: string;
  positionInput: string;
  minOrderSumInput: string;
  active: boolean;
}

const storagePrefix = process.env.VUE_APP_STORAGE_PREFIX;

const SCHEDULE_REGEX = /^([0-9]{2}:[0-6][0-9]-[0-9]{2}:[0-6][0-9],?){1,2}$/;

@Component({
  name: 'Restaurant',
  components: { 'photo-uploader': PhotoUploader },
})
export default class Restaurant extends Vue {
  @Prop() id!: number;

  get notificationAccountTelegramId() {
    return this.restaurant.notificationUser?.telegramId;
  }

  set notificationAccountTelegramId(value) {
    this.restaurant.notificationUser = { telegramId: value };
  }

  get isAdmin() {
    return this.$store.getters.roleId === RoleEnum.ADMIN;
  }

  get isRestaurantOwner() {
    return this.$store.getters.roleId === RoleEnum.RESTAURANT_OWNER;
  }

  get isCityAdmin() {
    return [RoleEnum.ADMIN, RoleEnum.CITY_ADMIN].includes(
      this.$store.getters.roleId,
    );
  }

  get isOperator() {
    return [RoleEnum.ADMIN, RoleEnum.CITY_ADMIN, RoleEnum.OPERATOR].includes(
      this.$store.getters.roleId,
    );
  }

  get cityId() {
    return this.$store.getters.cityId;
  }

  /**
   * Variables that are responsible for editting
   * restaurant info
   */
  restaurant: RawRestaurant = {
    id: '',
    name: '',
    name_ru: '',
    positionInput: '',
    deliveryPriceInput: '',
    deliveryTime: '',
    enabled: false,
    minOrderSumInput: '',
    canPickup: false,
    canDelivery: false,
    telegramFileId: '',
    schedule: '',
    notificationUser: { telegramId: '' },
    cityId: '',
    city: {
      id: '',
      name: '',
      name_ru: '',
      chatname: '',
      currency: '',
    },
    workingDays: [
      { value: 1, enabled: true },
      { value: 2, enabled: true },
      { value: 3, enabled: true },
      { value: 4, enabled: true },
      { value: 5, enabled: true },
      { value: 6, enabled: true },
      { value: 7, enabled: true },
    ],
    link: '',
    maximumPreorderTime: null,
    active: false,
  };

  districts: IDistrict[] = [];
  districtRestaurants: IDistrictRestaurant[] = [];
  districtsSelector: { value: string; text: string }[] = [];
  linkVisible = false;

  photoUploading = false;
  loading = true;

  getLocalizedDayOfWeek(dayOfWeek: { value: number }) {
    switch (dayOfWeek.value) {
      case 1:
        return this.$t('restaurant.monday');
      case 2:
        return this.$t('restaurant.tuesday');
      case 3:
        return this.$t('restaurant.wednesday');
      case 4:
        return this.$t('restaurant.thursday');
      case 5:
        return this.$t('restaurant.friday');
      case 6:
        return this.$t('restaurant.saturday');
      case 7:
        return this.$t('restaurant.sunday');
    }
  }

  /**
   * Variables that are responsible for validation
   */
  errors: {
    [key: string]: string;
  } = {};

  notificationUserNotFound: boolean | null = null;
  get notificationAccountValidation() {
    return this.notificationUserNotFound === null ? null : false;
  }

  get restaurantNameValidation() {
    if (!this.restaurant?.name) return false;

    return (
      this.restaurant.name.length < 256 && this.restaurant.name.length >= 3
    );
  }

  get restaurantNameRuValidation() {
    if (!this.restaurant?.name_ru) return false;

    return (
      this.restaurant.name_ru.length < 256 &&
      this.restaurant.name_ru.length >= 3
    );
  }

  get descriptionValidation() {
    if (!this.restaurant?.description) return null;

    return this.restaurant.description.length < 512;
  }

  get descriptionEnValidation() {
    if (!this.restaurant?.description_ru) return null;

    return this.restaurant.description_ru.length < 512;
  }

  get receiptTextValidation() {
    if (!this.restaurant?.receipt_text) return null;

    return this.restaurant.receipt_text.length < 128;
  }

  get receiptTextRuValidation() {
    if (!this.restaurant?.receipt_text_ru) return null;

    return this.restaurant.receipt_text_ru.length < 128;
  }

  get scheduleValidation() {
    if (!this.restaurant.schedule) return false;

    return SCHEDULE_REGEX.test(this.restaurant.schedule);
  }

  get addressValidation() {
    if (!this.restaurant.address) return null;

    return this.restaurant.address.length < 256;
  }

  get deliveryTimeValidation() {
    if (!this.restaurant.deliveryTime) return null;

    return this.restaurant.deliveryTime.length < 256;
  }

  get allValidationHasBeenPassed() {
    return (
      this.restaurantNameValidation !== false &&
      this.restaurantNameRuValidation !== false &&
      this.descriptionValidation !== false &&
      this.descriptionEnValidation !== false &&
      this.scheduleValidation !== false &&
      this.addressValidation !== false &&
      this.deliveryTimeValidation !== false
    );
  }

  /**
   * Variables that are responsible for editting restaurant
   * data that only admins can change
   */
  selectedCuisines: string[] = [];
  selectedCity: ICity = null;
  allCuisines: ICuisine[] = [];
  cities: ICity[] = [];

  interfaceLang = this.$store.getters.interfaceLang;

  async mounted() {
    const restaurant = await this.axios.get('/restaurants/' + this.id);

    this.restaurant = {
      active: false,
      ...(restaurant.data as RestaurantResponsed),
      deliveryPriceInput: restaurant.data.deliveryPrice,
      positionInput: restaurant.data.position,
      minOrderSumInput: restaurant.data.minOrderSum,
    };

    if (this.restaurant.restaurantCuisines) {
      this.selectedCuisines = this.restaurant.restaurantCuisines.map(
        (element) => element.cuisineId,
      );
    }

    this.selectedCity = this.restaurant.city;

    const districts = await this.axios.get<IDistrict[]>(
      `/districts/plain/${this.restaurant.cityId}`,
    );

    this.districts = districts.data;
    this.districtsSelector = this.districts.map((d) => ({
      value: d.id,
      text: `${d.name} (${d.name_ru})`,
    }));
    this.districtRestaurants = districts.data.map((district) => {
      return {
        restaurantId: this.restaurant.id,
        districtId: district.id,
        enabled: false,
        price: null,
        district: {
          name: district.name,
          name_ru: district.name_ru,
        },
      } as IDistrictRestaurant;
    });

    /**
     * To show existing relations between districts and restaurants,
     * we map the existing relations over blank ones that were created
     * two lines before.
     */

    if (this.restaurant.districtRestaurants) {
      const { districtRestaurants } = this.restaurant;

      for (const er of districtRestaurants) {
        const blankRelation = this.districtRestaurants.find(
          (dr) => dr.districtId === er.districtId,
        );

        blankRelation.enabled = er.enabled;
        blankRelation.price = er.price || null;
      }
    }

    const cuisines = await this.axios.get(
      '/cuisines/city/' + this.restaurant.cityId,
    );

    this.allCuisines = cuisines.data;

    this.axios.get('/cities').then((response) => {
      this.cities = response.data.map((city: ICity) => ({
        value: city,
        text: city.name,
      }));
    });

    this.loading = false;
  }

  forceUpdate() {
    setTimeout(() => this.$forceUpdate(), 50);
  }

  onDelete() {
    if (confirm(this.$t('restaurant.confirm_delete').toString())) {
      this.axios
        .delete('/restaurants/' + this.id)
        .then(() => this.$router.push({ path: '/restaurants' }));
    }
  }

  onLink() {
    localStorage.setItem(`${storagePrefix}-restaurantId`, this.id.toString());

    this.$router.push('/dishCategories/');
  }

  onReviews() {
    this.$router.push('/restaurants/' + this.restaurant.id + '/reviews');
  }

  onUploading() {
    this.photoUploading = true;
  }

  onUploaded(telegramFileId: string) {
    this.photoUploading = false;
    this.restaurant.telegramFileId = telegramFileId;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onSubmit(e: Event) {
    this.errors = {};

    // if (this.restaurant.schedule) {
    //     if (
    //         !/^([0-9]{2}:[0-6][0-9]-[0-9]{2}:[0-6][0-9],?){1,2}$/.test(
    //             this.restaurant.schedule
    //         )
    //     ) {
    //         this.errors.schedule = this.$t(
    //             'restaurant.schedule_error'
    //         ).toString()
    //         e.preventDefault()
    //         return false
    //     }
    // }

    // Removing unnecessary fields
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      notificationAccountId,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      restaurantCuisines,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      districtRestaurants: oldDistrictRestaurants,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      city,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      notificationUser,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      deliveryPriceInput,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      minOrderSumInput,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      positionInput,
      ...rawForm
    } = this.restaurant;

    const districtRestaurants = this.districtRestaurants.map((dr) => ({
      ...dr,
      price: dr.price || 0,
    }));

    // Converting data that we need as a form

    const form = {
      ...rawForm,
      deliveryPrice: parseInt(this.restaurant.deliveryPriceInput),
      minOrderSum: parseInt(this.restaurant.minOrderSumInput),
      position: parseInt(this.restaurant.positionInput),
      cityId: this.isCityAdmin ? this.cityId : this.selectedCity.id,
      selectedCuisines: this.selectedCuisines,
      notificationAccountTelegramId: this.notificationAccountTelegramId,
      districtRestaurants:
        this.districts?.length > 0 ? districtRestaurants : null,
    };

    this.axios
      .put<IRestaurant>(`/restaurants/${this.id}`, form)
      .then((response) => {
        if (response instanceof Error) throw response;

        this.$router.push(this.isRestaurantOwner ? '/' : '/restaurants');
      })
      .catch((e) => {
        switch (e.response?.data?.code) {
          case 'FST_KEY_IS_MISSING':
            if (e.response.data.message === 'districtRestaurant.price') {
              this.errors.district = 'error';
            }

            break;
          case 'FST_NOTIFICATION_ACCOUNT_NOT_FOUND':
            this.notificationUserNotFound = true;

            break;
          default:
            break;
        }
      });
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
