<template>
  <div v-if="regionCityId">
    <div class="row">
      <div class="col">
        <h1>{{ $t('region.all') }}</h1>
        <b-button
          squared
          :to="`/city/${regionCityId}/district`"
          variant="outline-success"
          >{{ $t('region.add') }}</b-button
        >
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <b-table :fields="fields" :items="districts" hover striped>
          <template v-slot:cell(name)="data">
            <b-link :to="`/city/${data.item.cityId}/district/${data.item.id}`"
              >{{ data.item.name }} / {{ data.item.name_ru }}</b-link
            >
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IDistrict } from 'types';

@Component({ name: 'Districts' })
export default class Regions extends Vue {
  @Prop() cityId!: number;

  regionCityId = '';

  fields = [
    {
      key: 'name',
      label: this.$t('region.name'),
      sortable: true,
    },
  ];

  districts: IDistrict[] = [];

  async mounted() {
    this.regionCityId = this.$route.params.cityId;
    const response = await this.axios.get(
      `/cities/${this.regionCityId}/districts`,
    );
    this.districts = response.data;
  }
}
</script>

<style scoped></style>
