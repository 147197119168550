export default {
  validation: {
    mustBeLessThan: 'Длина поля не должна превышать {amount} символов',
    mustBeMoreAndLessThan:
      'Длина поля должна быть больше {moreAmount} и меньше {lessAmount} символов',
    userNotFound: 'Пользователь не найден',
    scheduleError:
      'Время работы можно указать только в таком формате: 09:30-00:00 или 09:30-14:00,15:00-20:30.',
    cannotBeNull: 'Не может быть нулём',
    cantPublishAds: 'Ошибка публикации рекламы: ',
  },
  ad: {
    chatId: 'ID чата для публикации',
    h1: 'Реклама',
    edit: 'Edit ad',
    title: 'Title: {title}',
    title_placeholder: 'Ad #1',
    text: 'Текст рекламы: {text}',
    text_placeholder:
      'Лучший ресторан в галактике доставит вам радость, спокойствие и фруктовые коктейли.',
    publishDate: 'Начало публикации: {publishDate}',
    unpublishDate: 'Окончание публикации: {unpublishDate}',
    restaurant: 'Restaurant: {restaurant}',
    image: 'Изображение (не обязательно):',
    drop_placeholder: 'Drop here',
    file_placeholder: 'Choose file',
    add: '+ Реклама',
    save: 'Save',
    delete: 'Delete advertisement',
    new: '+ Реклама',
    status: 'Статус',
    created: 'Waiting',
    published: 'Опубликовано',
    removed: 'Удалено',
    unpublished: 'Удалено',
    timeout: 'Следующая публикация доступна: {timeout}',
    reload: 'Reload page',
    publicationError: 'Ошибка при публикации',
    removePublicationError: 'Ошибка при удалении сообщения',
    lengthValidation: 'Длина сообщения должна быть менее 1024 символов',
    waitingForImage: 'Прикрепляет изображение к рекламе',
    pleaseWaitUpload: 'Пожалуйста, подождите пока фотография загрузится...',
  },
  city: {
    add: '+ Добавить город',
    active: 'Город активен',
    all: 'Города',
    chatname: 'Чат для общения',
    currency: 'Валюта в городе',
    one: 'Город',
    save: 'Сохранить изменения',
    regions: 'Районы ->',
    name: 'Название (англ)',
    name_ru: 'Название',
    cityDisabledReason: 'Причина, по которой город отключен',
    choose: 'Выберите город',
    choose_placeholder: 'Пожалуйста, выберите город из списка',
    deliveryCostJump: 'Изменение стоимости доставки',
    deliveryCostJumpStart: 'Когда стоимость доставки начинает меняться',
    locales: 'Языки в канале курьеров',
    hideMoney: 'Скрывать суммы заказов в курьерском чате',
  },
  config: {
    ads: 'Advertisements',
    adsChannelId: 'Channel ID for advertisements',
    adsDelay: 'Timeout beetween advertisements (in hours)',
    adsTime: 'Time for publication (in hours)',
    deliveryMultiplier: 'Множитель стоимости доставки',
    delete: 'Удалить',
    ids: 'Identificators',
    infoButtonText_en: 'Info text in English',
    infoButtonText_ru: 'Info text in Russian',
    mainImage: 'Изображение по умолчанию',
    notifyChannelId: 'Channel ID for notifications',
    reviewChannelId: 'Channel ID for reviews',
    save: 'Save',
    settings: 'Settings',
    showInfoButton: 'Show info button',
    showTopTen: 'Show top 10 button',
    showOpenNow: 'Показывать "Открытые сейчас"',
    showTagsButton: 'Показывать теги',
    deliveryCostJump: 'Изменять стоимость доставки',
    annoyRestaurants: 'Уведомлять рестораны о задержках',
  },
  cuisines: {
    enabled: 'Показывать в списке',
  },
  dishCategories: {
    title: 'Категории блюд',
    add_button: '+ Добавить категорию блюд',
    name: 'Название (en)',
    name_ru: 'Название',
    position: 'Позиция в списке',
    enabled: 'Показывать в боте',
    edit: 'Редактировать',
    dishes: 'Блюда',
    displayBetween: 'Показывать в:',
    alwaysDisplayed: 'всегда',
    lastUpdated: 'Последнее обновление:',
  },
  dishCategory: {
    add: 'Добавить категорию',
    button_remove: 'Удалить категорию',
    name_ru: 'Название категории',
    name_ru_placeholder: 'Название категории',
    name: 'Название категории (en)',
    name_placeholder: 'Название категории (en)',
    position: 'Позиция в списке',
    dishes: 'Блюда',
    button_update: 'Обновить категорию',
    enabled: 'Показывать в боте',
    startTime: 'Начать показывать в:',
    endTime: 'Прекратить показывать в:',
    confirm_delete: 'Вы уверены, что хотите удалить категорию?',
  },
  dishes: {
    title: 'Блюда',
    button_add: 'Добавить блюдо',
    name: 'Название (en)',
    name_ru: 'Название',
    position: 'Позиция в списке',
    description: 'Описание товара',
    price: 'Цена',
    enabled: 'Показывать в боте',
    amount: 'Остаток',
    edit: 'Редактировать',
    lastUpdated: 'Последнее обновление:',
  },
  dish: {
    title: 'Добавить блюдо',
    name: 'Название блюда (en):',
    name_placeholder: 'Название блюда (en)',
    name_ru: 'Название блюда:',
    name_ru_placeholder: 'Название блюда',
    position: 'Позиция в списке:',
    description_ru: 'Описание товара (рус):',
    description_ru_placeholder: 'Описание товара (рус)',
    description: 'Описание товара (en):',
    description_placeholder: 'Описание товара (en)',
    price: 'Цена:',
    image: 'Изображение:',
    image_placeholder: 'Выберите файл',
    button_add: 'Добавить блюдо',
    button_remove: 'Удалить блюдо',
    button_update: 'Обновить блюдо',
    enabled: 'Показывать в боте',
    amount: 'Считать остаток',
    tags: 'Тэги',
    tags_disclaimer:
      'Теги нужны для ускорения поиска блюд для покупателей. Вы можете выбрать не более 2 тегов',
    confirm_delete: 'Вы уверены, что хотите удалить блюдо?',
  },
  home: {
    title: 'Главная',
    body: 'Выбери язык',
    menuMovedTitle: 'Меню переехало сюда!',
    menuMovedText: 'Вы можете нажать на эту кнопку чтобы открыть его.',
    dontShowAgain: 'Не показывать больше',
    pleaseReloadPage: 'Вышла новая версия!',
    pleaseReloadPageDetails: `Мы постоянно работаем над улучшением нашего сервиса. Пожалуйста, обновите страницу, чтобы продолжать полноценно использовать наш продукт!`,
  },
  logout: {
    title: 'Выход',
    button: 'Выйти',
  },
  language: {
    search_placeholder: 'Поиск...',
  },
  login: {
    title: 'Вход',
    login: 'Логин',
    login_placeholder: 'Введите аккаунт',
    password: 'Пароль',
    password_placeholder: 'Введите пароль',
    button: 'Вход',
    error: 'Вы ввели неправильный пароль или логин',
  },
  bots: {
    title: 'Список запущенных ботов',
    add: 'Добавить бота',
    main_page_title: 'Бот города',
  },
  menu: {
    reviews: 'Отзывы',
    bots: 'Боты',
    ads: 'Реклама',
    login: 'Вход',
    logout: 'Выход',
    cities: 'Города',
    cuisines: 'Кухни',
    restaurants: 'Рестораны',
    channel: 'Телеграм канал',
    config: 'Настройки',
    russian: 'Русские надписи',
    english: 'Английские надписи',
    georgian: 'Грузинские надписи',
    spam: 'Массовая рассылка',
    stats: 'Статистика',
    orders: 'Заказы',
    top: 'Топ покупаталей',
    users: 'Администраторы',
    myrest: 'Мой ресторан',
    regions: 'Районы',
    city: 'Настройки города',
    tags: 'Тэги',
  },
  region: {
    all: 'Районы',
    add: '+ Добавить район',
    delete: 'Удалить район',
    one: 'Район',
    name: 'Название района (на английском)',
    name_ru: 'Название района',
    regions: 'Укажите районы (1 на линию)',
    regions_placeholder: 'Left\nRight',
    save: 'Сохранить изменения',
  },
  restaurant: {
    descriptionLengthError: 'Длина описания должна быть меньше 512 символов',
    address: 'Ссылка на гугл-карту',
    general: 'Общие настройки',
    contact: 'Контакты',
    cities: 'Ресторан работает в городе:',
    maximumTimeForPreorder: 'Максимальное время для предзаказа (в часах)',
    chose_delivery_city: 'Выберите города, в которых доступна доставка',
    delivery: 'Настройки доставки',
    title_add: 'Новый ресторан',
    remove: 'Удалить',
    dishCategories: 'Категории блюд',
    dishCategory: 'Категории блюд',
    name: 'Название ресторана (en): {name}',
    name_ru: 'Название ресторана: {name}',
    name_placeholder: 'Название ресторана (en)',
    name_ru_placeholder: 'Название ресторана',
    receipt_text_ru: 'Доп текст в чеке (рус):',
    receipt_text: 'Доп текст в чеке (en):',
    description_ru: 'Описание ресторана (рус):',
    description_ru_placeholder: 'Описание ресторана (рус)',
    description: 'Описание ресторана (en):',
    description_placeholder: 'Описание товара (en)',
    position: 'Позиция в списке: {position}',
    minOrderSum: 'Минимальная сумма заказа: {sum}',
    deliveryTime: 'Время доставки: {time}',
    deliveryTime_placeholder: '25 минут',
    schedule: 'Время работы: {schedule}',
    schedule_placeholder: '09:00-00:00',
    schedule_error:
      'Время работы можно указать только в таком формате: 09:30-00:00 или 09:30-14:00,15:00-20:30.',
    deliveryPrice: 'Стоимость доставки:',
    notificationAccount:
      'Телеграм аккаунт для оповещения: {notificationAccount}',
    districtId: 'Район, в котором расположен ресторан',
    cuisines: 'Кухни:',
    image: 'Изображение:',
    file_placeholder: 'Выберите файл',
    update: 'Обновить данные',
    url: 'Прямая ссылка на ресторан в боте:',
    button_add: 'Создать ресторан',
    account_tooltip:
      'Напишите любому нашему боту команду /id чтобы узнать свой Телеграм ID',
    monday: 'Понедельник',
    tuesday: 'Вторник',
    wednesday: 'Среда',
    thursday: 'Четверг',
    friday: 'Пятница',
    saturday: 'Суббота',
    sunday: 'Воскресенье',
    link: 'Ссылка на ресторан',
    linkUnavailable:
      '🚫 Бот не работает в городе, в котором находится ресторан',
    confirm_delete: 'Вы уверены, что хотите удалить ресторан?',
  },
  restaurants: {
    title: 'Рестораны',
    add_button: '+ Добавить ресторан',
    name: 'Название (en)',
    name_ru: 'Название',
    enabled: 'Показывать в списке',
    active: 'Включен (admin)',
    canPickup: 'Есть самовывоз?',
    canDelivery: 'Есть доставка?',
    canSelfCuisine: 'Показывать выбор кухонь',
    position: 'Позиция в списке',
    minOrderSum: 'Минимальная сумма заказа',
    deliveryTime: 'Время доставки',
    deliveryPrice: 'Стоимость доставки',
    notificationAccount: 'Аккаунт для уведомлений',
  },
  spam: {
    user_total_count: 'Общее число пользователей',
  },
  chat: {
    title: 'Чат с клиентом {customerName}',
    orderHasBeenChanged: 'Пользователь сделал заказ №{threadId}',
    inputPlaceholder: 'Напишите сообщение для отправки',
    send: 'Отправить',
    sendToRestaurant: 'Отправить в ресторан',
    chatIsEmpty: 'В этом чате еще нет сообщений',
    customer: 'Покупатель',
  },
  orders: {
    title: 'Заказы',
    export: 'Экспортировать в Эксель',
    startingDate: 'Дата начала:',
    endingDate: 'Дата окончания:',
    city: 'Город:',
    restaurants: 'Рестораны:',
    restaurantDisclaimer: 'Выберите один или несколько ресторанов',
    showNumbers: 'Показывать номера?',
    totalSum: 'Общая сумма за выбранный период:',
    totalSumWithoutDelivery:
      'Общая сумма без учета стоимости доставки за период:',
    ordersAmount: 'Количество заказов:',
    averageCheque: 'Средний чек:',
    sum: 'Сумма:',
    sumWithoutDelivery: 'Сумма без стоимости доставки:',
    orderedAt: 'Дата заказа:',
    customerName: 'Имя покупателя:',
    contactType: 'Контакт:',
    phone: 'Номер телефона:',
    status: 'Статус:',
    openChat: 'Открыть чат',
    enableBriefSummary: 'Включить короткую сводку?',
    paid: 'Оплачено:',
    paymentReceipt: 'Charge ID:',
    serviceFee: 'Сервисный сбор:',
    courier: 'Курьер:',
    invoiceId: 'Invoice ID:',
    changeStatus: 'Сменить статус',
    statusesHelp: {
      noStatus: 'Неизвестный статус',
      RESTAURANT_CLOSED: 'Ресторан закрыт',
      CANCELED_BY_RESTAURANT: 'Отменен рестораном',
      CANCELED_BY_USER: 'Отменен клиентом',
      DRAFT: 'Черновик заказа',
      CREATED: 'Только что создан, будет оплачен наличкой',
      NOT_PAID: 'Только что создан, ждет оплаты картой',
      PAID: 'Оплачен картой',
      WAITING_FOR_COURIER: 'Ожидает курьера',
      READY: 'Ресторан приготовил заказ, ожидает подтверждения оператором',
      CONFIRMED: 'Ресторан подтвердил заказ, начинает его готовить',
      SERVING: 'Заказ готовится в ресторане',
      DELAYED: 'Заказ задерживается',
      COOKED: 'Заказ приготовлен',
      IN_RESTAURANT: 'Заказ готов к выдаче',
      OUT_FOR_DELIVERY: 'Заказ в пути',
      CALL_CUSTOMER: 'Нужно позвонить клиенту',
      DELIVERED: 'Заказ доставлен',
    },
    statuses: {
      noStatus: 'неизвестно',
      RESTAURANT_CLOSED: 'ресторан закрыт',
      CANCELED_BY_RESTAURANT: 'отменен рестораном',
      CANCELED_BY_USER: 'отменен клиентом',
      DRAFT: 'черновик заказа',
      CREATED: 'не подтвержден клиентом',
      NOT_PAID: 'не оплачен',
      PAID: 'оплачен',
      CONFIRMED: 'подтвержден',
      SERVING: 'готовится',
      DELAYED: 'задерживается',
      COOKED: 'приготовлен',
      WAITING_FOR_COURIER: 'готов к выдаче',
      IN_RESTAURANT: 'в ресторане',
      OUT_FOR_DELIVERY: 'в пути',
      CALL_CUSTOMER: 'нужно позвонить клиенту',
      DELIVERED: 'доставлен',
    },
  },
  tags: {
    newTag: 'Новый тэг',
    name: 'Название на английском',
    name_ru: 'Название на русском',
    update: 'Обновить тэг',
    create: 'Создать тэг',
  },
  reviews: {
    title: 'Отзывы',
    noText: 'Отзыв без текста',
    chooseCity: 'Выберите город',
    answer: {
      label: 'Ответьте на отзыв:',
      submit: 'Отправить',
    },
    controlling: {
      delete: 'Удалить',
      approve: 'Сохранить',
      publish: 'Опубликовать',
      answer: 'Ответить',
    },
  },
};
