<template>
  <b-container fluid v-if="category">
    <b-row>
      <b-col>
        <h3>{{ category[`name_${interfaceLang}`] || category.name }}</h3>
      </b-col>

      <b-col align-self="center" class="d-flex justify-content-end">
        <b-button variant="outline-success" @click="onLink">
          {{ $t('dishCategory.dishes') }}
        </b-button>
      </b-col>
    </b-row>

    <hr />

    <b-form @submit.prevent="onSubmit">
      <b-row class="mt-3">
        <b-col md="6">
          <b-form-group
            :label="$t('dishCategory.name')"
            :state="nameValidation"
            :invalid-feedback="$t('validation.mustBeLessThan', { amount: 256 })"
          >
            <b-form-input v-model="category.name" required />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            :label="$t('dishCategory.name_ru')"
            :state="nameRuValidation"
            :invalid-feedback="$t('validation.mustBeLessThan', { amount: 256 })"
          >
            <b-form-input v-model="category.name_ru" required />
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group :label="$t('dishCategory.position')">
        <b-form-input v-model="category.position" type="number" required />
      </b-form-group>

      <b-form-checkbox
        v-model="category.enabled"
        @input="changeState(category)"
      >
        {{ $t('dishCategories.enabled') }}
      </b-form-checkbox>

      <b-row class="mt-3">
        <b-col md="6">
          <b-form-group :label="$t('dishCategory.startTime')">
            <b-form-timepicker
              v-model="category.startDisplayingAt"
              locale="ru"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group :label="$t('dishCategory.endTime')">
            <b-form-timepicker
              v-model="category.stopDisplayingAt"
              locale="ru"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <div class="d-flex justify-content-between mt-3">
        <b-button
          v-if="category"
          type="submit"
          variant="primary"
          :disabled="!allValidationHasBeenPassed"
        >
          {{ $t('dishCategory.button_update') }}
        </b-button>

        <b-button
          v-if="!isOperator"
          variant="link"
          v-b-hover="onDeleteHover"
          :class="deleteLinkHovered ? 'text-danger' : 'text-secondary'"
          @click="onDelete"
        >
          {{ $t('dishCategory.button_remove') }}
        </b-button>
      </div>
    </b-form>
  </b-container>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RoleEnum, IDishCat } from 'types';

const storagePrefix = process.env.VUE_APP_STORAGE_PREFIX;

@Component({ name: 'DishCategory' })
export default class DishCategory extends Vue {
  @Prop()
  id: string;

  deleteLinkHovered = false;
  onDeleteHover(value: boolean) {
    this.deleteLinkHovered = value;
  }

  category: IDishCat = null;

  interfaceLang = this.$store.getters.interfaceLang;

  async mounted() {
    const response = await this.axios.get('/dishCategories/' + this.id);
    this.category = response.data;
  }

  public onDishesLinkPressed(category: IDishCat) {
    localStorage.setItem(`${storagePrefix}-dishCategoryId`, category.id);
    this.$router.push('/dishes');
  }

  async onDelete() {
    if (confirm(this.$t('dishCategory.confirm_delete').toString())) {
      await this.axios.delete('/dishCategories/' + this.id);
      this.$router.push('/dishCategories');
    }
  }

  onLink() {
    localStorage.setItem(`${storagePrefix}-dishCategoryId`, this.category.id);
    this.$router.push('/dishes');
  }

  async onSubmit() {
    // Removing seconds from time

    if (this.category.startDisplayingAt) {
      this.category.startDisplayingAt = this.category.startDisplayingAt.slice(
        0,
        5,
      );
    }

    if (this.category.stopDisplayingAt) {
      this.category.stopDisplayingAt = this.category.stopDisplayingAt.slice(
        0,
        5,
      );
    }

    // Sending the updated data

    await this.axios.put('/dishCategories/' + this.id, this.category);

    // Going back

    this.$router.push('/dishCategories');
  }

  /** Here goes validation */

  get nameValidation() {
    if (!this.category?.name) return null;

    return this.category.name.length < 256;
  }

  get nameRuValidation() {
    if (!this.category?.name_ru) return null;

    return this.category.name_ru.length < 256;
  }

  get allValidationHasBeenPassed() {
    return this.nameValidation !== false && this.nameRuValidation !== false;
  }

  get isOperator() {
    return this.$store.getters.roleId === RoleEnum.OPERATOR;
  }
}
</script>
