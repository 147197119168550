<template>
  <div>Page not found</div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'Page404',
})
export default class Page404 extends Vue {}
</script>
