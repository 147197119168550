<template>
  <div v-if="user">
    <div class="row mb-3">
      <div v-if="id" class="col">
        <h1>{{ user.username }}</h1>
        <b-button
          squared
          v-on:click.prevent="onDelete"
          variant="outline-danger"
        >
          Удалить администратора
        </b-button>
      </div>
      <div v-else class="col">
        <h1>Добавить администратора</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-form @submit.prevent="onSubmit">
          <label for="input-1">Юзернейм:</label>
          <b-form-group id="input-group-1">
            <b-form-input
              id="input-1"
              placeholder="Юзернейм"
              type="text"
              v-model="user.username"
            ></b-form-input>
          </b-form-group>

          <label for="input-2">Новый пароль:</label>
          <b-form-group id="input-group-2">
            <b-form-input
              id="input-2"
              placeholder="S3cR4tP@55w0rd"
              type="password"
              autocomplete="off"
              v-model="password"
            ></b-form-input>
          </b-form-group>

          <label for="input-2">Telegram Id:</label>
          <b-form-group id="input-group-2">
            <b-form-input
              id="input-tg-id"
              placeholder="1234567"
              type="number"
              autocomplete="off"
              v-model="user.telegramId"
            ></b-form-input>
          </b-form-group>

          <!-- City admins can't create any admin with any role other than the restaurateur -->

          <div v-if="canChoseRole">
            <label for="input-2">Роль:</label>
            <b-form-select
              v-model="user.roleId"
              :options="roles[currentUserRoleId]"
              class="mb-3"
            ></b-form-select>
          </div>

          <div v-if="user.roleId == 20">
            <label for="input-2">Ресторан:</label>
            <b-form-select
              v-model="user.restaurantId"
              :options="restaurants"
              class="mb-3"
            ></b-form-select>
          </div>

          <div v-if="canChoseCity">
            <label for="input-2">Город:</label>
            <b-form-select
              v-model="user.cityId"
              :options="cities"
              class="mb-3"
            ></b-form-select>
          </div>

          <b-button
            squared
            type="submit"
            variant="outline-primary"
            :disabled="!user"
          >
            Обновить данные
          </b-button>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IAdmin, RoleEnum } from 'types';

@Component({ name: 'Admin' })
export default class Admin extends Vue {
  @Prop() id!: string;

  password = '';
  user: IAdmin = {
    id: null,
    roleId: RoleEnum.COURIER,
    cityId: [RoleEnum.ADMIN].includes(this.currentUserRoleId)
      ? null
      : this.currentUserCityId,
    restaurantId: null,
    restaurant: null,
    telegramId: null,
    password: '',
    username: '',
  };

  restaurants = [];
  cities = [];
  roles: Record<RoleEnum, Array<{ value: RoleEnum; text: string }>> = {
    [RoleEnum.ADMIN]: [
      { value: 10, text: 'Главный администратор 👑' },
      { value: 30, text: 'Администратор города 🏠' },
      { value: 20, text: 'Владелец ресторана 🍲' },
      { value: 40, text: 'Оператор 🍽' },
      { value: 50, text: 'Курьер 🚴‍♂️' },
    ],
    [RoleEnum.CITY_ADMIN]: [
      { value: 20, text: 'Владелец ресторана 🍲' },
      { value: 40, text: 'Оператор 🍽' },
      { value: 50, text: 'Курьер 🚴‍♂️' },
    ],
    [RoleEnum.RESTAURANT_OWNER]: [],
    [RoleEnum.OPERATOR]: [{ value: 50, text: 'Курьер 🚴‍♂️' }],
    [RoleEnum.COURIER]: [],
    [RoleEnum.USER]: [],
  };

  get currentUserCityId() {
    return this.$store.getters.cityId;
  }

  get currentUserRoleId() {
    return this.$store.getters.roleId;
  }

  get canChoseRole() {
    return this.roles[this.currentUserRoleId].length > 0;
  }

  get canChoseCity() {
    return [RoleEnum.ADMIN].includes(this.currentUserRoleId);
  }

  mounted() {
    if (this.id) {
      this.axios.get('/users/' + this.id).then((response) => {
        this.user = response.data;
      });
    }

    this.axios.get('/restaurants').then((response) => {
      this.restaurants = response.data.map((rest) => ({
        value: rest.id,
        text: rest.name,
      }));
    });

    /**
     * City admins can't edit city of an admin, therefore they
     * won't need the list of cities.
     */
    if ([RoleEnum.ADMIN].includes(this.currentUserRoleId)) {
      this.axios.get('/cities').then(({ data }) => {
        this.cities = data.map((city) => ({
          value: city.id,
          text: city.name,
        }));
      });
    }
  }

  onDelete() {
    this.axios
      .delete('/users/' + this.id)
      .then(() => this.$router.push({ path: '/admins' }));
  }

  async onSubmit() {
    const form = {
      ...this.user,
      password: this.password,
    };

    try {
      if (!this.id) {
        await this.axios.post('/users/', form).then((result) => {
          this.$router.push({ path: `/admin/${result.data.id}` });
        });
      } else {
        await this.axios.put('/users/' + this.id, form).then(() =>
          this.$bvToast.toast('Данные обновлены', {
            title: 'Успешно',
            variant: 'success',
          }),
        );
      }
    } catch (error) {
      this.$bvToast.toast(`Ошибка при обновлении данных: ${error?.message}`, {
        title: 'Ошибка',
        variant: 'danger',
      });
    }
  }
}
</script>

<style scoped></style>
