<template>
  <b-container fluid>
    <h4>Топ пользователей</h4>

    <hr />

    <b-form-group v-if="isAdmin" label="Выберите город:">
      <b-form-select v-model="selectedCity" :options="cities">
        Выберите город
      </b-form-select>
    </b-form-group>

    <b-form-group label="Выберите дату, от которой нужно считать заказы:">
      <b-form-datepicker v-model="startDate" @input="onDateSelected" />
    </b-form-group>

    <b-skeleton-wrapper :loading="loading" v-if="users.length">
      <template #loading>
        <b-row>
          <b-col class="mb-3" sm="4" lg="3">
            <b-card>
              <b-skeleton width="85%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
            </b-card>
          </b-col>

          <b-col class="mb-3" sm="4" lg="3">
            <b-card>
              <b-skeleton width="85%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
            </b-card>
          </b-col>

          <b-col class="mb-3" sm="4" lg="3">
            <b-card>
              <b-skeleton width="85%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
            </b-card>
          </b-col>

          <b-col class="mb-3" sm="4" lg="3">
            <b-card>
              <b-skeleton width="85%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
            </b-card>
          </b-col>
        </b-row>
      </template>

      <b-row>
        <b-col
          v-for="user in users"
          :key="user.id"
          sm="4"
          lg="3"
          class="mb-3 user_profile"
        >
          <b-card
            :img-src="user.photo || '/logo.png'"
            img-fluid
            header-bg-variant="white"
            header-border-variant="0"
          >
            <h5>{{ `№${user.index + 1}. ` + user.name }}</h5>

            <b-link
              v-if="user.username"
              :href="'https://t.me/' + user.username"
              target="blank"
            >
              @{{ user.username }}
            </b-link>
            <code v-else> Нет юзернейма </code>

            <template #footer>
              Количество заказов:
              <b-badge>
                {{ user.ordersAmount }}
              </b-badge>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-skeleton-wrapper>
  </b-container>
</template>

<script lang="ts">
import { subDays } from 'date-fns';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { ICity, CitySelectable, RoleEnum, UserWithPhoto } from 'types';

@Component({ name: 'UsersTop' })
export default class UsersTop extends Vue {
  loading = true;
  users: UserWithPhoto[] = [];
  cities: CitySelectable[] = [];
  selectedCity: ICity = null;
  startDate: string = subDays(new Date(), 7).toLocaleString();

  async mounted() {
    if (this.isAdmin) {
      const citiesRequest = await this.axios.get<ICity[]>('/cities');

      this.cities = citiesRequest.data.map((city) => ({
        value: city,
        text: city.name,
      }));
    }

    if (this.isCityAdmin) {
      await this.loadUsers();

      await this.loadImages();
    }
  }

  async loadUsers() {
    this.loading = true;

    const usersRequest = await this.axios.get<UserWithPhoto[]>('/users/top', {
      params: {
        cityId: this.cityId,
        startDate: this.startDate,
      },
    });

    this.users = usersRequest.data.map((user, index) => ({
      ...user,
      index,
    }));

    this.loading = false;
  }

  async loadImages() {
    const usersWithSources: UserWithPhoto[] = [];

    for (const user of this.users) {
      try {
        if (!user.photo) throw new Error();

        const response = await this.axios.get(`/photo/`, {
          responseType: 'blob',
          params: {
            cityId: this.cityId,
            telegramFileId: user.photo,
          },
        });

        const imageSource = URL.createObjectURL(response.data);

        usersWithSources.push({ ...user, photo: imageSource });
      } catch {
        usersWithSources.push(user);
      }
    }

    this.users = usersWithSources;
  }

  @Watch('selectedCity')
  async onCitySelected() {
    await this.loadUsers();

    await this.loadImages();
  }

  async onDateSelected() {
    await this.loadUsers();

    await this.loadImages();
  }

  get isCityAdmin() {
    return this.$store.getters.roleId === RoleEnum.CITY_ADMIN;
  }

  get isAdmin() {
    return this.$store.getters.roleId === RoleEnum.ADMIN;
  }

  get cityId() {
    if (this.isCityAdmin) return this.$store.getters.cityId;
    return this.selectedCity?.id;
  }
}
</script>
