import axios from 'axios';

import router from './router/index';
import store from './store/index';

const storagePrefix = process.env.VUE_APP_STORAGE_PREFIX;

const Axios = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: { 'Content-type': 'application/json' },
});

try {
  const token = localStorage.getItem(`${storagePrefix}-user`);
  if (token) {
    Axios.defaults.headers.common.Authorization = JSON.parse(token).token;
  }
} catch (err) {
  // eslint-disable-next-line no-console
  console.error(err);
}

Axios.interceptors.response.use(
  (response) => {
    const currentAppVersion = process.env.VUE_APP_VERSION;
    const serverAppVersion = response.headers.appversion;

    // eslint-disable-next-line no-console
    console.log(
      'currentAppVersion: ',
      currentAppVersion,
      ', serverAppVersion',
      serverAppVersion,
    );

    if (serverAppVersion !== undefined) {
      if (
        currentAppVersion === null ||
        serverAppVersion !== currentAppVersion
      ) {
        store.commit('versionChanged', serverAppVersion);
      } else {
        store.commit('versionsEqual');
      }
    }

    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      store.commit('logout');
      router.push('/login');
    }

    return error;
  },
);

export default Axios;
