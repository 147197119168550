import _ from 'lodash';
import Vue from 'vue';
import Vuex from 'vuex';
import { RoleEnum } from 'types';

import apolloClient, { createLink } from '../http-graphql';
import axios from '../http-common';

import { ILoginForm } from './interfaces';

Vue.use(Vuex);

const storagePrefix = process.env.VUE_APP_STORAGE_PREFIX;

export default new Vuex.Store({
  strict: true,
  state: {
    status: '',
    user: localStorage.getItem(`${storagePrefix}-user`)
      ? JSON.parse(localStorage.getItem(`${storagePrefix}-user`) || '{}')
      : {},
    canSpam: true,
    spamLists: [],
    showMenuHasBeenMovedTooltip: (localStorage.getItem(
      `${storagePrefix}-tooltip`,
    )
      ? JSON.parse(localStorage.getItem(`${storagePrefix}-tooltip`) || 'true')
      : true) as boolean,
    citiesWithMissingParameters: [],
    missingGlobalSettings: [],
    appVersionIsChanged: false,
    serverVersion: '',
    restaurant: null,
    city: null,
    interfaceLang: localStorage.getItem(`${storagePrefix}-locale`) || 'en',
    debug: process.env.VUE_APP_DEBUG === 'true',
  },
  getters: {
    isLoggedIn: (state) => !!state.user && !!state.user.token,
    authStatus: (state) => state.status,
    roleId: (state) => state.user?.roleId,
    authKey: (state) => state.user?.authKey,
    cityId: (state) => state.user?.cityId,
    restId: (state) => !!state.user && state.user.restaurantId,
    adminName: (state) => state.user?.username,
    interfaceLang: (state) => state.interfaceLang,
    isDebug: (state) => state.debug,
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading';
    },
    auth_success(state, user) {
      state.status = 'success';
      state.user = user;
    },
    auth_error(state) {
      state.status = 'error';
    },
    logout(state) {
      state.status = '';
      state.user = {};
    },
    setSpamStatus(state, status) {
      state.canSpam = status;
    },
    setSpamLists(state, lists) {
      state.spamLists = lists;
    },
    turnOffMenuTooltip(state) {
      state.showMenuHasBeenMovedTooltip = false;
    },
    addMissingParametersToCity(state, { cityName, missingKeys }) {
      state.citiesWithMissingParameters.push([cityName, missingKeys]);
    },
    clearListOfMissingCityParameters(state) {
      state.citiesWithMissingParameters.length = 0;
    },
    addMissingGlobalSetting(state, message) {
      state.missingGlobalSettings.push(message);
    },
    clearMissingGlobalSettings(state) {
      state.missingGlobalSettings.length = 0;
    },
    versionChanged(state, serverVersion) {
      state.appVersionIsChanged = true;
      state.serverVersion = serverVersion;
    },
    versionsEqual(state) {
      state.appVersionIsChanged = false;
    },
    setRestaurant(state, restaurant) {
      state.restaurant = restaurant;
    },
    setCity(state, city) {
      state.city = city;
    },
  },
  actions: {
    loadSavedToken() {
      try {
        const userRaw = localStorage.getItem(`${storagePrefix}-user`);
        if (userRaw) {
          const user = JSON.parse(userRaw);
          axios.defaults.headers.common.Authorization = user.token;
        }
        const token = localStorage.getItem(`${storagePrefix}-token`);
        apolloClient.setLink(createLink(token));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    },
    disableMenuTooltip({ commit }) {
      localStorage.setItem(`${storagePrefix}-tooltip`, 'false');

      commit('turnOffMenuTooltip');
    },
    async login({ commit, dispatch }, user: ILoginForm) {
      commit('auth_request');

      try {
        // Sends login request

        const {
          data: { user: userLoggedIn, jwtToken },
        } = await axios.post(
          `${process.env.VUE_APP_API_URL.replace('/api', '')}/login`,
          user,
          { auth: user },
        );

        // Saves user in the local storage

        localStorage.setItem(
          `${storagePrefix}-user`,
          JSON.stringify(userLoggedIn),
        );
        localStorage.setItem(`${storagePrefix}-token`, jwtToken);

        // Sets authorization token for future requests (and for graphql)

        axios.defaults.headers.common.Authorization = userLoggedIn.token;
        apolloClient.setLink(createLink(jwtToken));

        // Notifies Vue about successful authorization

        commit('auth_success', userLoggedIn);

        // Gets additional details for admins depending on their roles

        dispatch('updateDetails');

        return userLoggedIn;
      } catch (e) {
        commit('auth_error');

        localStorage.removeItem(`${storagePrefix}-user`);
        localStorage.removeItem(`${storagePrefix}-token`);

        throw e;
      }
    },
    async logout({ commit }) {
      commit('logout');

      localStorage.removeItem(`${storagePrefix}-user`);
      localStorage.removeItem(`${storagePrefix}-token`);
      delete axios.defaults.headers.common.Authorization;
      apolloClient.setLink(createLink(''));

      return true;
    },
    getSpamStatus({ commit }) {
      return axios
        .get('spam')
        .then(({ data }) => {
          commit('setSpamLists', data);
        })
        .catch((err) => {
          // eslint-disable-next-line
                    console.error(err);
          commit('setSpamLists', -1);
        });
    },
    async getMissingCityConfigParameters({ commit }) {
      const response = await axios.get('/superadmin/checkCitiesConfigs');
      if (!response.data) return;

      _.map(response.data, (parameters, key) => {
        const parametersFormatted = _.chain(parameters)
          .map((key) => `"${key}"`)
          .join(', ')
          .value();

        commit('addMissingParametersToCity', {
          cityName: key,
          missingKeys: parametersFormatted,
        });
      });

      return response.data;
    },
    async getMissingGlobalSettings({ commit }) {
      const response = await axios.get<string[]>(
        '/superadmin/checkGlobalSettings',
      );
      if (!response.data) return;

      _.map(response.data, (message) => {
        commit('addMissingGlobalSetting', message);
      });

      return response.data;
    },
    /**
     * Gets additional details for admins depending on their roles
     */
    async updateDetails({ commit, state, getters }) {
      const roleId = state.user.roleId as RoleEnum;

      if (roleId === RoleEnum.CITY_ADMIN || roleId === RoleEnum.OPERATOR) {
        const { data: city } = await axios.get(`/cities/${getters.cityId}`);

        commit('setCity', city);
      }

      if (roleId === RoleEnum.RESTAURANT_OWNER) {
        const { data: restaurant } = await axios.get(
          `/restaurants/${getters.restId}`,
        );

        commit('setRestaurant', restaurant);
      }
    },
  },
});
