<template>
  <b-container fluid>
    <h1>{{ $t('dishCategory.add') }}</h1>

    <hr />

    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col>
          <b-form-group
            :label="$t('dishCategory.name')"
            :state="nameValidation"
            :invalid-feedback="$t('validation.mustBeLessThan', { amount: 256 })"
          >
            <b-form-input
              id="input-1"
              type="text"
              v-model="form.name"
              :placeholder="$t('dishCategory.name_placeholder')"
              required
            />
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
            :label="$t('dishCategory.name_ru')"
            :state="nameRuValidation"
            :invalid-feedback="$t('validation.mustBeLessThan', { amount: 256 })"
          >
            <b-form-input
              id="input-1"
              type="text"
              v-model="form.name_ru"
              :placeholder="$t('dishCategory.name_ru_placeholder')"
              required
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group :label="$t('dishCategory.position')">
        <b-form-input
          id="input-2"
          type="number"
          v-model="form.position"
          placeholder="1"
          required
        />
      </b-form-group>

      <b-form-checkbox id="checkbox-1" v-model="form.enabled" name="checkbox-1">
        {{ $t('dishCategory.enabled') }}
      </b-form-checkbox>

      <b-row class="mt-3">
        <b-col md="6">
          <b-form-group :label="$t('dishCategory.startTime')">
            <b-form-timepicker v-model="form.startDisplayingAt" locale="ru" />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group :label="$t('dishCategory.endTime')">
            <b-form-timepicker v-model="form.stopDisplayingAt" locale="ru" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-button
        :disabled="!allValidationHasBeenPassed"
        type="submit"
        variant="primary"
      >
        {{ $t('dishCategory.add') }}
      </b-button>
    </b-form>
  </b-container>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

const storagePrefix = process.env.VUE_APP_STORAGE_PREFIX;

@Component({ name: 'DishCategoryAdd' })
export default class DishCategoryAdd extends Vue {
  form = {
    name: '',
    name_ru: '',
    position: '',
    startDisplayingAt: '',
    stopDisplayingAt: '',
    restaurantId: null,
  };

  async onSubmit() {
    const form = this.form;

    form.restaurantId = localStorage.getItem(`${storagePrefix}-restaurantId`);

    // Removing seconds from time

    if (form.startDisplayingAt) {
      form.startDisplayingAt = form.startDisplayingAt.slice(0, 5);
    }

    if (form.stopDisplayingAt) {
      form.stopDisplayingAt = form.stopDisplayingAt.slice(0, 5);
    }

    // Sending the request

    await this.axios.post('/dishCategories/', form);

    await this.$router.push({ path: '/dishCategories' });
  }

  /** Here goes validation */

  get nameValidation() {
    if (!this.form?.name) return null;

    return this.form.name.length < 256;
  }

  get nameRuValidation() {
    if (!this.form?.name_ru) return null;

    return this.form.name_ru.length < 256;
  }

  get allValidationHasBeenPassed() {
    return this.nameValidation !== false && this.nameRuValidation !== false;
  }
}
</script>

<style scoped></style>
