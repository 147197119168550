<template>
  <b-container>
    <h4 v-if="tagId">{{ tag.name }}</h4>
    <h4 v-else>{{ $t('tags.newTag') }}</h4>

    <hr />

    <b-form @submit.prevent="onSubmit">
      <b-form-group v-if="isAdmin && !tagId" :label="$t('city.choose')">
        <b-form-select :options="cities" v-model="city" required>
          Please select a city
        </b-form-select>
      </b-form-group>

      <b-row>
        <b-col>
          <b-form-group :label="$t('tags.name')">
            <b-form-input id="input-1" type="text" v-model="tag.name" />
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group :label="$t('tags.name_ru')">
            <b-form-input id="input-2" type="text" v-model="tag.name_ru" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-button variant="primary" type="submit" v-if="tagId">
        {{ $t('tags.update') }}
      </b-button>
      <b-button variant="success" type="submit" v-else>
        {{ $t('tags.create') }}
      </b-button>
    </b-form>
  </b-container>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { RoleEnum, ICity, ITag } from 'types';

import { PreparedCity } from './Tags.vue';

@Component({ name: 'Tag' })
export default class Tag extends Vue {
  tagId: ITag['id'] = null;
  tag: ITag | Omit<ITag, 'id'> = {
    name: '',
    name_ru: '',
    cityId: '',
  };

  city: ICity = null;
  cities: PreparedCity[] = [];

  async mounted() {
    const tagId = this.$route.params.id;

    if (tagId) {
      this.tagId = tagId;

      const tagResponse = await this.axios.get<ITag>('/tags/' + tagId);
      this.tag = tagResponse.data;
    }

    if (this.isAdmin) {
      const citiesResponse = await this.axios.get<ICity[]>('/cities');
      this.cities = citiesResponse.data?.map((city) => ({
        text: city.name,
        value: city,
      }));
    }
  }

  async onSubmit() {
    const body = {
      ...this.tag,
      cityId: this.isCityAdmin ? this.cityId : this.city?.id,
    };

    if (this.tagId) {
      await this.axios.put('/tags/' + this.tagId, body);
    } else {
      await this.axios.post('/tags', body);
    }

    await this.$router.push('/tags');
  }

  // Getters from the store

  get cityId() {
    return this.$store.getters.cityId;
  }

  get isAdmin() {
    return this.$store.getters.roleId === RoleEnum.ADMIN;
  }

  get isCityAdmin() {
    return this.$store.getters.roleId === RoleEnum.CITY_ADMIN;
  }
}
</script>
