<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <h1>{{ form.name }}</h1>
      </b-col>

      <b-col cols="12" md="auto">
        <b-button
          squared
          v-on:click.prevent="onDelete"
          variant="outline-danger"
          pill
        >
          - Удалить кухню
        </b-button>
      </b-col>
    </b-row>

    <b-row class="mb-3">
      <b-col>
        <h4 v-if="hasCity">
          Город:
          <b-badge class="ml-1" variant="primary">
            {{ form.city.name }}
          </b-badge>
        </h4>

        <h4 v-else>Кухня не привязана к городу!</h4>
      </b-col>
    </b-row>

    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col>
          <label for="input-2"> Название кухни на английском: </label>
          <b-form-input
            id="input-2"
            placeholder="Название кухни (en)"
            type="text"
            v-model="form.name"
          />
        </b-col>

        <b-col>
          <label for="input-1"> Название кухни: </label>
          <b-form-input
            id="input-1"
            placeholder="Название кухни (ru)"
            type="text"
            v-model="form.name_ru"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col md="12">
          <label for="input-3" class="mt-3"> Позиция в списке: </label>
        </b-col>

        <b-col>
          <b-input-group>
            <b-form-input
              id="input-3"
              placeholder="12"
              type="number"
              v-model="form.position"
            />
            <b-input-group-append is-text>
              <b-form-checkbox
                id="checkbox-1"
                v-model="form.enabled"
                name="checkbox-1"
              >
                {{ $t('cuisines.enabled') }}
              </b-form-checkbox>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col md="12">
          <b-button
            squared
            type="submit"
            variant="outline-primary"
            :disabled="!loaded"
          >
            Обновить данные
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RoleEnum, ICity, ICuisine } from 'types';

type CityListWrapped = { value: ICity['id']; text: ICity['name'] };

@Component({ name: 'Cuisine' })
export default class Cuisine extends Vue {
  @Prop() private id!: string;

  form: ICuisine = {
    id: this.id,
    name: null,
    name_ru: null,
    position: null,
    enabled: true,
    cityId: null,
    city: null,
  };

  loaded = false;
  cities: CityListWrapped[] = [];

  get isAdmin() {
    return this.$store.getters.roleId === RoleEnum.ADMIN;
  }

  get hasCity() {
    return this.form.city;
  }

  async mounted() {
    const { data: form } = await this.axios.get<ICuisine>(
      '/cuisines/' + this.id,
    );

    this.form = form;
    this.loaded = true;
  }

  public onDelete() {
    this.axios
      .delete('/cuisines/' + this.id)
      .then(() => this.$router.push({ path: '/cuisines' }));
  }

  public onSubmit() {
    this.axios
      .put('/cuisines/' + this.id, this.form)
      .then(() => this.$router.push({ path: '/cuisines' }));
  }
}
</script>
