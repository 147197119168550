<template>
  <b-dropdown
    size="lg"
    variant="link"
    toggle-class="text-decoration-none"
    no-caret
  >
    <template #button-content>
      <b-button variant="link">
        <b-icon-pencil-square class="d-none d-lg-inline mr-1" />
        {{ $t('orders.changeStatus') }}
      </b-button>
    </template>

    <b-dropdown-item
      v-for="status of preparedStatuses"
      :key="status.text"
      :disabled="status.disabled"
      @click="onStatusEdited(status.key)"
      class="border-bottom"
    >
      <strong>{{ status.text }}</strong>

      <div>
        <small>{{ status.help }}</small>
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EOrderStatus } from 'types';

import { PreparedOrder } from './Orders.vue';

type PreparedStatus = {
  text: string;
  disabled: boolean;
  help: string;
  key: EOrderStatus;
};

@Component({ name: 'OrderStatus' })
export default class OrderStatus extends Vue {
  @Prop()
  order: PreparedOrder;

  get preparedStatuses(): PreparedStatus[] {
    return Object.keys(EOrderStatus)
      .filter((k) => typeof EOrderStatus[k] !== 'string')
      .map((status) => {
        const key = EOrderStatus[status] as EOrderStatus;
        return {
          text: this.$t('orders.statuses.' + status) as string,
          /**
           * Status will be disabled in the dropdown if the order
           * already has this status
           */
          disabled:
            !this.$store.getters.isDebug &&
            ((key >= 0 && this.order.status >= key) || this.order.status < 0),
          help: this.$t('orders.statusesHelp.' + status) as string,
          key,
        };
      });
  }

  async onStatusEdited(key: EOrderStatus) {
    this.$emit('statusChanged', {
      orderId: this.order.id,
      status: key,
    });
  }
}
</script>
