<template>
  <div v-if="cities">
    <div class="row mb-3">
      <div class="col">
        <h1>{{ $t('city.all') }}</h1>
        <b-button squared to="/city/" variant="outline-success">{{
          $t('city.add')
        }}</b-button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-table :fields="fields" :items="cities" hover striped>
          <template v-slot:cell(name)="data">
            <b-link :to="'/city/' + data.item.id">{{ data.item.name }}</b-link>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ICity } from 'types';

@Component({
  name: 'Cities',
})
export default class Cities extends Vue {
  private cities: ICity[] = [];
  private fields = [
    {
      key: 'name',
      label: this.$t('city.name'),
      sortable: true,
    },
    {
      key: 'chatname',
      label: this.$t('city.chatname'),
      sortable: true,
    },
    {
      key: 'currency',
      label: this.$t('city.currency'),
      sortable: true,
    },
  ];

  async mounted() {
    const response = await this.axios.get('/cities');
    // if (response.status === 200)
    this.cities = response.data;
  }
}
</script>
