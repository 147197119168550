<template>
  <b-container v-if="isAdmin || isCityAdmin">
    <b-row class="mb-3">
      <b-col class="d-flex justify-content-between">
        <h2 class="m-0">{{ city.name }}</h2>
        <b-button
          v-if="id"
          squared
          :to="`/city/${id}/districts`"
          variant="outline-info"
        >
          {{ $t('city.regions') }}
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <div class="col-12">
        <h3>Base settings</h3>

        <b-row class="mb-3">
          <b-col>
            <label for="input-title">{{ $t('city.name_ru') }}</label>
            <b-form-input
              id="input-title"
              :placeholder="$t('city.name_ru')"
              type="text"
              v-model="city.name_ru"
            />
          </b-col>

          <b-col>
            <label for="input-title">
              {{ $t('city.name') }}
            </label>
            <b-form-input
              id="input-title"
              :placeholder="$t('city.name')"
              type="text"
              v-model="city.name"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <label for="input-chatname">
              {{ $t('city.chatname') }}
            </label>
            <b-form-input
              id="input-chatname"
              :placeholder="$t('city.chatname')"
              type="text"
              v-model="city.chatname"
            />
          </b-col>

          <b-col>
            <label for="input-currency">
              {{ $t('city.currency') }}
            </label>
            <b-form-input
              id="input-currency"
              :placeholder="$t('city.currency')"
              type="text"
              v-model="city.currency"
            />
          </b-col>

          <b-col>
            <label for="input-currency">
              {{ $t('city.locales') }}
            </label>
            <b-form-input
              id="input-currency"
              placeholder="en"
              type="text"
              v-model="config.cityLocales"
            />
          </b-col>
          <b-col>
            <b-form-checkbox v-model="config.hideMoney">
              {{ $t('city.hideMoney') }}
            </b-form-checkbox>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-checkbox v-model="city.active">
              {{ $t('city.active') }}
            </b-form-checkbox>
            <label
              for="input-20"
              :class="muteInfoButtonSettings ? 'text-muted' : ''"
            >
              {{ $t('city.cityDisabledReason') }}
              <em>(HTML tags are supported)</em>
            </label>
            <b-form-textarea
              v-model="config.cityDisabledReason"
              :disabled="city.active"
            />
          </b-col>
        </b-row>

        <hr />

        <div v-if="id">
          <h3 class="mt-3">Config</h3>

          <b-row class="mb-3">
            <b-col class="d-flex">
              <b-form-checkbox v-model="config.showOpenNow" class="mr-3">
                {{ $t('config.showOpenNow') }}
              </b-form-checkbox>

              <b-form-checkbox v-model="config.showTopTen" class="mr-3">
                {{ $t('config.showTopTen') }}
              </b-form-checkbox>

              <b-form-checkbox class="mr-3" v-model="config.showInfoButton">
                {{ $t('config.showInfoButton') }}
              </b-form-checkbox>

              <b-form-checkbox class="mr-3" v-model="config.showTagsButton">
                {{ $t('config.showTagsButton') }}
              </b-form-checkbox>

              <b-form-checkbox v-model="config.deliveryCostJump">
                {{ $t('config.deliveryCostJump') }}
              </b-form-checkbox>

              <b-form-checkbox v-model="config.annoyRestaurants">
                {{ $t('config.annoyRestaurants') }}
              </b-form-checkbox>
            </b-col>
          </b-row>

          <div v-if="isAdmin" class="mb-3">
            <h4>Telegram Payments</h4>

            <b-row class="mb-3">
              <b-col>
                <b-form-group label="Telegram Payments method">
                  <b-form-radio-group
                    class="mb-3 mt-3"
                    v-model="config.telegramPaymentsStatus"
                    :options="[
                      { text: 'Cash only', value: 0 },
                      { text: 'Cashless only', value: 1 },
                      { text: 'Both', value: 2 },
                    ]"
                  >
                  </b-form-radio-group>
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-checkbox v-model="config.roundUpDeliveryCost">
                  Round up delivery cost
                </b-form-checkbox>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col>
                <b-form-group label="Provider token">
                  <b-input
                    id="input-200"
                    placeholder="12345678:TEST:MWJkZGVlMTZiOTRh"
                    v-model="config.providerToken"
                  />
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group label="Exchange rate (1 dollar to the currency)">
                  <b-input
                    id="input-201"
                    placeholder="60"
                    v-model="config.exchangeRate"
                    type="number"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="mb-3" align-v="center">
              <b-col>
                <b-form-group label="Service fee">
                  <b-input
                    id="input-202"
                    placeholder="0.035"
                    v-model="config.serviceFeeMultiplier"
                    type="number"
                  />
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group label="VAT">
                  <b-input
                    id="input-202"
                    placeholder="0.21"
                    v-model="config.cityVat"
                    type="number"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>

          <h4 :class="muteDeliveryJumpSettings ? 'text-muted' : ''">
            {{ $t('city.deliveryCostJump') }}
          </h4>

          <b-row class="mb-3">
            <b-col>
              <label :class="muteDeliveryJumpSettings ? 'text-muted' : ''">
                {{ $t('city.deliveryCostJumpStart') }}
              </label>
              <b-form-timepicker
                :disabled="muteDeliveryJumpSettings"
                v-model="config.deliveryCostJumpStart"
                locale="ru"
              />
            </b-col>

            <b-col>
              <label :class="muteDeliveryJumpSettings ? 'text-muted' : ''">
                When should the price jump end?
              </label>
              <b-form-timepicker
                :disabled="muteDeliveryJumpSettings"
                v-model="config.deliveryCostJumpEnd"
                locale="ru"
              />
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col>
              <label :class="muteDeliveryJumpSettings ? 'text-muted' : ''">
                How much to raise the delivery cost?
                <em>(without currency name)</em>
              </label>
              <b-input
                placeholder="1500"
                :disabled="muteDeliveryJumpSettings"
                v-model="config.deliveryCostJumpAmount"
                type="number"
              />
            </b-col>
          </b-row>

          <h4 :class="muteTopSettings ? 'text-muted' : ''">Top ten config</h4>

          <b-row class="mb-3">
            <b-col>
              <label
                for="input-20"
                :class="muteTopSettings ? 'text-muted' : ''"
              >
                Time interval for collecting orders needed to build top 10
                dishes
                <em>(in days)</em>
              </label>
              <b-input
                id="input-20"
                placeholder="7"
                :disabled="muteTopSettings"
                v-model="config.topInterval"
                type="number"
              />
            </b-col>
          </b-row>

          <h4 :class="muteInfoButtonSettings ? 'text-muted' : ''">
            Info button settings
          </h4>

          <b-row class="mb-3">
            <b-col>
              <label
                for="input-20"
                :class="muteInfoButtonSettings ? 'text-muted' : ''"
              >
                Info button text in English
              </label>
              <b-input
                id="input-20"
                placeholder="Support"
                :disabled="muteInfoButtonSettings"
                v-model="config.infoButtonTextEn"
              />
            </b-col>

            <b-col>
              <label
                for="input-20"
                :class="muteInfoButtonSettings ? 'text-muted' : ''"
              >
                Info button text in russian
              </label>
              <b-input
                id="input-20"
                placeholder="Поддержка"
                :disabled="muteInfoButtonSettings"
                v-model="config.infoButtonTextRu"
              />
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col md="6">
              <label
                for="input-20"
                :class="muteInfoButtonSettings ? 'text-muted' : ''"
              >
                Info content in English
                <em>(HTML tags are supported)</em>
              </label>
              <b-form-textarea
                id="input-20"
                placeholder="Write here if you have any problem using the bot: @username"
                :disabled="muteInfoButtonSettings"
                v-model="config.infoButtonContentEn"
              />
            </b-col>

            <b-col md="6">
              <label
                for="input-20"
                :class="muteInfoButtonSettings ? 'text-muted' : ''"
              >
                Info content in russian
                <em>(HTML tags are supported)</em>
              </label>
              <b-form-textarea
                id="input-20"
                placeholder="Пишите сюда если шо: @username"
                :disabled="muteInfoButtonSettings"
                v-model="config.infoButtonContentRu"
              />
            </b-col>
          </b-row>

          <h4>City settings</h4>

          <b-row class="mb-3">
            <b-col>
              <label for="input-12"> Delivery multiplier </label>
              <b-input
                id="input-12"
                placeholder="1.75"
                v-model="config.deliveryMultiplier"
                type="number"
              />
            </b-col>

            <b-col>
              <label for="input-4">
                Timezone shift from GMT
                <em>(in hours, without minutes)</em>
              </label>
              <b-input
                id="input-4"
                placeholder="in hours"
                v-model="config.timeShift"
                type="number"
              />
            </b-col>
          </b-row>

          <photo-uploader
            v-if="city.id"
            title="URL of the default city image"
            :cityId="city.id"
            :telegramFileId="config.defaultImageId"
            @uploaded="onUploaded"
            @uploading="onUploading"
          />

          <h4>Channels and groups of the city</h4>

          <b-row class="mb-3">
            <b-col md-6>
              <label for="input-4">
                Telegram ID of <em>a notication channel</em>
              </label>
              <b-input
                id="input-4"
                placeholder="-10093848492"
                v-model="config.notifyChannelId"
              />
            </b-col>

            <b-col class="mb-3">
              <label for="input-5">
                Telegram ID of <em>a review channel</em>
              </label>
              <b-input
                id="input-5"
                placeholder="-100832493492"
                v-model="config.reviewChannelId"
                type="number"
              />
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col class="mb-3">
              <label for="input-11">
                How long should the ads stay in chat for restaurant owners?
                <em>(in hours)</em>
              </label>
              <b-input
                id="input-11"
                placeholder="10"
                v-model="config.adChatGap"
                type="number"
              />
            </b-col>

            <b-col md="6">
              <label for="input-10">
                Delay in publishing ads for restaurant owners
                <em>(in hours)</em>
              </label>
              <b-input
                id="input-10"
                placeholder="10"
                v-model="config.adChatDelay"
                type="number"
              />
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col>
              <label for="input-5">
                Telegram ID of
                <em>a chat where the ads will be published</em>
              </label>
              <b-input
                id="input-5"
                placeholder="-100832493492"
                v-model="config.adChatId"
                type="number"
              />
            </b-col>
          </b-row>
        </div>

        <label for="input-1">AuthKey:</label>
        <h5>{{ authKey }}</h5>

        <b-button
          v-if="id"
          squared
          type="submit"
          variant="outline-primary"
          :disabled="!isCityLoaded || isPhotoLoading"
          v-on:click.prevent="onSave"
          class="mb-3"
        >
          {{ $t('city.save') }}
        </b-button>
        <b-button
          v-else
          squared
          type="submit"
          variant="outline-primary"
          v-on:click.prevent="onAdd"
          class="mb-3"
        >
          {{ $t('city.add') }}
        </b-button>
      </div>
    </b-row>
  </b-container>

  <b-container v-else>
    <h1>{{ city.name }}</h1>

    <hr />

    <label for="input-12"> Delivery multiplier </label>
    <b-input
      id="input-12"
      placeholder="1.75"
      v-model="config.deliveryMultiplier"
      type="number"
    />

    <h4 :class="'mt-3 ' + (muteDeliveryJumpSettings ? 'text-muted' : '')">
      Delivery cost jump settings
    </h4>

    <b-row class="mb-3">
      <b-col>
        <label :class="muteDeliveryJumpSettings ? 'text-muted' : ''">
          When should the price jump start?
        </label>
        <b-form-timepicker
          :disabled="muteDeliveryJumpSettings"
          v-model="config.deliveryCostJumpStart"
          locale="ru"
        />
      </b-col>

      <b-col>
        <label :class="muteDeliveryJumpSettings ? 'text-muted' : ''">
          When should the price jump end?
        </label>
        <b-form-timepicker
          :disabled="muteDeliveryJumpSettings"
          v-model="config.deliveryCostJumpEnd"
          locale="ru"
        />
      </b-col>
    </b-row>

    <b-row class="mb-3">
      <b-col>
        <label :class="muteDeliveryJumpSettings ? 'text-muted' : ''">
          How much to raise the delivery cost?
          <em>(without currency name)</em>
        </label>
        <b-input
          placeholder="1500"
          :disabled="muteDeliveryJumpSettings"
          v-model="config.deliveryCostJumpAmount"
          type="number"
        />
      </b-col>
    </b-row>

    <b-button
      type="submit"
      variant="primary"
      :disabled="!isCityLoaded"
      v-on:click.prevent="onSave"
      class="mt-3"
    >
      {{ $t('city.save') }}
    </b-button>
  </b-container>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RoleEnum, ICity } from 'types';

import PhotoUploader from './PhotoUploader.vue';

type IConfig = { [key: string]: string | number | boolean };

@Component({
  name: 'City',
  components: { 'photo-uploader': PhotoUploader },
})
export default class City extends Vue {
  @Prop() id!: number;

  // Config params

  config: IConfig = {
    notifyChannelId: null,
    reviewChannelId: null,
    adChatId: null,
    adChatDelay: null,
    adChatGap: null,
    defaultImageId: null,
    showOpenNow: false,
    showTopTen: false,
    showInfoButton: false,
    infoButtonText: null,
    infoButtonTextEn: null,
    infoButtonContent: null,
    infoButtonContentEn: null,
    timeShift: null,
    deliveryMultiplier: null,
    topInterval: null,
    deliveryCostJump: null,
    deliveryCostJumpStart: null,
    deliveryCostJumpEnd: null,
    deliveryCostJumpAmount: null,
  };

  // Needed for form

  isCityLoaded = false;
  isPhotoLoading = false;
  city: ICity = {
    id: null,
    name: '',
    name_ru: '',
    chatname: '',
    currency: '',
  };

  get isAdmin() {
    return this.$store.getters.roleId === RoleEnum.ADMIN;
  }

  get isCityAdmin() {
    return this.$store.getters.roleId === RoleEnum.CITY_ADMIN;
  }

  get authKey() {
    return this.$store.getters.authKey;
  }

  get isOperator() {
    return this.$store.getters.roleId === RoleEnum.OPERATOR;
  }

  get muteTopSettings() {
    return !this.config.showTopTen || this.config.showTopTen === 'false';
  }

  get muteInfoButtonSettings() {
    return (
      !this.config.showInfoButton || this.config.showInfoButton === 'false'
    );
  }

  get muteDeliveryJumpSettings() {
    return (
      !this.config.deliveryCostJump || this.config.deliveryCostJump === 'false'
    );
  }

  async mounted() {
    /*
     * Since a single component is responsible for creating a new model and
     * updating it, there's a logic to separate these two keys.
     *
     * If user's editting the city, it will request city details, otherwise
     * it will stop the further code from here.
     */

    if (!this.id) return;

    // Gets city details

    const { data: city } = await this.axios.get(`/cities/${this.id}`);
    this.city = city;
    this.isCityLoaded = true;

    // Gets config rows of this city

    const { data: configRows } = await this.axios.get<IConfig[]>(
      `/config/${this.id}`,
    );

    // Converts list of rows into an object

    configRows.map(({ key, value }) => (this.config[key as string] = value));
  }

  onUploading() {
    this.isPhotoLoading = true;
  }

  onUploaded(telegramFileId: string) {
    this.isPhotoLoading = false;
    this.config.defaultImageId = telegramFileId;
  }

  async onSave() {
    await this.axios.put(`/cities/${this.id}`, this.city);

    // We'll send only those rows that were changed

    const changedRowsOfConfig = Object.fromEntries(
      Object.entries(this.config).map(([key, value]) => [key, value ?? '']),
    );

    this.axios.put(`/config/${this.id}`, changedRowsOfConfig);

    if (this.isAdmin) this.$router.push('/cities');
    if (this.isCityAdmin || this.isOperator) this.$router.push('/');
  }

  async onAdd() {
    await this.axios.post('/cities/', this.city);

    if (this.isAdmin) this.$router.push('/cities');
    if (this.isCityAdmin) this.$router.push('/');
  }
}
</script>
