<template>
  <div>
    <div class="row mb-3">
      <div class="col">
        <h1>{{ $t('region.one') }}</h1>
        <h2>{{ district.name }}</h2>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <label for="input-name">{{ $t('region.name') }}</label>
        <b-form-input
          id="input-name"
          :placeholder="$t('region.name')"
          type="text"
          v-model="district.name"
        ></b-form-input>
        <label for="input-name_ru">{{ $t('region.name_ru') }}</label>
        <b-form-input
          id="input-name_ru"
          :placeholder="$t('region.name_ru')"
          type="text"
          v-model="district.name_ru"
        ></b-form-input>

        <b-button
          squared
          v-if="districtId"
          type="submit"
          variant="outline-primary"
          v-on:click.prevent="onSave"
        >
          {{ $t('region.save') }}
        </b-button>

        <b-button
          v-if="districtId"
          squared
          type="submit"
          variant="outline-danger"
          v-on:click.prevent="onDelete"
        >
          {{ $t('region.delete') }}
        </b-button>

        <b-button
          v-else
          squared
          type="submit"
          variant="outline-primary"
          :disabled="!loading"
          v-on:click.prevent="onAdd"
        >
          {{ $t('region.add') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { IDistrict } from 'types';

@Component({
  name: 'District',
})
export default class District extends Vue {
  district: IDistrict = {
    id: '',
    name: '',
    name_ru: '',
    cityId: '',
  };

  loading = true;

  get cityId(): string {
    return this.$route.params.cityId;
  }

  get districtId(): string {
    return this.$route.params.id;
  }

  async mounted() {
    if (this.districtId) {
      const response = await this.axios.get(`/districts/${this.districtId}`);

      this.district = response.data;
      this.loading = false;
    }
  }

  async onSave() {
    await this.axios.put(`/districts/${this.districtId}`, this.district);

    this.$router.push(`/city/${this.cityId}/districts`);
  }

  async onAdd() {
    await this.axios.post('/districts/', {
      cityId: this.cityId,
      name: this.district.name,
      name_ru: this.district.name_ru,
    });

    this.$router.push(`/city/${this.cityId}/districts`);
  }

  async onDelete() {
    await this.axios.delete(`/districts/${this.districtId}`);

    this.$router.push(`/city/${this.cityId}/districts`);
  }
}
</script>
