<template>
  <div class="column mb-3">
    <div class="row d-flex justify-content-between">
      <h2>
        <span>{{ $t('bots.add') }}</span>
      </h2>
    </div>

    <div class="row">
      <div class="col-md-12">
        <b-form @submit.prevent="onSubmit">
          <label for="input-2">Bot token</label>
          <b-form-input id="input-2" v-model="bot.botToken"></b-form-input>

          <label for="input-3">City</label>
          <b-form-select
            id="input-3"
            v-model="bot.city"
            :options="cities"
            class="mb-3"
          ></b-form-select>

          <b-button type="submit" variant="outline-primary">
            Create new bot
          </b-button>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { IBot, ICity } from 'types';

@Component({ name: 'Bots' })
export default class BotsAdd extends Vue {
  cities: ICity[] = [];
  bot: IBot & { city: Partial<ICity>; id?: string } = {
    botToken: '',
    endpoint: '',
    cityId: '',
    city: { name: '', name_ru: '' },
  };

  mounted() {
    this.axios.get('/cities/for_bots').then(({ data }) => {
      this.cities = data.map((city) => ({
        value: city,
        text: city.name,
      }));
    });
  }

  onSubmit() {
    // Removing unnecessary fields from the form

    const { id, city, ...formData } = this.bot;

    // Updates the city_id (by default only bot.city value changes)

    formData.cityId = city.id;

    // Sends the request and returns the admin back to the bot menu

    this.axios
      .post('/bots', formData)
      .then(() => this.$router.push({ path: '/bots' }));
  }
}
</script>
