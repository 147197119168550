<template>
  <b-container fluid id="app" class="mw-100">
    <header class="app__header bg-primary shadow">
      <b-button
        variant="link"
        class="text-white"
        @click="sidebarStatus = !sidebarStatus"
        id="openSidebarButton"
        v-if="isSmallViewport"
      >
        <b-icon icon="justify" />
      </b-button>

      <b-tooltip
        :show.sync="showMenuMovedTooltip"
        target="openSidebarButton"
        triggers="manual"
        v-if="isSmallViewport"
      >
        <strong class="text-white">
          {{ $t('home.menuMovedTitle') }}
        </strong>
        <span class="d-block">
          {{ $t('home.menuMovedText') }}
        </span>
        <b-form-checkbox
          class="mt-2"
          @input="$store.dispatch('disableMenuTooltip')"
        >
          {{ $t('home.dontShowAgain') }}
        </b-form-checkbox>
      </b-tooltip>

      <b-link
        v-else
        to="/"
        class="text-decoration-none text-white d-flex align-items-center"
      >
        <b-avatar variant="primary" class="ml-3">
          <img src="/logo.png" alt="logotype" width="50rem" height="50rem" />
        </b-avatar>
        <h4 class="p-2 text-center m-0 d-inline-block">
          {{ title }}
        </h4>
      </b-link>
    </header>

    <b-row class="app__body">
      <b-col
        sm="3"
        lg="2"
        class="app__sidebar bg-light p-0"
        v-if="!isSmallViewport"
      >
        <nav class="nav flex-column pl-3">
          <div v-if="isLoggedIn">
            <b-link
              class="nav-link"
              v-for="section in allowedSections"
              :key="section.to"
              :to="section.to"
            >
              {{ section.text }}
            </b-link>
          </div>

          <!-- if not logged in -->

          <div v-else>
            <b-link class="nav-link" to="/login">
              <i class="fas fa-door-open"></i>
              {{ $t('menu.login') }}
            </b-link>
          </div>
        </nav>

        <small class="text-secondary text-center version">
          {{ appVersion }}
        </small>
      </b-col>

      <b-col sm="9" lg="10">
        <router-view class="p-3" />
      </b-col>
    </b-row>

    <!-- this is the sidebar with sections -->

    <b-sidebar
      backdrop-variant="dark"
      backdrop
      shadow
      v-model="sidebarStatus"
      bg-variant="light"
      no-header
      id="sidebar"
    >
      <!-- logotype -->

      <header class="bg-dark shadow w-100">
        <b-link
          to="/"
          class="text-decoration-none text-white d-flex align-items-center justify-content-center"
        >
          <b-avatar variant="dark" class="ml-3">
            <img src="/logo.png" alt="logotype" width="50rem" height="50rem" />
          </b-avatar>
          <h4 class="p-2 text-center m-0 d-inline-block">
            {{ title }}
          </h4>
        </b-link>
      </header>

      <nav class="nav flex-column pl-3">
        <div v-if="isLoggedIn">
          <b-link
            class="nav-link"
            v-for="section in allowedSections"
            :to="section.to"
            :key="section.to"
          >
            {{ section.text }}
          </b-link>
        </div>

        <!-- if not logged in -->

        <div v-else>
          <b-link class="nav-link" to="/login">
            <i class="fas fa-door-open"></i>
            {{ $t('menu.login') }}
          </b-link>
        </div>
      </nav>

      <small class="text-secondary text-center version">
        {{ appVersion }}
      </small>
    </b-sidebar>

    <version-modal />
  </b-container>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import * as _ from 'lodash';
import { RoleEnum } from 'types';

import VersionModal from './components/VersionModal.vue';

@Component({
  name: 'App',
  components: { versionModal: VersionModal },
})
export default class App extends Vue {
  BOOSTRAP_MEDIUM_WIDTH = 768;

  /**
   * Checks if this is a phone
   */
  isSmallViewport = document.body.offsetWidth < this.BOOSTRAP_MEDIUM_WIDTH;

  sidebarVisible = false;

  get sidebarStatus() {
    return this.sidebarVisible;
  }

  set sidebarStatus(value) {
    if (this.isSmallViewport) this.sidebarVisible = value;
  }

  get title() {
    return process.env.VUE_APP_APP_TITLE;
  }

  get menuStyle() {
    return { 'background-color': process.env.VUE_APP_BACK_COLOR };
  }

  get isLoggedIn() {
    return this.$store.getters.isLoggedIn;
  }

  get isAdmin() {
    return this.$store.getters.roleId === RoleEnum.ADMIN;
  }

  get isCityAdmin() {
    return this.$store.getters.roleId === RoleEnum.CITY_ADMIN;
  }

  get isOperator() {
    return this.$store.getters.roleId === RoleEnum.OPERATOR;
  }

  get isRestaurantOwner() {
    return this.$store.getters.roleId === RoleEnum.RESTAURANT_OWNER;
  }

  get restId() {
    return this.$store.getters.restId;
  }

  get cityId() {
    return this.$store.getters.cityId;
  }

  get allowedSections() {
    const roleId = this.$store.getters.roleId as RoleEnum;

    switch (roleId) {
      case RoleEnum.ADMIN:
        return [
          {
            to: '/stats',
            text: this.$t('menu.stats'),
          },
          {
            to: '/bots',
            text: this.$t('menu.bots'),
          },
          {
            to: '/cuisines',
            text: this.$t('menu.cuisines'),
          },
          {
            to: '/restaurants',
            text: this.$t('menu.restaurants'),
          },
          {
            to: '/reviews',
            text: this.$t('menu.reviews'),
          },
          {
            to: '/ads',
            text: this.$t('menu.ads'),
          },
          {
            to: '/cities',
            text: this.$t('menu.cities'),
          },
          // {
          //   to: '/language/ru',
          //   text: this.$t('menu.russian'),
          // },
          // {
          //   to: '/language/en',
          //   text: this.$t('menu.english'),
          // },
          // {
          //   to: '/language/ka',
          //   text: this.$t('menu.georgian'),
          // },
          {
            to: '/spam',
            text: this.$t('menu.spam'),
          },
          {
            to: '/users/top',
            text: this.$t('menu.top'),
          },
          {
            to: '/orders',
            text: this.$t('menu.orders'),
          },
          {
            to: '/tags',
            text: this.$t('menu.tags'),
          },
          {
            to: '/admins',
            text: this.$t('menu.users'),
          },
          {
            to: '/logout',
            text: this.$t('menu.logout'),
          },
        ];
      case RoleEnum.CITY_ADMIN:
        return [
          {
            to: '/restaurants',
            text: this.$t('menu.restaurants'),
          },
          {
            to: '/reviews',
            text: this.$t('menu.reviews'),
          },
          {
            to: '/cuisines',
            text: this.$t('menu.cuisines'),
          },
          {
            to: '/ads',
            text: this.$t('menu.ads'),
          },
          {
            to: '/city/' + this.cityId,
            text: this.$t('menu.city'),
          },
          {
            to: '/orders',
            text: this.$t('menu.orders'),
          },
          {
            to: '/tags',
            text: this.$t('menu.tags'),
          },
          {
            to: '/stats',
            text: this.$t('menu.stats'),
          },
          {
            to: '/spam',
            text: this.$t('menu.spam'),
          },
          {
            to: '/users/top',
            text: this.$t('menu.top'),
          },
          {
            to: '/admins',
            text: this.$t('menu.users'),
          },
          {
            to: '/logout',
            text: this.$t('menu.logout'),
          },
        ];
      case RoleEnum.RESTAURANT_OWNER:
        return [
          {
            to: '/restaurants/' + this.restId,
            text: this.$t('menu.myrest'),
          },
          {
            to: '/ads',
            text: this.$t('menu.ads'),
          },
          {
            to: '/orders',
            text: this.$t('menu.orders'),
          },
          {
            to: '/restaurants/' + this.restId + '/reviews',
            text: this.$t('menu.reviews'),
          },
          {
            to: '/logout',
            text: this.$t('menu.logout'),
          },
        ];
      case RoleEnum.OPERATOR:
        return [
          {
            to: '/restaurants',
            text: this.$t('menu.restaurants'),
          },
          {
            to: '/reviews',
            text: this.$t('menu.reviews'),
          },
          {
            to: '/city/' + this.cityId,
            text: this.$t('menu.city'),
          },
          {
            to: '/orders',
            text: this.$t('menu.orders'),
          },
          {
            to: '/logout',
            text: this.$t('menu.logout'),
          },
        ];
      default:
        return [];
    }
  }

  get showMenuMovedTooltip() {
    return (
      this.$store.state.showMenuHasBeenMovedTooltip &&
      !this.sidebarVisible &&
      this.$store.getters.isLoggedIn
    );
  }

  get appVersion() {
    return process.env.VUE_APP_VERSION;
  }

  mounted() {
    if (!this.isLoggedIn) {
      this.$router.push('/login');
      this.$store.commit('logout');
    } else {
      this.$store.dispatch('loadSavedToken');
    }

    window.addEventListener('resize', () => {
      this.isSmallViewport =
        document.body.offsetWidth < this.BOOSTRAP_MEDIUM_WIDTH;
    });

    // Gets additional details for admins depending on their roles

    this.$store.dispatch('updateDetails');

    // Shows alert if important parameters is not set

    if (this.isAdmin) {
      this.$store.dispatch('getMissingGlobalSettings').then(() => {
        const { missingGlobalSettings } = this.$store.state;

        _.map(missingGlobalSettings, (message) => {
          this.$bvToast.toast(message, {
            toaster: 'b-toaster-bottom-right',
            title: 'A global setting is missing!',
            autoHideDelay: 20000, // 10s
            variant: 'danger',
          });
        });

        this.$store.commit('clearMissingGlobalSettings');
      });

      this.$store.dispatch('getMissingCityConfigParameters').then(() => {
        const { citiesWithMissingParameters } = this.$store.state;

        _.map(citiesWithMissingParameters, ([cityName, parameters]) => {
          this.$bvToast.toast(
            `${cityName} city is missing this set of config parameters: ${parameters}. Some functionallity might not work.`,
            {
              toaster: 'b-toaster-bottom-right',
              title: 'Important city config parameters are missing',
              autoHideDelay: 20000, // 10s
              variant: 'warning',
            },
          );
        });

        this.$store.commit('clearListOfMissingCityParameters');
      });
    }
  }
}
</script>

<style lang="scss">
@import '../../../node_modules/bootstrap/scss/bootstrap';
@import '../../../node_modules/bootstrap-vue/src/index.scss';

#app {
  // min-height: 100vh;
  height: 100vh;
  // min-width: 100vw;
  background-color: #ededed;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.nav a {
  color: black;
}

#sidebar {
  background-color: #ededed !important;
}

.version {
  position: absolute;
  bottom: 1em;
  width: 100%;
  display: block;
}

.app__header {
  position: sticky;
  top: 0;
  z-index: 1024;
}

.app__sidebar {
  min-height: 94vh;
  height: auto;
  position: sticky;
}
</style>
