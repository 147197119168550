import { RouteConfig } from 'vue-router';

import Admins from '../components/Admins.vue';
import Admin from '../components/Admin.vue';
import Login from '../components/Login.vue';
import Logout from '../components/Logout.vue';
import AdminAdd from '../components/AdminAdd.vue';
import UsersTop from '../components/UsersTop.vue';

export const userRoutes: Array<RouteConfig> = [
  {
    // title: prefix + "Login",
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    // title: prefix + "Language selector",
    path: '/logout',
    name: 'Logout',
    component: Logout,
  },
  {
    path: '/admins',
    name: 'Admins',
    component: Admins,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/add',
    name: 'AdminAdd',
    component: Admin,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/users/top',
    name: 'UsersTop',
    component: UsersTop,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin/:id',
    name: 'Admin',
    component: Admin,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
];
