<template>
  <b-container fluid>
    <b-row class="mb-3" align-h="between">
      <b-col>
        <h1 class="m-0 mb-2">{{ $t('dishes.title') }}</h1>
      </b-col>

      <b-col
        align-self="center"
        class="d-flex justify-content-end"
        v-if="!isOperator"
      >
        <b-button variant="outline-success" to="/dishes/add">
          {{ $t('dishes.button_add') }}
        </b-button>
      </b-col>
    </b-row>

    <hr />

    <b-row class="mt-3">
      <b-col
        v-for="dish in dishes"
        :key="dish.id"
        xl="12"
        lg="12"
        md="6"
        sm="12"
        class="mb-3"
      >
        <b-card>
          <b-row align-v="end">
            <b-col sm="12" lg="auto" class="pr-lg-0">
              <h4 class="m-0">
                {{ dish[`name_${interfaceLang}`] || dish.name }}
              </h4>
            </b-col>

            <b-col sm="12" lg="auto">
              <span class="d-none d-lg-inline">/</span>

              <span class="text-secondary">
                {{ dish.name }}
              </span>
            </b-col>

            <b-col class="d-none d-lg-block text-right">
              <b-link variant="link" :to="'/dishes/' + dish.id">
                <b-icon-pencil />
                {{ $t('dishes.edit') }}
              </b-link>
            </b-col>
            <b-col sm="12" lg="auto" align-self="end">
              <small :title="String(dish.updatedAt)"
                ><i
                  >{{ $t('dishes.lastUpdated') }}
                  {{ inWords(new Date(dish.updatedAt)) }}</i
                ></small
              >
            </b-col>
          </b-row>

          <hr class="d-lg-none" />

          <div
            class="mt-3 d-flex justify-content-between justify-content-lg-start align-items-center"
          >
            {{ $t('dishes.price') }}:
            <b-badge pill variant="success" class="ml-lg-2">
              {{ dish.price }}
            </b-badge>
          </div>

          <div
            class="mt-3 d-flex justify-content-between justify-content-lg-start align-items-center"
          >
            {{ $t('dishes.amount') }}:
            <b-badge class="ml-lg-2">
              {{ dish.amount }}
            </b-badge>
          </div>

          <div
            class="mt-1 mt-lg-0 d-flex justify-content-between justify-content-lg-start align-items-center"
          >
            {{ $t('dishes.enabled') }}:
            <b-form-checkbox
              class="d-inline ml-1 ml-lg-2"
              v-model="dish.enabled"
              @input="changeState(dish)"
            />
          </div>

          <hr class="d-lg-none" />

          <div class="text-center d-lg-none">
            <b-link variant="link" :to="'/dishes/' + dish.id">
              {{ $t('dishes.edit') }}
            </b-link>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { IDish, RoleEnum } from 'types';
import { formatDistanceToNow } from 'date-fns';

const storagePrefix = process.env.VUE_APP_STORAGE_PREFIX;

@Component({ name: 'Dishes' })
export default class Dishes extends Vue {
  public dishes: IDish[] = [];

  interfaceLang = this.$store.getters.interfaceLang;

  public async changeState(dish: IDish) {
    const response = await this.axios.put<IDish>(`/dishes/${dish.id}`, {
      enabled: dish.enabled,
    });

    dish = response.data;
  }

  async mounted() {
    const response = await this.axios.get<IDish[]>(
      '/dishes?dishCategoryId=' +
        localStorage.getItem(`${storagePrefix}-dishCategoryId`),
    );

    this.dishes = response.data.sort((a, b) => {
      if (a.position > b.position) return 1;
      if (a.position < b.position) return -1;

      return a.name.localeCompare(b.name);
    });
  }

  get isOperator() {
    return this.$store.getters.roleId === RoleEnum.OPERATOR;
  }

  inWords(date: Date) {
    return formatDistanceToNow(date, { addSuffix: true });
  }
}
</script>

<style scoped></style>
