import { RouteConfig } from 'vue-router';

import Districts from '../components/Districts.vue';
import District from '../components/District.vue';
import Cities from '../components/Cities.vue';
import City from '../components/City.vue';

export const cityRoutes: Array<RouteConfig> = [
  {
    path: '/cities',
    component: Cities,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/city/',
    component: City,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/city/:id',
    component: City,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/city/:cityId/districts',
    component: Districts,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/city/:cityId/district',
    component: District,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/city/:cityId/district/:id',
    component: District,
    meta: {
      requiresAuth: true,
    },
  },
];
