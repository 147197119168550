<template>
  <b-container fluid>
    <b-row class="mb-3">
      <b-col>
        <h1>Кухни</h1>
      </b-col>

      <b-col cols="12" md="auto">
        <b-button pill to="/cuisines/add" variant="outline-success">
          Добавить кухню
        </b-button>
      </b-col>
    </b-row>

    <b-row v-if="isAdmin" class="mb-3">
      <b-col md="12">
        <label for="input-1"> Выберите город: </label>

        <b-form-select @input="onCityChanged" :options="cities" />
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <b-table
          :fields="fields"
          :items="cuisines"
          hover
          strip
          class="text-center"
        >
          <template v-slot:cell(name)="cuisine">
            <b-link :to="'/cuisines/' + cuisine.item.id">
              {{ cuisine.item.name }}
            </b-link>
          </template>
          <template v-slot:cell(enabled)="data">
            {{ data.item.enabled ? '✅' : '❌' }}
          </template>
          <template v-slot:cell(cityId)="data">
            <span v-if="data.item.city">
              {{ data.item.city.name }}
            </span>
            <span v-else class="text-danger"> &lt;no-city&gt; </span>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ICity, ICuisine, CitySelectable, RoleEnum } from 'types';

@Component({ name: 'Cuisines' })
export default class Cuisines extends Vue {
  fields = [
    {
      key: 'name',
      label: 'Название (en)',
      sortable: true,
    },
    {
      key: 'name_ru',
      label: 'Название (ru)',
      sortable: true,
    },
    {
      key: 'position',
      label: 'Позиция в списке',
      sortable: true,
    },
    {
      key: 'enabled',
      label: this.$t('cuisines.enabled'),
      sortable: true,
    },
    {
      key: 'cityId',
      label: 'Город',
    },
  ];

  isAdmin = this.$store.getters.roleId === RoleEnum.ADMIN;
  cuisines = [];
  cities: CitySelectable[] = [];

  async mounted() {
    const cuisinesResponse = await this.axios.get('/cuisines');
    this.cuisines = cuisinesResponse.data;

    if (this.isAdmin) {
      this.cities = await this.axios
        .get<ICity[]>('/cities')
        .then(({ data }) => {
          const citiesFormatted = data.map((city) => ({
            value: city,
            text: city.name,
          }));

          return [...citiesFormatted, { value: null, text: 'Все города' }];
        });
    }
  }

  async onCityChanged(value: ICity) {
    const url = `/cuisines` + (value ? `/city/${value.id}` : '');

    this.cuisines = await this.axios
      .get<ICuisine[]>(url)
      .then(({ data }) => data);
  }
}
</script>

<style scoped></style>
