<template>
  <div>
    <div class="row">
      <div class="col">
        <h1>Добавить кухню</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-form @submit.prevent="onSubmit">
          <!-- Allows to choose the city if this is an admin -->

          <b-form-group v-if="isAdmin" label="Выберите город:">
            <b-form-select v-model="form.cityId" :options="cities" required />
          </b-form-group>

          <label for="input-2" class="mt-3">
            Название кухни на английском:
          </label>
          <b-form-input
            id="input-2"
            placeholder="Название кухни (en)"
            type="text"
            v-model="form.name"
          />

          <label for="input-1"> Название кухни (ru): </label>
          <b-form-input
            id="input-1"
            placeholder="Название кухни"
            type="text"
            v-model="form.name_ru"
          />

          <label for="input-3" class="mt-3"> Позиция в списке: </label>
          <b-form-input
            id="input-3"
            placeholder="12"
            type="number"
            v-model="form.position"
          />

          <b-form-checkbox
            id="checkbox-1"
            v-model="form.enabled"
            name="checkbox-1"
            class="mt-3"
          >
            {{ $t('cuisines.enabled') }}
          </b-form-checkbox>

          <b-button
            squared
            type="submit"
            variant="outline-primary"
            class="mt-3"
          >
            Добавить кухню
          </b-button>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { RoleEnum, ICity, ICuisine } from 'types';

type CityListWrapped = { value: ICity['id']; text: ICity['name'] };

@Component({ name: 'CuisineAdd' })
export default class CuisineAdd extends Vue {
  form: ICuisine = {
    name: null,
    name_ru: null,
    position: null,
    enabled: true,
    cityId: null,
  };

  cities: CityListWrapped[] = [];

  get isAdmin() {
    return this.$store.getters.roleId === RoleEnum.ADMIN;
  }

  get cityId() {
    return this.$store.getters.cityId;
  }

  async mounted() {
    /**
     * Superadmins allowed to choose a city where they want to create cuisine in.
     *
     * If this section was requested by a city admin, it will allow to create a cuisine
     * in the admin's city.
     */
    if (this.isAdmin) {
      const response = await this.axios.get<ICity[]>('/cities');

      this.cities = response.data.map((city) => ({
        value: city.id,
        text: city.name,
      }));
    } else {
      this.form.cityId = this.cityId;
    }
  }

  onSubmit() {
    this.axios
      .post('/cuisines/', this.form)
      .then(() => this.$router.push({ path: '/cuisines' }));
  }
}
</script>
