export default {
  validation: {
    mustBeLessThan: 'Length of the field must be less than {amount} symbols',
    mustBeMoreAndLessThan:
      'Length of the field must be more than {moreAmount} and less than {lessAmount} symbols',
    userNotFound: 'User is not found',
    scheduleError:
      'Only this format accepted: 09:30-00:00 or 09:30-14:00,15:00-20:30. Please, correct the field.',
    cannotBeNull: 'Cannot be null',
    cantPublishAds: 'Cannot publish ads:',
  },
  ad: {
    chatId: 'Chat ID for publication',
    h1: 'Advertisements',
    edit: 'Edit ad',
    title: 'Title: {title}',
    title_placeholder: 'Ad #1',
    text: "Text of advertisement. (Don't forget it will be publish on Russian speaking Telegram chat):",
    text_placeholder: 'We got super best restaurant',
    publishDate: 'Start time: {publishDate}',
    unpublishDate: 'Time of remove: {unpublishDate}',
    restaurant: 'Restaurant: {restaurant}',
    image: 'Image:',
    drop_placeholder: 'Drop here',
    file_placeholder: 'Choose file',
    add: '+ Advertisement',
    save: 'Save',
    delete: 'Delete advertisement',
    new: '+ Advertisement',
    status: 'Status',
    created: 'Waiting',
    published: 'Published',
    removed: 'Removed',
    unpublished: 'Removed',
    timeout: 'Can create next: {timeout}',
    reload: 'Reload page',
    publicationError: 'Error occured during publication',
    removePublicationError: 'Error occured during removing of the ad',
    lengthValidation: 'The message length must be less than 1024 characters',
    waitingForImage: 'Attaching image to ad',
    pleaseWaitUpload: 'Please wait until the photo is uploaded...',
  },
  city: {
    add: '+ Add city',
    active: 'City is active',
    all: 'Cities',
    chatname: 'City chat',
    currency: 'Currency in city',
    one: 'City',
    save: 'Save changes',
    regions: 'Regions ->',
    name: 'Name (eng)',
    name_ru: 'Name (rus)',
    cityDisabledReason: 'Why city is disabled',
    choose: 'Choose a city',
    choose_placeholder: 'Please select a city',
    deliveryCostJump: 'Delivery cost jump settings',
    deliveryCostJumpStart: 'When should the price jump start?',
    locales: 'Languages for couriers',
    hideMoney: 'Hide prices from couriers',
  },
  config: {
    ads: 'Advertisements',
    adsChannelId: 'Channel ID for advertisements',
    adsDelay: 'Timeout beetween advertisements (in hours)',
    adsTime: 'Time for publication (in hours)',
    deliveryMultiplier: 'Delivery multiplier',
    delete: 'Remove',
    ids: 'Identificators',
    infoButtonText_en: 'Info text in English',
    infoButtonText_ru: 'Info text in Russian',
    mainImage: 'Default image',
    notifyChannelId: 'Channel ID for notifications',
    reviewChannelId: 'Channel ID for reviews',
    save: 'Save',
    settings: 'Settings',
    showInfoButton: 'Show info button',
    showTopTen: 'Show top 10 button',
    showOpenNow: 'Show "Open now" button',
    showTagsButton: 'Show "Tags" button',
    deliveryCostJump: 'Enable delivery cost increase',
    annoyRestaurants: 'Annoy restaurants with notifications',
  },
  cuisines: {
    enabled: 'Show in list',
  },
  dishCategories: {
    title: 'Dishes categories',
    add_button: '+ Add the dishes category',
    name: 'Name of category (English)',
    name_ru: 'Name of category (Russian)',
    position: 'Position on the list',
    enabled: 'Show in bot',
    edit: 'Edit',
    dishes: 'Get dishes',
    displayBetween: 'Displayed:',
    alwaysDisplayed: 'always',
    lastUpdated: 'Last updated',
  },
  dishCategory: {
    add: 'New category',
    button_remove: 'Remove the category',
    button_update: 'Update category',
    button_add: 'Add',
    name: 'Name of category (English)',
    name_placeholder: 'Name of category (English)',
    name_ru: 'Name of category (Russian)',
    name_ru_placeholder: 'Name of category (Russian)',
    position: 'Position on the list',
    dishes: 'Dishes',
    enabled: 'Show in your menu',
    startTime: 'Start showing at:',
    endTime: 'Stop showing at:',
    confirm_delete: 'Are you sure you want to delete this category?',
  },
  dishes: {
    title: 'Dishes',
    button_add: 'Add the dish',
    name: ' Name of the dish (English)',
    name_ru: 'Name of the dish (Russian)',
    position: 'Position on the list',
    description: 'Description',
    price: 'Price',
    enabled: 'Show in bot',
    amount: 'Remaining amount',
    edit: 'Edit',
    lastUpdated: 'Last updated',
  },
  dish: {
    title: 'New dish',
    name: ' Name of the dish (English)',
    name_placeholder: ' Name of the dish (English)',
    name_ru: 'Name of the dish (Russian)',
    name_ru_placeholder: 'Name of the dish (Russian)',
    position: 'Position on the list',
    description: 'Description (English)',
    description_placeholder: 'Description (English)',
    description_ru: 'Description (Russian)',
    description_ru_placeholder: 'Description (Russian)',
    price: 'Price:',
    image: 'Photo of the dish:',
    image_placeholder: 'Select a file',
    button_add: 'Add',
    button_remove: 'Remove dish',
    button_update: 'Update dish',
    enabled: 'Show in your menu',
    amount: 'Count remaining amount',
    tags: 'Tags',
    tags_disclaimer:
      'Tags are used for speeding up the search for dishes for customers. You can chose not more than 2 tags for one dish.',
    confirm_delete: 'Are you sure you want to delete this dish?',
  },
  home: {
    title: 'Home page',
    body: 'Select language',
    menuMovedTitle: 'The menu has been moved here!',
    menuMovedText: 'You can access it by pressing this button.',
    dontShowAgain: 'Do not show it again',
    pleaseReloadPage: 'New version was released!',
    pleaseReloadPageDetails: `We are constantly working on our service improvement. Please reload this page to continue working with our product!`,
  },
  language: {
    search_placeholder: 'Search...',
  },
  logout: {
    title: 'Logout',
    button: 'Logout',
  },
  login: {
    title: 'Log in',
    login: 'Login',
    login_placeholder: 'Enter your login',
    password: 'Password',
    password_placeholder: 'Enter your password',
    button: 'Enter',
    error: 'You provided a wrong password or login',
  },
  menu: {
    reviews: 'Reviews',
    bots: 'Bots',
    ads: 'Advertisments',
    login: 'Log in',
    logout: 'Log out',
    cities: 'Cities',
    cuisines: 'Cuisines',
    restaurants: 'Restaurants',
    channel: 'Notifications',
    config: 'Site config',
    russian: 'Russian texts',
    georgian: 'Georgian texts',
    english: 'English texts',
    spam: 'Bulk mail',
    stats: 'Stats',
    orders: 'Orders',
    top: 'Customers top',
    users: 'Users',
    myrest: 'My restaurant',
    regions: 'Regions',
    city: 'Edit city',
    tags: 'Tags',
  },
  bots: {
    title: 'List of running bots',
    add: 'Add new bot',
    main_page_title: 'Bot of city',
  },
  region: {
    all: 'Regions',
    add: '+ Add region',
    delete: 'Remove region',
    one: 'Region',
    name: 'Region name (in english)',
    name_ru: 'Region name (in russian)',
    regions: 'Enter regions (1 per line)',
    regions_placeholder: 'Left\nRight',
    save: 'Save data',
  },
  restaurant: {
    descriptionLengthError:
      'Length of the description must be less than 512 symbols',
    address: 'Google link to map',
    general: 'General options',
    contact: 'Contact',
    cities: 'Choose the city:',
    maximumTimeForPreorder: 'Preordering maximum time (in hours)',
    chose_delivery_city: 'Choose city where delivery is available',
    delivery: 'Delivery config',
    title_add: 'New restaurant',
    dishCategories: 'Dishes categories',
    remove: 'Remove',
    name: 'Name of the restaurant: {name}',
    name_placeholder: 'Please enter name of the restaurant again',
    name_ru: 'Name of the restaurant: {name}',
    name_ru_placeholder: 'Name of the restaurant',
    receipt_text: 'Text in reciept (English):',
    receipt_text_ru: 'Text in reciept (Russian):',
    description_ru: 'Description (Russian):',
    description_ru_placeholder: 'Description (Russian)',
    description: 'Description (English):',
    description_placeholder: 'Description (English)',
    position: 'Position on the list: {position}',
    minOrderSum: 'Minimum order: {sum}',
    deliveryTime: 'Delivery time: {time}',
    deliveryTime_placeholder: '25 min',
    schedule: 'Working hours: {schedule}',
    schedule_placeholder: '09:00-00:00',
    schedule_error:
      'Only this format accepted: 09:30-00:00 or 09:30-14:00,15:00-20:30. Please, correct the field.',
    deliveryPrice: 'Delivery charges:',
    notificationAccount: 'Telegram account for orders: {notificationAccount}',
    districtId: 'Restaurant disctrict',
    cuisines: 'Cuisines:',
    image: 'Restaurant logo:',
    file_placeholder: 'Select a file',
    update: 'Update',
    button_add: 'Add restaurant',
    url: 'Direct url for restaurant in bot:',
    account_tooltip:
      'Send /id command to any of our bots to get your telegram id.',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    link: 'Link to the restaurant',
    linkUnavailable: '🚫 No bot works in city where the restaurant is placed',
    confirm_delete: 'Are you sure you want to delete this restaurant?',
  },
  restaurants: {
    title: 'Restaurants',
    add_button: '+ Add restaurant',
    name: 'Name (en)',
    name_ru: 'Name (ru)',
    canPickup: 'Pickup?',
    canDelivery: 'Delivery enabled',
    canSelfCuisine: 'Show the cuisine choice',
    enabled: 'Working now',
    active: 'Active (admin)',
    position: 'Position on the list',
    minOrderSum: 'Minimum order',
    deliveryTime: 'Delivery time',
    deliveryPrice: 'Delivery price',
    notificationAccount: 'Notification account',
  },
  spam: {
    user_total_count: 'Total user count',
  },
  chat: {
    title: 'Chat with customer {customerName}',
    orderHasBeenChanged: 'The user made an order №{threadId}',
    inputPlaceholder: 'Write here a message to send',
    send: 'Send',
    sendToRestaurant: 'Send to restaurant',
    chatIsEmpty: "There's no messages in this chat yet",
    customer: 'Customer',
  },
  orders: {
    title: 'Orders',
    export: 'Export to Excel',
    startingDate: 'Starting date:',
    endingDate: 'Ending date:',
    city: 'City:',
    restaurants: 'Restaurants:',
    restaurantDisclaimer: 'Choose one or multiple restaurants',
    showNumbers: 'Show numbers?',
    totalSum: 'Total sum for this period:',
    totalSumWithoutDelivery: 'Total sum without delivery cost for this period:',
    ordersAmount: 'Orders amount:',
    averageCheque: 'Average cheque:',
    sum: 'Sum:',
    sumWithoutDelivery: 'Sum without delivery:',
    orderedAt: 'Order date:',
    customerName: 'Customer name:',
    contactType: 'Contact type:',
    phone: 'Phone:',
    status: 'Status:',
    openChat: 'Open chat',
    enableBriefSummary: 'Enable brief summary?',
    paid: 'Paid:',
    paymentReceipt: 'Charge ID:',
    serviceFee: 'Service fee:',
    courier: 'Courier:',
    invoiceId: 'Invoice ID:',
    changeStatus: 'Change status',
    statusesHelp: {
      noStatus: 'Unknown status',
      RESTAURANT_CLOSED: 'Restaurant closed',
      CANCELED_BY_RESTAURANT: 'Canceled by restaurant',
      CANCELED_BY_USER: 'Canceled by user',
      DRAFT: 'Order draft',
      CREATED: 'Just created, will be paid in cash',
      NOT_PAID: 'Just created, waiting for card payment',
      PAID: 'Paid by card',
      WAITING_FOR_COURIER: 'Waiting for courier',
      READY: 'Restaurant prepared the order, waiting for operator confirmation',
      CONFIRMED: 'Restaurant confirmed the order, starts preparing it',
      SERVING: 'Order is being prepared in the restaurant',
      DELAYED: 'Order delayed',
      COOKED: 'Order cooked',
      IN_RESTAURANT: 'Order ready for pickup',
      OUT_FOR_DELIVERY: 'Order on the way',
      CALL_CUSTOMER: 'Need to call the customer',
      DELIVERED: 'Order delivered',
    },
    statuses: {
      noStatus: 'unknown',
      CREATED: 'not confirmed',
      CONFIRMED: 'confirmed',
      SERVING: 'processing',
      READY: 'cooked',
      WAITING_FOR_COURIER: 'delivered',
      NOT_PAID: 'not paid',
      RESTAURANT_CLOSED: 'restaurant closed',
      CANCELED_BY_RESTAURANT: 'canceled by restaurant',
      CANCELED_BY_USER: 'canceled by user',
      DRAFT: 'order draft',
      PAID: 'paid',
      DELAYED: 'delayed',
      COOKED: 'cooked',
      IN_RESTAURANT: 'in restaurant',
      OUT_FOR_DELIVERY: 'on the way',
      CALL_CUSTOMER: 'need to call the customer',
      DELIVERED: 'delivered',
    },
  },
  tags: {
    newTag: 'New tag',
    name: 'Name in English',
    name_ru: 'Name in Russian',
    update: 'Update tag',
    create: 'Create tag',
  },
  reviews: {
    title: 'Reviews',
    noText: 'No text',
    chooseCity: 'Choose city:',
    answer: {
      label: 'Answer the review:',
      submit: 'Submit',
    },
    controlling: {
      delete: 'Delete',
      approve: 'Approve',
      publish: 'Publish',
      answer: 'Answer',
    },
  },
};
