<template>
  <div>
    <div class="row-md-12 d-flex justify-content-between">
      <h2>
        <span>{{ $t('bots.main_page_title') }} {{ this.bot.city.name }}</span>
      </h2>

      <b-button squared @click="onDelete" variant="outline-danger">
        Delete bot
      </b-button>
    </div>

    <div class="row">
      <div class="col-md-12">
        <b-form @submit.prevent="onSubmit">
          <div class="mb-3">
            <!-- <label for="input-1">Endpoint</label>
                        <b-form-input
                            id="input-1"
                            v-model="bot.endpoint"
                        ></b-form-input> -->
            <span>Endpoint: </span>
            <em>{{ bot.endpoint }}</em>
          </div>

          <div class="mb-3">
            <label for="input-2">Bot token</label>
            <b-form-input id="input-2" v-model="bot.botToken"></b-form-input>
          </div>

          <div class="mb-3">
            <label for="input-3">City</label>
            <b-form-select
              id="input-3"
              v-model="bot.city"
              :options="cities"
            ></b-form-select>
          </div>

          <b-button type="submit" variant="outline-primary" class="mt-3">
            Update bot
          </b-button>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { IBot, ICity } from 'types';

@Component({ name: 'Bots' })
export default class Bots extends Vue {
  cities: ICity[] = [];
  bot: IBot & { city: Partial<ICity>; id?: string } = {
    botToken: '',
    endpoint: '',
    cityId: '',
    city: { name: '', name_ru: '' },
  };

  async mounted() {
    await this.axios.get(`/bots/${this.$route.params.id}`).then(({ data }) => {
      this.bot = { ...this.bot, ...data };

      this.axios.get('/cities/for_bots/' + this.bot.id).then(({ data }) => {
        this.cities = data.map((city) => ({
          value: city,
          text: city.name,
        }));
      });
    });
  }

  onSubmit() {
    // Removing unnecessary fields from the form

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, city, ...botData } = this.bot;

    // Updates the city_id (by default only bot.city value changes)

    botData.cityId = city.id;

    this.axios
      .put(`/bots/${this.$route.params.id}`, botData)
      .then(() => this.$router.push({ path: '/bots' }));
  }

  onDelete() {
    this.axios
      .delete(`/bots/${this.$route.params.id}`)
      .then(() => this.$router.push({ path: '/bots' }));
  }
}
</script>
