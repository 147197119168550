"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkMailingProperties = exports.Valued = void 0;
const helpers_1 = require("../helpers");
class Valued {
}
exports.Valued = Valued;
exports.checkMailingProperties = (0, helpers_1.checkProperties)([
    'text',
    'cityId',
]);
