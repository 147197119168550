import { RouteConfig } from 'vue-router';

import Ad from '../components/Ad.vue';
import Ads from '../components/Ads.vue';

export const adRoutes: Array<RouteConfig> = [
  {
    path: '/ads',
    name: 'Ads',
    component: Ads,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/ads/add',
    name: 'AdsAdd',
    component: Ad,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/ads/:id',
    name: 'Ad',
    component: Ad,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
];
