<template>
  <b-container fluid>
    <h4>{{ $t('reviews.title') }}</h4>

    <hr />

    <b-form-group
      :label="$t('reviews.chooseCity')"
      v-if="isAdmin && !restaurantId"
    >
      <b-form-select
        id="citypicker"
        :options="cities"
        required
        @input="onCitySelected"
      />
    </b-form-group>

    <b-row>
      <b-col sm="12" md="6" v-for="review in reviews" :key="review.orderId">
        <b-card class="p-0 mb-3" header-bg-variant="white">
          <template #header>
            <div class="d-flex">
              <div>
                <h6 class="m-0">
                  {{ review.order.restaurant.name }}
                  <small> #{{ review.order.threadId }} </small>
                </h6>

                <sub>{{ review.createdAtString }}</sub>
              </div>

              <div class="ml-auto" v-if="review.telegramFileId">
                <b-icon-image
                  class="load-image-button"
                  @click="loadImage(review)"
                />
              </div>
            </div>
          </template>

          <small v-if="review.text">
            {{ review.text }}
          </small>

          <code v-else> {{ $t('reviews.noText') }} </code>

          <div
            class="text-right"
            v-if="review.telegramProfile && !isRestaurantOwner"
          >
            <small>
              <em>
                {{ review.telegramProfile.name }}
                <span v-if="review.telegramProfile.username">
                  <a
                    target="blank"
                    :href="'http://t.me/' + review.telegramProfile.username"
                  >
                    <span>(@</span>
                    <span>
                      {{ review.telegramProfile.username }}
                    </span>
                    <span>)</span>
                  </a>
                </span>
              </em>
            </small>
          </div>

          <template #footer>
            <div class="d-flex align-items-center">
              <div>{{ getRatingEmojied(review) }}</div>

              <b-dropdown
                right
                variant="blank"
                toggle-class="text-decoration-none"
                class="ml-auto mr-1"
                no-caret
              >
                <template #button-content>
                  <b-icon-caret-down-fill class="secondary" />
                </template>

                <b-dropdown-item
                  v-if="review.publishedAt"
                  @click="review.answerShown = !review.answerShown"
                >
                  <b-icon-envelope class="mr-1" variant="primary" />
                  {{ $t('reviews.controlling.answer') }}
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="!review.publishedAt"
                  @click="onPublish(review)"
                >
                  <b-icon-messenger variant="success" />
                  {{ $t('reviews.controlling.publish') }}
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="!review.publishedAt"
                  @click="onApproved(review)"
                >
                  <b-icon-check variant="warning" />
                  {{ $t('reviews.controlling.approve') }}
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="!isRestaurantOwner"
                  @click="onDelete(review)"
                >
                  <b-icon-trash variant="danger" />
                  {{ $t('reviews.controlling.delete') }}
                </b-dropdown-item>
              </b-dropdown>
            </div>

            <!-- hidden input for answering -->

            <b-collapse v-model="review.answerShown" class="mt-2">
              <b-form @submit.prevent="onAnswer(review)">
                <b-form-group :label="$t('reviews.answer.label')">
                  <b-form-textarea
                    :disabled="Boolean(review.answer)"
                    v-model="review.answerText"
                  />
                </b-form-group>

                <b-button
                  :disabled="Boolean(review.answer)"
                  variant="primary"
                  type="submit"
                >
                  <b-spinner small v-if="review.answering" />
                  Submit
                </b-button>
              </b-form>
            </b-collapse>
          </template>
        </b-card>
      </b-col>
    </b-row>

    <b-modal centered v-model="imageModalShown" hide-footer>
      <b-img fluid :src="imageSource" alt="Review photo" />
    </b-modal>
  </b-container>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ICity, IReview, RoleEnum } from 'types';

interface ReviewFormatted extends IReview {
  createdAtString: string;
  approving: boolean;
  deleting: boolean;
  answering: boolean;
  answerShown: boolean;
  answerText: string;
  telegramProfile?: {
    name: string;
    username: string;
  };
}

type PreparedCity = { text: string; value: ICity };

@Component({ name: 'Reviews' })
export default class Reviews extends Vue {
  reviews: ReviewFormatted[] = [];
  cities: PreparedCity[] = [];
  selectedCity: ICity = null;

  imageModalShown = false;
  imageSource: string = null;

  answerText = '';

  async updateReviewList() {
    if (!this.cityId && !this.restaurantId) return null;

    const reviewResponse = await this.axios.get<IReview[]>(
      '/reviews/' +
        (this.restaurantId ? `restaurant/${this.restaurantId}` : this.cityId),
    );

    this.reviews = reviewResponse.data?.map((review: IReview) => {
      return {
        ...review,
        createdAtString: new Date(review.createdAt).toLocaleString('ru'),
        approving: false,
        answering: false,
        deleting: false,
        answerShown: Boolean(review.answer),
        answerText: review.answer,
      };
    });
  }

  async mounted() {
    this.updateReviewList();

    if (this.isAdmin) {
      const citiesResponse = await this.axios.get<ICity[]>('/cities');
      this.cities = citiesResponse?.data?.map((city) => ({
        text: city.name,
        value: city,
      }));
    }
  }

  async onCitySelected(city: ICity) {
    this.selectedCity = city;
    this.updateReviewList();
  }

  async onPublish(review: ReviewFormatted) {
    review.approving = true;
    await this.axios.post('/reviews/' + review.id, { publish: true });

    await this.updateReviewList();
  }

  async onApproved(review: ReviewFormatted) {
    review.approving = true;
    await this.axios.post('/reviews/' + review.id);

    await this.updateReviewList();
  }

  async onDelete(review: ReviewFormatted) {
    review.deleting = true;
    await this.axios.delete('/reviews/' + review.id);

    await this.updateReviewList();
  }

  async onAnswer(review: ReviewFormatted) {
    review.answering = true;
    await this.axios.put('/reviews/' + review.id, {
      text: review.answerText,
    });

    await this.updateReviewList();
  }

  async loadImage(review: ReviewFormatted) {
    this.imageSource = null;

    const response = await this.axios.get(`/photo/`, {
      responseType: 'blob',
      params: {
        cityId: this.cityId,
        telegramFileId: review.telegramFileId,
      },
    });

    this.imageSource = URL.createObjectURL(response.data);
    this.imageModalShown = true;
  }

  getRatingEmojied(review: IReview): string {
    const length = review.rating > 0 ? review.rating : 0;

    return new Array(length).fill('⭐').join('');
  }

  get isAdmin() {
    return this.$store.getters.roleId === RoleEnum.ADMIN;
  }

  get isCityAdmin() {
    return this.$store.getters.roleId === RoleEnum.CITY_ADMIN;
  }

  get isRestaurantOwner() {
    return this.$store.getters.roleId === RoleEnum.RESTAURANT_OWNER;
  }

  get isOperator() {
    return this.$store.getters.roleId === RoleEnum.OPERATOR;
  }

  get restaurantId() {
    return this.$route.params?.id;
  }

  get cityId() {
    if (this.isCityAdmin || this.isOperator) return this.$store.getters.cityId;
    if (this.isAdmin) return this.selectedCity?.id;
    if (this.isRestaurantOwner) return this.$store.state.restaurant?.cityId;

    return null;
  }
}
</script>

<style scoped>
.load-image-button-container {
  border-left: 1px solid var(--secondary);
  height: auto;
  /* align-self: center; */
  margin: auto 1em;
  margin-left: 1em;
}

.load-image-button {
  font-size: 1.25em;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.load-image-button:hover {
  color: var(--primary);
  transform: scale(1.1);
}
</style>
