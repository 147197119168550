import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import store from '../store';
import Bots from '../components/Bots.vue';
import Bot from '../components/Bot.vue';
import BotsAdd from '../components/BotsAdd.vue';
import Tags from '../components/Tags.vue';
import Tag from '../components/Tag.vue';
import Cuisines from '../components/Cuisines.vue';
import Cuisine from '../components/Cuisine.vue';
import CuisineAdd from '../components/CuisineAdd.vue';
import Restaurants from '../components/Restaurants.vue';
import Restaurant from '../components/Restaurant.vue';
import RestaurantAdd from '../components/RestaurantAdd.vue';
import DishCategories from '../components/DishCategories.vue';
import DishCategoryAdd from '../components/DishCategoryAdd.vue';
import DishCategory from '../components/DishCategory.vue';
import Dishes from '../components/Dishes.vue';
import DishAdd from '../components/DishAdd.vue';
import Dish from '../components/Dish.vue';
import Language from '../components/Language.vue';
import Home from '../components/Home.vue';
import Spam from '../components/Spam.vue';
import Orders from '../components/Orders.vue';
import Reviews from '../components/Reviews.vue';
import Page404 from '../components/Page404.vue';

import { cityRoutes } from './cities';
import { adRoutes } from './ads';
import { userRoutes } from './user';
import { adminRoutes } from './admin';

Vue.use(VueRouter);

const defaultRoutes: Array<RouteConfig> = [
  {
    // will match everything
    path: '*',
    component: Page404,
  },
  {
    // title: prefix + "Language selector",
    path: '/',
    component: Home,
  },
  {
    path: '/bots',
    name: 'Bots',
    component: Bots,
    meta: { requiresAuth: true },
  },
  {
    path: '/bots/add',
    name: 'BotsAdd',
    component: BotsAdd,
    meta: { requiresAuth: true },
  },
  {
    path: '/bots/:id',
    name: 'Bot',
    component: Bot,
    meta: { requiresAuth: true },
  },
  {
    path: '/cuisines',
    name: 'Cuisines',
    component: Cuisines,
    meta: { requiresAuth: true },
  },
  {
    path: '/cuisines/add',
    name: 'CuisinesAdd',
    component: CuisineAdd,
    meta: { requiresAuth: true },
  },
  {
    path: '/cuisines/:id',
    name: 'Cuisine',
    component: Cuisine,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/tags',
    name: 'Tags',
    component: Tags,
    meta: { requiresAuth: true },
  },
  {
    path: '/tags/:id',
    name: 'Tag',
    component: Tag,
    meta: { requiresAuth: true },
  },
  {
    path: '/tag',
    name: 'Tag',
    component: Tag,
    meta: { requiresAuth: true },
  },
  {
    path: '/restaurants',
    name: 'Restaurants',
    component: Restaurants,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/restaurants/add',
    name: 'RestaurantAdd',
    component: RestaurantAdd,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/restaurants/:id',
    name: 'Restaurant',
    component: Restaurant,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/restaurants/:id/reviews',
    name: 'Reviews',
    component: Reviews,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/dishCategories',
    name: 'DishCategories',
    component: DishCategories,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/dishCategories/add',
    name: 'DishCategoryAdd',
    component: DishCategoryAdd,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/dishCategories/:id',
    name: 'DishCategory',
    component: DishCategory,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/dishes',
    name: 'Dishes',
    component: Dishes,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/dishes/add',
    name: 'DishAdd',
    component: DishAdd,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/dishes/:id',
    name: 'Dish',
    component: Dish,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/language/:id',
    name: 'Language',
    component: Language,
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/spam',
    name: 'Spam',
    component: Spam,
    meta: { requiresAuth: true },
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders,
    meta: { requiresAuth: true },
  },
  {
    path: '/reviews',
    name: 'Reviews',
    component: Reviews,
    meta: { requiresAuth: true },
  },
];

const routes = [
  ...defaultRoutes,
  ...cityRoutes,
  ...adRoutes,
  ...userRoutes,
  ...adminRoutes,
];

const router = new VueRouter({
  base: process.env.VUE_APP_BASE_ROUTE,
  mode: 'hash',
  routes,
});

router.beforeEach((to, from, next) => {
  // https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next('/login');
  } else {
    next();
  }
});

export default router;
