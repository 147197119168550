<template>
  <div>
    <b-row class="mb-3">
      <b-col>
        <h1>Массовая рассылка</h1>
      </b-col>
    </b-row>

    <b-row class="mb-3">
      <b-col md="12">
        <span>
          {{ $t('spam.user_total_count') }}:
          {{ amountOfUsers }}
        </span>
      </b-col>
    </b-row>

    <b-row class="mb-3">
      <b-col md="12">
        <b-form @submit.prevent="onSubmit">
          <b-row class="mb-3">
            <b-col :md="isAdmin ? 6 : 12">
              <label for="to-date">
                Пользователь был в последний раз активен до:
              </label>
              <b-form-datepicker
                v-model="lastActiveDate"
                id="to-date"
                type="datetime"
                @input="onLastActiveDateChanged"
                class="mb-2"
              />
            </b-col>

            <b-col md="6" v-if="isAdmin">
              <label for="input-8">Выберите город:</label>
              <b-form-select :options="cities" @input="onCityChanged" />
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group
                id="input-group-1"
                label="Текст:"
                label-for="input-2"
              >
                <b-form-textarea
                  class="input-area"
                  v-model="text"
                  rows="6"
                  max-rows="10"
                  placeholder="Напишите текст массового сообщения"
                >
                </b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mb-3">
            <b-col md="12">
              <b-form-checkbox v-model="bindedButton.enabled">
                Добавить кнопку к сообщению?
              </b-form-checkbox>
            </b-col>

            <b-col v-if="bindedButton.enabled" class="mt-2">
              <b-row>
                <b-col>
                  Тип кнопки:
                  <b-form-select
                    :options="buttonTypeOptions"
                    v-model="bindedButton.type"
                    @input="onButtonTypeChanged"
                  />
                </b-col>

                <b-col :class="bindedButton.type === 'url' ? 'text-muted' : ''">
                  Расположение кнопки:
                  <b-form-select
                    :options="buttonPositionOptions"
                    v-model="bindedButton.position"
                    :disabled="bindedButton.type === 'url'"
                  />
                </b-col>

                <b-col md="12" class="mt-2" v-if="bindedButton.type === 'url'">
                  Значение кнопки
                  <small>(url)</small>
                  :

                  <b-form-input v-model="bindedButton.value" />
                </b-col>

                <b-col md="12" class="mt-2" v-if="bindedButton.type === 'url'">
                  Текст кнопки:

                  <b-form-input v-model="bindedButton.text" />
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <label for="input-6">Изображение:</label>
              <b-form-input
                id="input-6"
                placeholder="http://unsplash.com/s..."
                default=""
                type="text"
                class="mb-3"
                v-model="photoId"
              />
            </b-col>
          </b-row>

          <b-button
            type="submit"
            variant="primary"
            :disabled="text.length < 10"
          >
            Отправить
          </b-button>

          <b-button
            class="ml-3"
            variant="outline-primary"
            @click="onTestSendClick"
            :disabled="text.length < 10"
          >
            Отправить тестовое сообщение
          </b-button>
        </b-form>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="mb-3" md="12">
        <label for="mailing-list">Предыдущие рассылки:</label>
      </b-col>

      <b-col md="12" class="mb-3">
        <ul id="mailing-list">
          <li
            v-for="list in spamLists"
            :key="list.id"
            @click="onMailingItemClick(list.id)"
            title="Нажмите, чтобы вставить текст сообщения в поле ввода"
          >
            {{ list.text.substr(0, 20) }}... /
            {{ new Date(list.createdAt).toLocaleString('ru') }} /
            {{ getStatus(list.status) }} /
            {{ ((list.position / (list.count || 1)) * 100).toFixed(2) }}
            %
          </li>
        </ul>
      </b-col>
    </b-row>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { EMailingStatus, ICity, IMailingBindedButton, IMailing } from 'types';

@Component({
  name: 'Mailing',
  computed: mapState(['spamLists']), // Needed for showing the process
})
export default class Mailing extends Vue {
  spamLists: IMailing[];
  text = '';
  intervalId = null;
  photoId: string = null;
  lastActiveDate: string = null;
  cities: ICity[] = [];
  cityId: ICity['id'] = null;
  amountOfUsers = 0;

  bindedButton: IMailingBindedButton = {
    enabled: false,
    type: 'url',
    position: 'inline',
    text: '',
    value: '',
  };

  buttonTypeOptions: IMailingBindedButton['type'][] = ['start', 'url'];
  buttonPositionOptions: IMailingBindedButton['position'][] = [
    'inline',
    'bottom',
  ];

  onButtonTypeChanged(newType: IMailingBindedButton['type']) {
    if (this.bindedButton.type === 'url') this.bindedButton.position = 'inline';
  }

  get isAdmin() {
    return this.$store.getters.roleId === 10;
  }

  get isCityAdmin() {
    return this.$store.getters.roleId === 30;
  }

  async onLastActiveDateChanged(date: string) {
    this.lastActiveDate = date;

    this.updateCount();
  }

  async onCityChanged(city: ICity) {
    this.cityId = city.id;

    this.updateCount();
  }

  async updateCount() {
    const params: { lastActiveDate?: string; cityId?: string } = {};

    if (this.lastActiveDate) params.lastActiveDate = this.lastActiveDate;
    if (this.cityId) params.cityId = this.cityId;

    const { data } = await this.axios.get<number>('/users/count', {
      params,
    });

    this.amountOfUsers = data;
  }

  onMailingItemClick(id: string) {
    const list = this.spamLists.find((item) => item.id === id);
    this.text = list.text;
    this.photoId = list.photoId;
    this.bindedButton = list.config.bindedButton;
  }

  getStatus(status: EMailingStatus) {
    switch (status) {
      case EMailingStatus.failed:
        return 'Ошибка';
      case EMailingStatus.created:
        return 'Ждёт';
      case EMailingStatus.processing:
        return 'Идёт';
      case EMailingStatus.finished:
        return 'Закончилась';
    }
  }

  created() {
    // Needed for updating status of spamming
    this.updateSpamStatus();
    this.intervalId = setInterval(this.updateSpamStatus, 60000);
  }

  destroy() {
    clearInterval(this.intervalId);
  }

  mounted() {
    if (this.isAdmin) {
      this.axios.get('/cities').then((response) => {
        this.cities = response.data.map((city) => ({
          value: city,
          text: city.name,
        }));
      });
    }

    if (this.isCityAdmin) {
      this.cityId = this.$store.getters.cityId;
    }
  }

  updateSpamStatus() {
    this.$store.dispatch('getSpamStatus');
  }

  onTestSendClick() {
    const telegramId = prompt(
        'Напишите Telegram ID пользователя для отправки тестового сообщения:',
      ),
      form = {
        photoId: this.photoId,
        text: this.text,
        cityId: this.cityId,
        recipientId: telegramId,
        bindedButton: null,
      };

    if (this.bindedButton.enabled) {
      form.bindedButton = this.bindedButton;
    }

    this.axios.post('/spam/sendTestMessage', form);
  }

  onSubmit() {
    const form = {
      photoId: this.photoId,
      text: this.text,
      lastActiveDate: this.lastActiveDate
        ? new Date(Date.parse(this.lastActiveDate))
        : null,
      cityId: this.cityId,
      bindedButton: null,
    };

    if (this.bindedButton.enabled) {
      form.bindedButton = this.bindedButton;
    }

    this.axios.post('/spam', form);
  }
}
</script>

<style scoped>
#mailing-list li {
  cursor: pointer;
}

#mailing-list li:hover {
  background-color: lightblue;
}
</style>
