import { RouteConfig } from 'vue-router';

import Config from '../components/Config.vue';
import Stats from '../components/Stats.vue';

export const adminRoutes: Array<RouteConfig> = [
  // {
  //   path: '/config',
  //   name: 'Config',
  //   component: Config
  // },
  {
    path: '/stats',
    name: 'Stats',
    component: Stats,
  },
];
