<template>
  <div>
    <div v-if="objects">
      <div class="row">
        <div class="col">
          <h1>Строки для перевода ({{ this.id }})</h1>
        </div>
        <div class="col">
          <b-form-input
            id="input-search"
            v-bind:placeholder="$t('language.search_placeholder')"
            v-model="filter"
            type="text"
          ></b-form-input>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <b-form @submit.prevent="onSubmit">
            <b-button
              :disabled="isSubmitted"
              class=""
              squared
              type="submit"
              variant="outline-primary"
            >
              Сохранить
            </b-button>
            <b-card
              v-for="(object, rootKey) in objects"
              :key="rootKey"
              v-bind:title="(object.title || rootKey) + ':'"
            >
              <b-form-group
                v-for="(value, key) in object"
                :key="key"
                v-show="key !== 'title'"
                v-bind:label="rootKey + '.' + key"
                v-bind:label-for="'input-area-' + rootKey + '-' + key"
                v-bind:hidden="
                  filter !== '' &&
                  object[key].toUpperCase().search(filter.toUpperCase()) < 0
                "
                label-cols="1"
                label-cols-lg="2"
                label-size="sm"
              >
                <b-form-textarea
                  v-bind:class="'input-area-' + rootKey + '-' + key"
                  v-model="objects[rootKey][key]"
                  v-bind:rows="object[key].split(/\r\n|\r|\n/).length + 2"
                  class="input-area"
                  placeholder=""
                >
                </b-form-textarea>
              </b-form-group>
            </b-card>
            <br />
            <b-button
              :disabled="isSubmitted"
              class="float-right"
              squared
              type="submit"
              variant="outline-primary"
            >
              Сохранить
            </b-button>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'Language',
  props: {
    id: String,
  },
  data() {
    return {
      submitted: false,
      objects: false,
      filter: '',
    };
  },
  mounted() {
    this.updateData();
  },
  beforeRouteUpdate(to, from, next) {
    // called when the route that renders this component has changed,
    // but this component is reused in the new route.
    // For example, for a route with dynamic params `/foo/:id`, when we
    // navigate between `/foo/1` and `/foo/2`, the same `Foo` component instance
    // will be reused, and this hook will be called when that happens.
    // has access to `this` component instance.
    this.updateData(to.params.id);
    next();
  },
  computed: {
    isSubmitted: {
      get: function () {
        return this.submitted;
      },
      set: function (newValue) {
        this.submitted = newValue;
      },
    },
  },
  methods: {
    updateData(to) {
      to = to || this.id;
      this.axios.get(`/language/${to}`).then((response) => {
        this.objects = response.data;
      });
    },
    // onSubmit(evt, name, index) {
    onSubmit() {
      if (!this.isSubmitted) {
        this.isSubmitted = true;

        this.axios
          .post(`/language/${this.id}`, {
            data: this.objects,
          })
          .then(() => {
            // this.$router.go()
            setTimeout(() => {
              this.$router.go();
            }, 10000);
          });
        setTimeout(() => {
          this.$router.go();
        }, 15000);
      }
    },
  },
};
</script>

<style scoped></style>
