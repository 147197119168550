<template>
  <b-container>
    <b-row>
      <b-col>
        <h1>{{ $t('restaurant.title_add') }}</h1>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <h5>{{ $t('restaurant.general') }}</h5>
      </b-col>
    </b-row>
    <b-form @submit.prevent="onSubmit">
      <!-- if it's not a city admin -->
      <b-row v-if="roleId !== 30" class="mb-3">
        <b-col md="12">
          <label for="input-0">Choose the city</label>
          <b-form-select
            id="input-0"
            v-model="rawForm.cityId"
            :options="cities"
            required
            @input="onCityChanged"
          />
        </b-col>
      </b-row>

      <b-row class="mb-3">
        <b-col>
          <label for="input-1">
            {{ $t('restaurant.name', { name: '' }) }}
          </label>
          <b-form-input
            v-model="rawForm.name"
            v-bind:placeholder="$t('restaurant.name_placeholder')"
            id="input-1"
            required
            type="text"
          />
        </b-col>

        <b-col>
          <label for="input-15">
            {{ $t('restaurant.name_ru', { name: '' }) }}
          </label>
          <b-form-input
            v-model="rawForm.name_ru"
            v-bind:placeholder="$t('restaurant.name_ru_placeholder')"
            id="input-15"
            required
            type="text"
          />
        </b-col>
      </b-row>

      <b-row class="mb-3">
        <b-col md="12" class="mb-3">
          <b-form-group
            :state="descriptionLengthValidation"
            :invalid-feedback="$t('restaurant.descriptionLengthError')"
            :label="$t('restaurant.description')"
          >
            <b-form-textarea
              id="input-3"
              rows="3"
              v-model="rawForm.description"
              :placeholder="$t('restaurant.description_placeholder')"
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
            :state="descriptionRuLengthValidation"
            :invalid-feedback="$t('restaurant.descriptionLengthError')"
            :label="$t('restaurant.description_ru')"
          >
            <b-form-textarea
              id="input-3"
              rows="3"
              v-model="rawForm.description_ru"
              :placeholder="$t('restaurant.description_ru_placeholder')"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mb-3">
        <b-col>
          <b-form-group id="input-group-3">
            <b-form-checkbox
              id="checkbox-1"
              v-model="rawForm.enabled"
              name="checkbox-1"
            >
              {{ $t('restaurants.enabled') }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <label for="input-2">
            {{ $t('restaurant.position', { position: '' }) }}
          </label>
          <b-form-group id="input-group-2">
            <b-form-input
              id="input-2"
              placeholder="1"
              required
              type="number"
              v-model="rawForm.position"
            />
          </b-form-group>
        </b-col>

        <b-col>
          <label for="input-3">
            {{ $t('restaurant.minOrderSum', { sum: '' }) }}
          </label>
          <b-form-group id="input-group-3">
            <b-form-input
              id="input-3"
              placeholder="1000"
              required
              type="number"
              step="0.01"
              v-model="rawForm.minOrderSum"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            id="input-group-4"
            :label="$t('restaurant.schedule', { schedule: '' })"
            label-for="input-4"
            :invalid-feedback="scheduleValidationErrorText"
            :state="scheduleValidation"
          >
            <b-form-input
              v-model="rawForm.schedule"
              v-bind:placeholder="$t('restaurant.schedule_placeholder')"
              id="input-4"
              required
              type="text"
              :state="scheduleValidation"
            />
          </b-form-group>
        </b-col>

        <b-col>
          <label for="input-4">
            {{ $t('restaurant.deliveryTime', { deliveryTime: '' }) }}
          </label>
          <b-form-group id="input-group-4">
            <b-form-input
              v-model="rawForm.deliveryTime"
              v-bind:placeholder="$t('restaurant.deliveryTime_placeholder')"
              id="input-4"
              required
              type="text"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mb-3">
        <b-col>
          <label for="input-4">
            {{ $t('restaurant.address') }}
          </label>
          <b-form-input
            v-model="rawForm.address"
            v-bind:placeholder="$t('restaurant.address')"
            id="input-40"
            required
            type="text"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            :label="
              $t('restaurant.notificationAccount', {
                notificationAccountId: '',
              })
            "
            label-for="notificationAccountInput"
            :invalid-feedback="notificationUserNotFoundText"
            :state="notificationAccountValidation"
          >
            <b-form-input
              placeholder="16515313"
              v-model="rawForm.notificationAccountTelegramId"
              id="notificationAccountInput"
              :state="notificationAccountValidation"
            />
          </b-form-group>

          <b-tooltip target="notificationAccountInput" triggers="focus">
            {{ $t('restaurant.account_tooltip') }}
          </b-tooltip>
        </b-col>
      </b-row>

      <b-row v-if="cuisines.length > 0">
        <b-col>
          <b-form-group v-bind:label="$t('restaurant.cuisines')">
            <b-form-checkbox
              :key="cuisine.id"
              :value="cuisine.id"
              inline
              name="flavour-3a"
              v-for="cuisine in cuisines"
              v-model="rawForm.selectedCuisines"
            >
              {{ cuisine.name }} / {{ cuisine.name_ru }}
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mb-3">
        <b-col md="12">
          <h5>{{ $t('restaurant.delivery') }}</h5>
        </b-col>

        <b-col md="12">
          <b-form-checkbox v-model="rawForm.canPickup" class="col-4">
            {{ $t('restaurants.canPickup') }}
          </b-form-checkbox>

          <b-form-checkbox v-model="rawForm.canDelivery" class="col-4">
            {{ $t('restaurants.canDelivery') }}
          </b-form-checkbox>
        </b-col>
      </b-row>

      <!-- If the city has districts -->

      <b-row v-if="districts.length > 0">
        <b-col md="6" v-for="district in districts" :key="district.id">
          <b-row>
            <b-col md="12" :class="rawForm.canDelivery ? '' : 'text-muted'">
              <h6>
                {{ district.name }} / {{ district.name_ru }} -
                {{ $t('restaurant.deliveryPrice') }}
              </h6>
            </b-col>

            <b-col>
              <b-input-group>
                <b-input-group-prepend is-text>
                  <b-form-checkbox
                    v-model="deliveryEnabled[district.id]"
                    switch
                    :disabled="rawForm.canDelivery"
                  />
                </b-input-group-prepend>

                <b-form-input
                  placeholder="200.00"
                  type="number"
                  step="0.01"
                  v-model="deliveryPrices[district.id]"
                  :disabled="!deliveryEnabled[district.id]"
                />
              </b-input-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- If the city does not have any district, then we only provide the delivery cost -->

      <b-row v-else :class="rawForm.canDelivery ? '' : 'text-muted'">
        <b-col>
          <label for="input-4">
            {{
              $t('restaurant.deliveryPrice', {
                deliveryPrice: rawForm.deliveryPrice,
              })
            }}
          </label>
          <b-form-input
            id="input-4"
            placeholder="200.00"
            type="number"
            step="0.01"
            v-model="rawForm.deliveryPrice"
            :required="rawForm.canDelivery"
            :disabled="!rawForm.canDelivery"
          />
        </b-col>
      </b-row>

      <photo-uploader
        class="mt-3"
        :title="$t('restaurant.image')"
        :cityId="rawForm.cityId"
        :telegramFileId="rawForm.telegramFileId"
        @uploaded="onUploaded"
        @uploading="onUploading"
      />

      <b-button
        class="mb-3 mt-3"
        squared
        type="submit"
        variant="outline-primary"
        :disabled="hasValidationErrors || photoUploading"
      >
        {{ $t('restaurant.button_add') }}
      </b-button>
    </b-form>
  </b-container>
</template>

<script lang="ts">
import { ICity } from 'types';

import PhotoUploader from './PhotoUploader.vue';

const SCHEDULE_TEST_REGEXP =
  /^([0-9]{2}:[0-6][0-9]-[0-9]{2}:[0-6][0-9],?){1,2}$/;

export default {
  name: 'RestaurantAdd',
  components: { 'photo-uploader': PhotoUploader },
  data() {
    return {
      /**
       * We create a raw form because some fields of it must
       * be converted before sending the requests.
       *
       * The fields are: `position`, `minOrderSum`, `notificationAccountTelegramId`.
       */
      rawForm: {
        name: '',
        name_ru: '',
        description: '',
        description_ru: '',
        position: null,
        enabled: true,
        minOrderSum: null,
        deliveryTime: null,
        notificationAccountTelegramId: null,
        telegramFileId: null,
        selectedCuisines: [],
        cityId: null,
        address: null,
        canPickup: false,
        canDelivery: false,
      },
      cuisines: [],
      cities: [],
      districts: [],
      deliveryPrices: {},
      deliveryEnabled: {},
      notificationUserNotFound: null,
      notificationUserNotFoundText: '',
      scheduleError: null,
      photoUploading: false,
    };
  },
  computed: {
    roleId() {
      return this.$store.getters.roleId;
    },
    cityId() {
      return this.$store.getters.cityId;
    },
    notificationAccountValidation() {
      return this.notificationUserNotFound === null ? null : false;
    },
    scheduleValidation() {
      return this.scheduleError === null ? null : false;
    },
    scheduleValidationErrorText() {
      return this.$t('restaurant.schedule_error')
        .toString()
        .replaceAll(/<[^>]*>/g, '');
    },
    descriptionRuLengthValidation() {
      return this.rawForm.description_ru?.length >= 512 ? false : null;
    },
    descriptionLengthValidation() {
      return this.rawForm.description?.length >= 512 ? false : null;
    },
    hasValidationErrors() {
      return (
        this.descriptionRuLengthValidation === false ||
        this.descriptionLengthValidation === false ||
        this.scheduleValidation === false ||
        this.notificationAccountValidation === false
      );
    },
  },
  mounted() {
    // Gets a list of cities to let the user to select them while creating the restaurant

    this.axios.get('/cities').then(({ data }) => {
      // It needed to be converted in way the bootstrap-vue <b-table> will understand it

      this.cities = (data as ICity[]).map((city) => ({
        value: city.id,
        text: city.name,
      }));
    });

    if (this.cityId) {
      this.rawForm.cityId = this.cityId;

      this.axios.get(`/districts/plain/${this.cityId}`).then((response) => {
        this.districts = response.data;
      });
    }
  },
  methods: {
    onSubmit() {
      if (!SCHEDULE_TEST_REGEXP.test(this.rawForm.schedule)) {
        this.scheduleError = true;

        return;
      }

      this.scheduleError = null;
      this.notificationUserNotFound = null;

      const districtRestaurants = this.districts.map((district) => {
        return {
          districtId: district.id,
          price: this.deliveryPrices[district.id],
          enabled: this.deliveryEnabled[district.id],
        };
      });

      /**
       * Because we take data from input tags, some values of the form
       * are strings by default. This is why we need to convert them
       * separately.
       */
      const form = {
        ...this.rawForm,
        position: parseFloat(this.rawForm.position),
        minOrderSum: parseFloat(this.rawForm.minOrderSum),
        notificationAccountTelegramId: parseFloat(
          this.rawForm.notificationAccountTelegramId,
        ),
        deliveryPrice: parseFloat(this.rawForm.deliveryPrice) || 0,
        cityId: this.roleId === 30 ? this.cityId : this.rawForm.cityId,
        districtRestaurants,
      };

      this.axios
        .post('/restaurants/', form)
        .then((response) => {
          if (response.isAxiosError) throw response;

          /**
           * We send the photo by a seperate request, because the whole relation
           * between the admin panel and API was build on JSON and this would've been
           * quite hard to translate it onto multipart/forms just for sending images.
           *
           * This is why we send it separately - it uses multipart for the request!
           */
          if (response.data?.id && this.file) {
            const photoForm = new FormData();
            photoForm.append('photo', this.file);

            return this.axios.put(
              `/restaurants/${response.data.id}/photo`,
              photoForm,
            );
          }
        })
        .then(() => this.$router.push({ path: '/restaurants' }))
        .catch((e) => {
          if (e.response.data.code === 'FST_NOTIFICATION_ACCOUNT_NOT_FOUND') {
            this.notificationUserNotFound = true;
            this.notificationUserNotFoundText = e.response.data.message;
          }
        });
    },
    onCityChanged() {
      this.axios
        .get(`/districts/plain/${this.rawForm.cityId}`)
        .then((response) => {
          this.districts = response.data;
        });

      this.axios
        .get(`/cuisines/city/${this.rawForm.cityId}`)
        .then((response) => {
          this.cuisines = response.data;
        });
    },
    onUploading() {
      this.photoUploading = true;
    },
    onUploaded(telegramFileId: string) {
      this.photoUploading = false;
      this.rawForm.telegramFileId = telegramFileId;
    },
  },
};
</script>

<style scoped></style>
