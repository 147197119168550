<template>
  <b-container fluid>
    <h1>Статистика</h1>

    <hr />

    <b-row>
      <b-col md="6">
        <b-form-group label="Выберите дату для вывода статистики:">
          <b-form-datepicker
            id="datepicker"
            v-model="date"
            @input="updateStats"
            class="mb-2"
          />
        </b-form-group>
      </b-col>

      <b-col md="6" v-if="this.isAdmin">
        <b-form-group label="Выберите город:">
          <b-form-select
            id="citypicker"
            v-model="city"
            :options="cities"
            required
            @input="() => updateStats()"
          />
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group label="Выберите масштаб:">
          <b-form-select
            v-model="scale"
            :options="scales"
            @input="() => updateStats()"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <hr />

    <b-overlay :show="!city && this.isAdmin" variant="dark">
      <b-row>
        <b-col sm="12" xl="6">
          <h5>График заказов:</h5>

          <apexchart
            v-if="city || isCityAdmin"
            ref="ordersChart"
            type="line"
            width="500"
            :options="chartOptions"
            :series="series"
          />
        </b-col>

        <b-col sm="12" xl="6">
          <h5>Пользователи</h5>

          <div>
            Новых пользователей:
            <b-badge>{{ newUsers }}</b-badge>
          </div>

          <div>
            Количество активных пользователей:
            <b-badge>{{ activeUsers }}</b-badge>
          </div>

          <div>
            Количество пользователей, заблокировавших бота:
            <b-badge id="tooltip-target-1" variant="danger">
              {{ blockedUsersAmount }}
            </b-badge>

            <b-tooltip target="tooltip-target-1">
              <b-badge variant="danger">Красный цвет</b-badge>
              вокруг значения поля означает, что параметр не зависит от
              выбранной даты
            </b-tooltip>
          </div>

          <div>
            Количество пользователей у которых в данный момент отложены товары в
            корзине:
            <b-badge id="tooltip-target-2" variant="danger">{{
              cartsAmount
            }}</b-badge>

            <b-tooltip target="tooltip-target-2">
              <b-badge variant="danger">Красный цвет</b-badge>
              вокруг значения поля означает, что параметр не зависит от
              выбранной даты
            </b-tooltip>
          </div>

          <h5 class="mt-3">Заказы</h5>

          <div>
            Количество всех заказов:
            <b-badge>{{ orderStatistics.amountOfOrders }}</b-badge>
          </div>

          <div>
            Количество пользователей, <em>сделавших 2 заказа</em>:
            <b-badge>{{ orderStatistics.amountOfTwoOrders }}</b-badge>
          </div>

          <div>
            Количество пользователей, <em>сделавших 3 заказа</em>:
            <b-badge>{{ orderStatistics.amountOfThreeOrders }}</b-badge>
          </div>

          <div>
            Количество пользователей,
            <em>сделавших 4 и более заказа</em>:
            <b-badge>
              {{ orderStatistics.amountOfFourAndMoreOrders }}
            </b-badge>
          </div>
        </b-col>
      </b-row>

      <hr />

      <h5>Другое</h5>

      <div>
        Среднее время ответа бота на запрос пользователя:
        <b-badge>{{ executionTime || '?' }} ms</b-badge>
      </div>

      <template #overlay>
        <div class="text-center">
          <b-icon
            icon="lock-fill"
            font-scale="3"
            animation="cylon-vertical"
            variant="primary"
            class="mb-3"
          ></b-icon>

          <p class="text-white">Выберите город для показа статистики</p>
        </div>
      </template>
    </b-overlay>
  </b-container>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { ApexOptions } from 'apexcharts';
import { ICity, RoleEnum } from 'types';

type OrderStatistics = {
  amountOfOrders: number;
  amountOfTwoOrders: number;
  amountOfThreeOrders: number;
  amountOfFourAndMoreOrders: number;
};

type Queries = {
  date: string;
  cityId: string;
  weeklyReport?: boolean;
  monthlyReport?: boolean;
};

type ChartData = {
  data: number[];
  columns: string[];
};

@Component({ name: 'Stats' })
export default class Stats extends Vue {
  newUsers = 0;
  executionTime = 0;
  activeUsers = 0;
  blockedUsersAmount = 0;
  cartsAmount = 0;
  orderStatistics: OrderStatistics = {
    amountOfOrders: 0,
    amountOfTwoOrders: 0,
    amountOfThreeOrders: 0,
    amountOfFourAndMoreOrders: 0,
  };

  scales = ['День', 'Неделя', 'Месяц'] as const;
  scale: (typeof this.scales)[number] = 'День';

  chartOptions: ApexOptions = {
    chart: { id: 'vuechart' },
    dataLabels: { enabled: true },
    colors: ['#F15065', '#545454'],
  };

  series = [];

  date: string = null;
  city = null;
  cities = [];

  async mounted() {
    await this.axios.get<ICity[]>('/cities').then(({ data }) => {
      this.cities = data.map((city) => ({
        value: city,
        text: city.name,
      }));
    });

    await this.updateStats();
  }

  updateStats<T>(date?: T) {
    const dateStringified =
      date && date instanceof String
        ? date
        : this.date
        ? this.date
        : new Date().toDateString();

    const params = {
      date: dateStringified,
      cityId: this.isCityAdmin ? this.cityId : this.city.id,
    } as Queries;

    if (this.scale === 'Неделя') params.weeklyReport = true;
    if (this.scale === 'Месяц') params.monthlyReport = true;

    // Updates chart

    this.axios.get<ChartData>('/stats/orders', { params }).then(({ data }) => {
      this.series = [{ name: 'orders', data: data.data }];

      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          categories: data.columns,
        },
      };

      this.chartOptions.dataLabels.enabled = this.scale !== 'День';
    });

    this.axios.get('/stats/newUsers', { params }).then((response) => {
      this.newUsers = response.data;
    });

    this.axios.get('/stats/executionTime', { params }).then((response) => {
      this.executionTime = response.data;
    });

    this.axios.get('/stats/activeUsers', { params }).then((response) => {
      this.activeUsers = response.data;
    });

    this.axios.get('/stats/ordersAmount', { params }).then((response) => {
      this.orderStatistics = response.data;
    });

    this.axios.get('/stats/blockedUsersAmount', { params }).then((response) => {
      this.blockedUsersAmount = response.data;
    });

    this.axios.get('/stats/cartsAmount', { params }).then((response) => {
      this.cartsAmount = response.data;
    });
  }

  get isAdmin() {
    return this.$store.getters.roleId === RoleEnum.ADMIN;
  }

  get isCityAdmin() {
    return this.$store.getters.roleId === RoleEnum.CITY_ADMIN;
  }

  get cityId() {
    return this.$store.getters.cityId;
  }
}
</script>

<style scoped></style>
