import '@babel/polyfill';
import 'mutationobserver-shim';
import Vue, { provide } from 'vue';
import VueAxios from 'vue-axios';
import VueDateFns from 'vue-date-fns';
import VueI18n from 'vue-i18n';
import VueApexCharts from 'vue-apexcharts';
import { DefaultApolloClient } from '@vue/apollo-composable';

import apolloClient from './http-graphql';
import App from './App.vue';
import axios from './http-common';
import enMessages from './lang/en';
import ruMessages from './lang/ru';
import esMessages from './lang/es';
import './plugins/bootstrap-vue';
import router from './router';
import store from './store';

Vue.use(VueApexCharts);
Vue.use(VueI18n);
Vue.use(VueDateFns);
Vue.use(VueAxios, axios);
Vue.component('apexchart', VueApexCharts);

const storagePrefix = process.env.VUE_APP_STORAGE_PREFIX;

const i18n = new VueI18n({
  locale: localStorage.getItem(`${storagePrefix}-locale`),
  fallbackLocale: 'en',
  messages: {
    // set locale messages
    en: enMessages,
    ru: ruMessages,
    es: esMessages,
  },
});

new Vue({
  i18n,
  router,
  store,
  setup() {
    provide(DefaultApolloClient, apolloClient);
  },
  render: (h) => h(App),
}).$mount('#app');
